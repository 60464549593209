import React from 'react'
import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useAuth0Token } from '../auth/auth0'

const { Dragger } = Upload

interface FileUploadProps {
  action: string
  onSuccess: (result: any) => void
  sampleFile?: string
}

export const FileUpload: React.FC<FileUploadProps> = props => {
  const token = useAuth0Token()

  return (
    <Dragger
      action={props.action}
      headers={{
        authorization: `Bearer ${token}`,
      }}
      name="file"
      multiple={false}
      onChange={info => {
        const { status } = info.file
        if (status !== 'uploading') console.log(info.file, info.fileList)
        if (status === 'done') {
          props.onSuccess(info.fileList?.[info.fileList.length - 1].response)
          message.success(`${info.file.name} file uploaded successfully.`)
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`)
        }
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag a file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for single file upload.{' '}
        {props.sampleFile ? (
          <>
            Use the format in this{' '}
            <a
              href={props.sampleFile}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              sample file
            </a>
            .
          </>
        ) : null}
      </p>
    </Dragger>
  )
}
