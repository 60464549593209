import { SERVER_URL } from './config'

export const apiCall = async (path: string) => {
  const res = await fetch(`${SERVER_URL}${path}`)

  if (!res.ok) {
    const json = await res.json()
    console.error('apiCall error', json)
    throw Error(json.message)
  }

  return res.json()
}
