import { gql } from 'apollo-boost'

export const CONTRACT_FRAGMENT = gql`
  fragment Contract on Contract {
    id
    description
    name
    threshold
    thresholdType
    cap
    periodPostEmploymentMonths
    periodPostGraduationMonths
    ratePercentage
    type
    upfrontPayment
    currency
    dropoutInterest
    dropoutPrincipal
    fileUrl
    schoolId
    deleted
  }
`

export const EMPLOYMENT_FRAGMENT = gql`
  fragment Employment on Employment {
    id
    companyName
    startDate
    endDate
    amountPerMonth
    amountPerHour
    currency
    salaryType
    paymentDay
    firstPaymentDate
    country
    notes
    studentId
  }
`

export const STUDENT_FRAGMENT = gql`
  ${CONTRACT_FRAGMENT}
  ${EMPLOYMENT_FRAGMENT}

  fragment GetStudentStudent on Student {
    id
    firstName
    lastName
    createdAt
    updatedAt
    email
    phone
    address
    street
    city
    country
    zip
    stage
    candidateStage
    studentStage
    graduateStage
    passportIdNumber
    idFrontImageUrl
    idBackImageUrl
    stubImageUrl
    checkImageUrl
    signatureImageUrl
    agreementVideoUrl
    plaidPaymentEnabled
    stripePaymentEnabled
    manualPaymentEnabled
    stripeCustomerId
    stripePaymentMethodId
    bankName
    bankBranch
    bankAccountNumber
    bankAuthorized
    blender
    contractId
    driveFolder: driveFile(fileType: "folder") {
      id
    }
    driveContract: driveFile(fileType: "contract") {
      id
    }
    cohorts {
      id
      name
      program {
        id
        name
        school {
          id
          name
        }
      }
    }
    contract {
      ...Contract
    }
    employments {
      ...Employment
    }
    currentEmployment {
      ...Employment
    }
    payments {
      id
      amount
      currency
      paymentDate
      paymentMethod
    }
    paymentSummary {
      paymentsPaid
      paymentsExpected
      balancePaid
      balanceExpected
      firstPayment
      lastPayment
      currency
    }
    notes {
      id
      text
      createdAt
      creator {
        user_id
        name
        picture
      }
    }
    deleted
  }
`
