import React from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

import { GRAPHQL_URL } from '../utils/config'
import { useAuth0 } from './auth0'

export const AuthorizedApolloProvider: React.FC<{}> = ({ children }) => {
  const { getTokenSilently } = useAuth0()

  const client = new ApolloClient({
    uri: GRAPHQL_URL,
    request: async operation => {
      const token = await getTokenSilently()

      if (token) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
      }
    },
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
