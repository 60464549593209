import { Button, Modal } from 'antd'
import React from 'react'
import { PlusOutlined } from '@ant-design/icons'

interface ButtonModalAddProps {
  title: string
  buttonText?: string
  width?: string | number
  form: (onCreate: () => void) => React.ReactNode
}

export const ButtonModalAdd: React.FC<ButtonModalAddProps> = props => {
  const [state, setState] = React.useState({
    modalVisible: false,
  })
  const closeModal = () => setState({ modalVisible: false })

  return (
    <>
      <Button
        type="dashed"
        style={{ width: '100%', marginBottom: 8 }}
        onClick={() => {
          setState({ modalVisible: true })
        }}
      >
        <PlusOutlined />
        {props.buttonText || props.title}
      </Button>
      <Modal
        title={props.title}
        width={props.width || 640}
        bodyStyle={{ padding: '28px 20px 10px' }}
        destroyOnClose
        visible={state.modalVisible}
        onCancel={closeModal}
        footer={null}
      >
        {props.form(closeModal)}
      </Modal>
    </>
  )
}
