import React from 'react'
import { Comment, Tooltip, List, Card, Button, Form, Popconfirm, message } from 'antd'
import { Field, Formik } from 'formik'
import { gql } from 'apollo-boost'
import { formatDate } from '../../utils/date'
import {
  GetStudentStudentFragment,
  useCreateNoteMutation,
  useEditNoteMutation,
  useDeleteNoteMutation,
  CreateNoteInput,
  EditNoteInput,
} from '../../generated/graphql'
import { CustomTextAreaComponent } from '../../components/FormikCustom'
import { GET_STUDENT } from './Student'
import { containsHebrew } from '../../utils/language'
import { ButtonModalLink } from '../../components/ButtonModalLink'
import { trackEvent } from '../../utils/analytics'

const NOTE_FRAGMENT = gql`
  fragment Note on Note {
    id
    version
    createdAt
    updatedAt
    creatorId
    text
    studentId
  }
`

gql`
  ${NOTE_FRAGMENT}

  mutation CreateNote($data: CreateNoteInput!) {
    createNote(data: $data) {
      ...Note
    }
  }
`

gql`
  ${NOTE_FRAGMENT}

  mutation EditNote($data: EditNoteInput!) {
    editNote(data: $data) {
      ...Note
    }
  }
`

gql`
  mutation DeleteNote($id: ID!) {
    deleteNote(id: $id)
  }
`

interface NotesProps {
  student: GetStudentStudentFragment
}

export const Notes: React.FC<NotesProps> = props => {
  const studentId = props.student.id

  const data =
    props.student.notes?.map(note => {
      return {
        ...note,
        author: note.creator.name || 'Unnamed author',
        avatar:
          note.creator.picture ||
          'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        content: (
          <p style={containsHebrew(note.text) ? { direction: 'rtl', textAlign: 'right' } : {}}>
            {note.text}
          </p>
        ),
        datetime: (
          <Tooltip title={formatDate(new Date(note.createdAt))}>
            <span>{formatDate(new Date(note.createdAt))}</span>
          </Tooltip>
        ),
      }
    }) || []

  return (
    <Card title="Notes">
      <CreateNoteForm studentId={studentId} />
      <List
        itemLayout="horizontal"
        locale={{ emptyText: 'There are no notes yet' }}
        dataSource={data}
        renderItem={item => (
          <li>
            <Comment
              author={item.author}
              avatar={item.avatar}
              content={item.content}
              datetime={item.datetime}
              actions={[
                <ButtonModalLink
                  buttonText="Edit"
                  title={'Edit Note'}
                  content={closeModal => {
                    return (
                      <EditNoteForm
                        noteId={item.id}
                        text={item.text}
                        studentId={studentId}
                        onSubmit={closeModal}
                      />
                    )
                  }}
                />,
                <DeleteNoteButton noteId={item.id} studentId={studentId} />,
              ]}
            />
          </li>
        )}
      />
    </Card>
  )
}

interface CreateNoteFormProps {
  studentId: string
}

const CreateNoteForm: React.FC<CreateNoteFormProps> = props => {
  const { studentId } = props
  const [createNote] = useCreateNoteMutation({
    refetchQueries: [{ query: GET_STUDENT, variables: { studentId } }],
  })

  return (
    <Formik<CreateNoteInput>
      enableReinitialize
      initialValues={{
        text: '',
        studentId,
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        console.log('onSubmit', values)

        await createNote({ variables: { data: values } })
        trackEvent('createNote', values)
        setSubmitting(false)
        resetForm()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form layout="inline" onFinish={() => handleSubmit()} onReset={handleReset}>
          <div style={{ flex: 1 }}>
            <Field name="text" label="" component={CustomTextAreaComponent} />
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
              Post
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}

interface EditNoteFormProps {
  noteId: string
  text: string
  studentId: string
  onSubmit?: () => void
}

const EditNoteForm: React.FC<EditNoteFormProps> = props => {
  const { noteId, text, studentId } = props
  const [editNote] = useEditNoteMutation({
    refetchQueries: [{ query: GET_STUDENT, variables: { studentId } }],
  })

  return (
    <Formik<EditNoteInput>
      enableReinitialize
      initialValues={{
        id: noteId,
        text,
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        console.log('onSubmit', values)

        await editNote({ variables: { data: values } })
        trackEvent('editNote', values)
        setSubmitting(false)
        resetForm()
        props.onSubmit?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form layout="inline" onFinish={() => handleSubmit()} onReset={handleReset}>
          <div style={{ flex: 1 }}>
            <Field name="text" label="" component={CustomTextAreaComponent} />
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}

interface DeleteNoteButtonProps {
  noteId: string
  studentId: string
}

const DeleteNoteButton: React.FC<DeleteNoteButtonProps> = props => {
  const [deleteNote] = useDeleteNoteMutation({
    variables: { id: props.noteId },
    refetchQueries: [{ query: GET_STUDENT, variables: { studentId: props.studentId } }],
  })

  return (
    <Popconfirm
      title="Are you sure delete this note?"
      onConfirm={async e => {
        await deleteNote()
        trackEvent('deleteNote', { studentId: props.studentId })
        message.success('Note deleted')
      }}
      okText="Yes"
      cancelText="No"
    >
      <a href="#">Delete</a>
    </Popconfirm>
  )
}
