import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import {
  PaymentsTable,
  paymentIdColumn,
  amountColumn,
  paymentDateColumn,
  paymentMethodColumn,
  noteColumn,
} from '../../payment/Payments'
import { PaymentFragment } from '../../generated/graphql'

interface PaymentProps {
  payments: PaymentFragment[]
}

export const Payment: React.FC<PaymentProps> = props => {
  const columns: ColumnProps<PaymentFragment>[] = [
    paymentIdColumn,
    amountColumn,
    paymentDateColumn,
    paymentMethodColumn,
    noteColumn,
  ]

  return <PaymentsTable payments={props.payments} columns={columns} />
}
