import React from 'react'
import { Link } from 'react-router-dom'
import { Tag } from 'antd'

interface TagLinkProps {
  tag: string
  schoolId: string
}

export const TagLink: React.FC<TagLinkProps> = props => {
  return (
    <Link to={`/school/${props.schoolId}/tag/${props.tag}`}>
      <Tag color="green" style={{ cursor: 'pointer' }}>
        {props.tag}
      </Tag>
    </Link>
  )
}
