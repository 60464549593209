export const SERVER_URL = (process.env.REACT_APP_TOML_REACT_APP_SERVER_URL ||
  process.env.REACT_APP_SERVER_URL) as string

export const GRAPHQL_URL = SERVER_URL + '/graphql'

export const AUTH0_DOMAIN = (process.env.REACT_APP_TOML_REACT_APP_AUTH0_DOMAIN ||
  process.env.REACT_APP_AUTH0_DOMAIN) as string

export const AUTH0_CLIENT_ID = (process.env.REACT_APP_TOML_REACT_APP_AUTH0_CLIENT_ID ||
  process.env.REACT_APP_AUTH0_CLIENT_ID) as string

export const AUTH0_AUDIENCE = (process.env.REACT_APP_TOML_REACT_APP_AUTH0_AUDIENCE ||
  process.env.REACT_APP_AUTH0_AUDIENCE) as string

export const STRIPE_PUBLIC_KEY = (process.env.REACT_APP_TOML_REACT_APP_STRIPE_PUBLIC_KEY ||
  process.env.REACT_APP_STRIPE_PUBLIC_KEY) as string

export const STRIPE_DASHBOARD_URL = (process.env.REACT_APP_TOML_REACT_APP_STRIPE_DASHBOARD_URL ||
  process.env.REACT_APP_STRIPE_DASHBOARD_URL) as string

export const SENTRY_DSN = (process.env.REACT_APP_TOML_REACT_APP_SENTRY_DSN ||
  process.env.REACT_APP_SENTRY_DSN) as string

export const SENTRY_ENVIRONMENT = (process.env.REACT_APP_TOML_REACT_APP_SENTRY_ENVIRONMENT ||
  process.env.REACT_APP_SENTRY_ENVIRONMENT) as string

export const AMPLITUDE_API_KEY = (process.env.REACT_APP_TOML_REACT_APP_AMPLITUDE_API_KEY ||
  process.env.REACT_APP_AMPLITUDE_API_KEY) as string
