import * as React from 'react'
import { FieldProps } from 'formik'
import styled from 'styled-components/macro'
import { Form, Input, Select, Checkbox, DatePicker } from 'antd'
import { containsHebrew } from '../utils/language'

const { Option } = Select
const { RangePicker } = DatePicker

const ErrorMessage = styled.div``

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
}) => (
  <Form.Item label={props.label}>
    <Input type="text" {...field} {...props} />
    {/*  */}
    {touched[field.name] && errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
  </Form.Item>
)

export const CustomTextAreaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
}) => (
  <Form.Item label={props.label}>
    <Input.TextArea
      rows={4}
      style={containsHebrew(field.value) ? { direction: 'rtl', textAlign: 'right' } : {}}
      {...field}
      {...props}
    />
    {/*  */}
    {touched[field.name] && errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
  </Form.Item>
)

export const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  options: Array<{ value: string; label: string }>
  style?: React.CSSProperties
  onChange?: (val: any) => void
}) => {
  const { options } = props

  return (
    <Form.Item label={props.label}>
      <Select
        {...field}
        {...props}
        defaultValue={options[0]?.value}
        style={props.style}
        onChange={value => {
          setFieldValue(field.name, value)
          props.onChange?.(value)
        }}
      >
        {options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label || option.value}
          </Option>
        ))}
      </Select>
      {/*  */}
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage>{errors[field.name]}</ErrorMessage>
      )}
    </Form.Item>
  )
}

export const CustomCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
}) => (
  <Form.Item label={props.label}>
    <Checkbox checked={field.value} {...field} {...props} />
    {/*  */}
    {touched[field.name] && errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
  </Form.Item>
)

export const CustomDateComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
}) => {
  return (
    <Form.Item label={props.label}>
      <DatePicker
        {...(field as any)}
        {...props}
        onChange={values => setFieldValue(field.name, values)}
      />
      {/*  */}
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage>{errors[field.name]}</ErrorMessage>
      )}
    </Form.Item>
  )
}

export const CustomDateRangeComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
}) => {
  return (
    <Form.Item label={props.label}>
      <RangePicker
        {...(field as any)}
        {...props}
        onChange={values => setFieldValue(field.name, values)}
      />
      {/*  */}
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage>{errors[field.name]}</ErrorMessage>
      )}
    </Form.Item>
  )
}
