import { Button, Form } from 'antd'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import moment, { Moment } from 'moment'
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomDateRangeComponent,
} from '../components/FormikCustom'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import {
  EditCohortInput,
  useEditCohortMutation,
  useGetSchoolContractsFullQuery,
  Cohort,
} from '../generated/graphql'
import { GET_COHORT } from './Cohort'
import { trackEvent } from '../utils/analytics'

export const EDIT_COHORT = gql`
  mutation EditCohort($data: EditCohortInput!) {
    editCohort(data: $data) {
      id
      name
      startDate
      endDate
      programId
      defaultContract {
        id
        name
      }
    }
  }
`

interface EditCohortFormProps {
  onCreate?: () => void
  cohort: Pick<
    Cohort,
    'id' | 'name' | 'startDate' | 'endDate' | 'programId' | 'defaultContractId' | 'tag'
  >
  schoolId: string
}

export const EditCohortForm: React.FC<EditCohortFormProps> = props => {
  const { schoolId } = props
  const [editCohort] = useEditCohortMutation({
    refetchQueries: [{ query: GET_COHORT, variables: { cohortId: props.cohort.id } }],
  })
  const { data } = useGetSchoolContractsFullQuery({
    fetchPolicy: 'cache-and-network',
    variables: { schoolId },
  })
  const contracts = data?.contractsForSchool

  return (
    <Formik<EditCohortInput & { dates?: Array<Moment | undefined> }>
      enableReinitialize
      initialValues={{
        id: props.cohort.id,
        name: props.cohort.name,
        startDate: props.cohort.startDate,
        endDate: props.cohort.endDate,
        dates: [
          props.cohort.startDate && moment(props.cohort.startDate),
          props.cohort.endDate && moment(props.cohort.endDate),
        ],
        tag: props.cohort.tag || '',
        programId: props.cohort.programId,
        defaultContractId: props.cohort.defaultContractId,
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)

        if (!values.dates || !values.dates[0] || !values.dates[1]) return

        const data = {
          id: values.id,
          name: values.name,
          startDate: values.dates[0].toDate(),
          endDate: values.dates[1].toDate(),
          programId: values.programId,
          defaultContractId: values.defaultContractId,
          tag: values.tag,
        }
        editCohort({ variables: { data } })
        trackEvent('editCohort', data)
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="name" label="Name" component={CustomInputComponent} />
          <Field name="dates" label="Dates" component={CustomDateRangeComponent} />
          <Field name="tag" label="Tag" component={CustomInputComponent} />

          {contracts && (
            <Field
              name="defaultContractId"
              label="Contract"
              options={contracts.map(contract => ({
                value: contract.id,
                label: `${contract.name}`,
              }))}
              component={CustomSelectComponent}
            />
          )}
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
