import React from 'react'
import { Result } from 'antd'

interface StripeCancelProps {}

export const StripeCancel: React.FC<StripeCancelProps> = props => {
  return (
    <Result status="warning" title="Cancelled" subTitle="Cancelled adding payment information" />
  )
}
