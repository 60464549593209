import React from 'react'
import { Pie, PieChart as PieChartRecharts, ResponsiveContainer } from 'recharts'
import { blue } from '@ant-design/colors'

interface PieChartProps {
  percentage: number
}

export const PieChart: React.FC<PieChartProps> = props => {
  const data01 = [
    {
      name: 'Placement Rate',
      value: 100,
    },
  ]

  return (
    // <ResponsiveContainer>
    <PieChartRecharts height={250} width={200}>
      <Pie
        data={data01}
        dataKey="value"
        nameKey="name"
        // cx="50%"
        // cy="50%"
        innerRadius={60}
        outerRadius={80}
        fill={blue.primary}
        label
        startAngle={(props.percentage * 360) / 100}
        endAngle={0}
        valueKey="value"
      />
    </PieChartRecharts>
    // </ResponsiveContainer>
  )
}
