import React from 'react'
import { Card, Steps, Form, Button } from 'antd'
import { gql } from 'apollo-boost'
import styled from 'styled-components'
import groupBy from 'lodash/groupBy'
import { ButtonModalLink } from '../../components/ButtonModalLink'
import {
  ActionType,
  ActionProgressStatus,
  GetStudentStudentFragment,
  useStudentActionProgressQuery,
  SentEmailFragment,
  useSendEmailMutation,
  useEmailTemplateQuery,
  useUpdateActionProgressStatusMutation,
  useActionTypeDetailsQuery,
  ActionProgressFragment,
} from '../../generated/graphql'
import { CardWrapper } from '../../components/Card'
import { Loading } from '../../components/Loading'
import { ACTION_FRAGMENT } from '../../actions/ActionGroups'
import { SendEmail } from './SendEmail'
import { formatDateTime } from '../../utils/date'
import { Formik, Field } from 'formik'
import { CustomSelectComponent } from '../../components/FormikCustom'
import { formatActionProgressStatus } from '../../utils/action'
import { trackEvent } from '../../utils/analytics'

const STUDENT_ACTION_PROGRESS_QUERY = gql`
  ${ACTION_FRAGMENT}

  fragment SentEmail on Email {
    id
    createdAt
    reminder
  }

  fragment ActionProgress on ActionProgress {
    id
    status
  }

  query StudentActionProgress($cohortId: ID!, $studentId: ID!) {
    cohort(id: $cohortId) {
      id
      actionGroup {
        name
        actions {
          ...Action
          progress(studentId: $studentId) {
            ...ActionProgress
          }
          sentEmails(studentId: $studentId) {
            ...SentEmail
          }
        }
      }
    }
  }
`

gql`
  query EmailTemplate($studentId: ID!, $sendgridTemplateId: String!) {
    student(id: $studentId) {
      id
      emailTemplate(sendgridTemplateId: $sendgridTemplateId) {
        id
        htmlContentWithDynamicData
        plain_content
        subject
      }
    }
  }
`

gql`
  mutation SendEmail($data: SendEmailInput!) {
    sendEmail(data: $data)
  }
`

gql`
  mutation UpdateActionProgressStatus($data: UpdateActionProgressInput!) {
    updateActionProgressStatus(data: $data) {
      id
      studentId
      actionId
      status
    }
  }
`

gql`
  query ActionTypeDetails {
    actionTypeDetails {
      label
      stage
      type
    }
  }
`

const { Step } = Steps

const StepContentsWrapper = styled.div`
  margin-top: 10px;
`

const EmailButtonWrapper = styled.div`
  margin-bottom: 10px;
`

interface TimelinesProps {
  studentId: string
  student: GetStudentStudentFragment
}

export const Timelines: React.FC<TimelinesProps> = props => {
  const { studentId, student } = props
  const cohortId = student.cohorts?.[0].id || ''
  const schoolId = student.cohorts?.[0]?.program.school.id || ''

  const { data, loading, error } = useStudentActionProgressQuery({
    variables: { cohortId, studentId },
  })
  const {
    data: dataActionTypeDetails,
    loading: loadingActionTypeDetails,
    error: errorActionTypeDetails,
  } = useActionTypeDetailsQuery()

  if (!schoolId || !cohortId) {
    return (
      <CardWrapper>
        <Card title="No School">
          The timelines will appear here once the student is added to a cohort.
        </Card>
      </CardWrapper>
    )
  }

  if (loading || loadingActionTypeDetails) return <Loading />
  if (!data || !dataActionTypeDetails || error || errorActionTypeDetails) {
    console.error('error', error)
    console.error('errorActionTypeDetails', errorActionTypeDetails)
    return <Card title="Error">There was an error loading the action group</Card>
  }

  const sections = groupBy(dataActionTypeDetails.actionTypeDetails, actionType => {
    return actionType.stage
  })

  return (
    <div>
      {Object.entries(sections).map(([section, actions]) => {
        const studentActions = data.cohort.actionGroup?.actions
          .filter(action => action.enabled && actions.find(ac => ac.type === action.type))
          .sort((a, b) => a.order - b.order)

        let currentStep: number = 0
        let stopCountingSteps = false

        studentActions?.forEach(action => {
          if (stopCountingSteps) return

          if (action.progress?.status === ActionProgressStatus.Completed) currentStep++
          else stopCountingSteps = true
        })

        return (
          <CardWrapper key={section}>
            <Card title={section}>
              <Steps size="small" direction="vertical" current={currentStep}>
                {studentActions?.map(action => {
                  return (
                    <Step
                      key={action.id}
                      title={action.type}
                      description={
                        <StepContents
                          studentId={studentId}
                          actionId={action.id}
                          items={[
                            {
                              title: action.type,
                              type: action.type,
                              emails: action.sentEmails,
                              progress: action.progress || undefined,
                              modalContent: closeModal => {
                                return action.sendgridTemplateId ? (
                                  <SendEmailCommon
                                    type={action.type}
                                    actionId={action.id}
                                    templateId={action.sendgridTemplateId}
                                    studentId={studentId}
                                    cohortId={cohortId}
                                    onSuccess={closeModal}
                                    reminder={false}
                                  />
                                ) : (
                                  <div>There is no email for this action.</div>
                                )
                              },
                              reminderModalContent: closeModal => {
                                return action.reminderSendgridTemplateId ? (
                                  <SendEmailCommon
                                    type={action.type}
                                    actionId={action.id}
                                    templateId={action.reminderSendgridTemplateId}
                                    studentId={studentId}
                                    cohortId={cohortId}
                                    onSuccess={closeModal}
                                    reminder
                                  />
                                ) : (
                                  <div>There is no email for this action.</div>
                                )
                              },
                            },
                          ]}
                        />
                      }
                    />
                  )
                })}
              </Steps>
            </Card>
          </CardWrapper>
        )
      })}
    </div>
  )
}

interface StepContentsItem {
  title: string
  emails?: SentEmailFragment[]
  progress?: ActionProgressFragment
  type: ActionType
  linkText?: string
  modalContent: (onCreate: () => void) => React.ReactNode
  reminderModalContent: (onCreate: () => void) => React.ReactNode
  extra?: React.ReactNode
}

interface StepContentsProps {
  studentId: string
  actionId: string
  items: StepContentsItem[]
}

const StepContents: React.FC<StepContentsProps> = props => {
  return (
    <StepContentsWrapper>
      {props.items.map((item, i) => (
        <EmailButtonWrapper key={i}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <div>
              <ButtonModalLink
                title={item.title}
                buttonText={item.linkText || 'Send Email'}
                buttonType="primary"
                content={item.modalContent}
              />
              <SentEmails
                title="Emails Sent"
                emails={item?.emails?.filter(email => !email.reminder)}
              />
            </div>

            <div>
              <ButtonModalLink
                title={item.title}
                buttonText={item.linkText || 'Send Reminder Email'}
                buttonType="default"
                content={item.reminderModalContent}
              />
              <SentEmails
                title="Reminders Sent"
                emails={item?.emails?.filter(email => email.reminder)}
              />
            </div>
          </div>

          <SetActionProgressStatus
            studentId={props.studentId}
            actionId={props.actionId}
            progress={item.progress}
          />
          {item.extra}
        </EmailButtonWrapper>
      ))}
    </StepContentsWrapper>
  )
}

const SentEmailsWrapper = styled.div`
  margin: 10px 0;
`

const SentEmails: React.FC<{ title: string; emails?: SentEmailFragment[] }> = props => {
  const { title, emails } = props

  if (!emails) return null

  return (
    <SentEmailsWrapper>
      <strong>{title}</strong>
      {emails.length ? (
        <ul>
          {emails.map(email => {
            return <li key={email.id}>Sent at: {formatDateTime(new Date(email.createdAt))}</li>
          })}
        </ul>
      ) : (
        <div>No email sent yet</div>
      )}
    </SentEmailsWrapper>
  )
}

const SendEmailCommon: React.FC<{
  studentId: string
  cohortId: string
  templateId: string
  actionId: string
  type: ActionType
  reminder: boolean
  onSuccess?: () => void
}> = props => {
  const { studentId, cohortId, templateId, actionId, reminder, onSuccess } = props

  const { data, loading, error } = useEmailTemplateQuery({
    variables: { studentId, sendgridTemplateId: templateId },
  })

  const [sendEmail] = useSendEmailMutation({
    refetchQueries: [{ query: STUDENT_ACTION_PROGRESS_QUERY, variables: { studentId, cohortId } }],
  })

  return (
    <SendEmail
      subject={data?.student.emailTemplate.subject || ''}
      htmlContentWithDynamicData={data?.student.emailTemplate.htmlContentWithDynamicData || ''}
      plainContent={data?.student.emailTemplate.plain_content || ''}
      loading={loading}
      error={error}
      onSend={async () => {
        const data = { id: studentId, sendgridTemplateId: templateId, actionId, reminder }
        sendEmail({ variables: { data } })
        trackEvent('sendEmail', data)
      }}
      onSuccess={onSuccess}
    />
  )
}

interface SetActionProgressStatusProps {
  progress?: ActionProgressFragment
  studentId: string
  actionId: string
}

export const SetActionProgressStatus: React.FC<SetActionProgressStatusProps> = props => {
  const [updateActionProgressStatus] = useUpdateActionProgressStatusMutation()

  return (
    <Formik<{ status: ActionProgressStatus }>
      enableReinitialize
      initialValues={{ status: props.progress?.status || ActionProgressStatus.NotStarted }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)

        if (!values.status) return

        const data = {
          status: values.status,
          studentId: props.studentId,
          actionId: props.actionId,
        }
        await updateActionProgressStatus({ variables: { data } })
        trackEvent('updateActionProgressStatus', data)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form layout="inline" onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field
            name="status"
            label="Status"
            options={Object.entries(ActionProgressStatus).map(([key, value]) => ({
              value: value,
              label: formatActionProgressStatus(key as ActionProgressStatus),
            }))}
            component={CustomSelectComponent}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
