import React from 'react'
import { Result } from 'antd'
import { useSearchQuery } from '../utils/location'
import { apiCall } from '../utils/rest'
import { trackEvent } from '../utils/analytics'

interface StripeSuccessProps {}

export const StripeSuccess: React.FC<StripeSuccessProps> = props => {
  const query = useSearchQuery()
  const sessionId = query.get('session_id')

  React.useEffect(() => {
    const completePaymentProcess = async () => {
      const session = await apiCall(`/stripe/complete-session/${sessionId}`)

      trackEvent('Successfully added Stripe Payment Info', {
        stripeSession: session,
        email: sessionId,
      })
    }

    completePaymentProcess()
  }, [sessionId])

  return (
    <Result
      status="success"
      title="Success"
      subTitle="Your payment information was updated successfully!"
    />
  )
}
