import { Button, Form } from 'antd'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomCheckboxComponent,
} from '../components/FormikCustom'
import {
  EditStudentInput,
  useGetCohortsQuery,
  useEditStudentMutation,
  GetStudentStudentFragment,
  Stage,
  CandidateStage,
  StudentStage,
  GraduateStage,
} from '../generated/graphql'
import { CountrySelectField } from '../components/CountrySelectField'
import { STUDENT_FRAGMENT } from './fragments'
import { formatStage, formatSubstage } from './utils'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { trackEvent } from '../utils/analytics'

export const EDIT_STUDENT = gql`
  mutation EditStudent($data: EditStudentInput!) {
    editStudent(data: $data) {
      ...GetStudentStudent
    }
  }

  ${STUDENT_FRAGMENT}
`

interface EditStudentFormProps {
  onCreate?: () => void
  student: GetStudentStudentFragment
}

export const EditStudentForm: React.FC<EditStudentFormProps> = props => {
  const [editStudent] = useEditStudentMutation()
  const { data } = useGetCohortsQuery()
  const cohorts = data?.cohorts || []
  const [selectedCohortId, setSelectedCohortId] = React.useState<string>()
  const selectedCohort = cohorts.find(({ id }) => selectedCohortId === id) || cohorts?.[0]

  const contracts = selectedCohort?.program.school.contracts

  const DEFAULT_CONTRACT_FOR_COHORT = 'DEFAULT_CONTRACT_FOR_COHORT'

  return (
    <Formik<EditStudentInput>
      enableReinitialize
      initialValues={{
        id: props.student.id,
        firstName: props.student.firstName,
        lastName: props.student.lastName,
        email: props.student.email,
        phone: props.student.phone,
        street: props.student.street,
        city: props.student.city,
        country: props.student.country,
        zip: props.student.zip,
        passportIdNumber: props.student.passportIdNumber,
        cohortId: props.student.cohorts?.[0]?.id,
        contractId: props.student.contractId,
        plaidPaymentEnabled: props.student.plaidPaymentEnabled,
        stripePaymentEnabled: props.student.stripePaymentEnabled,
        manualPaymentEnabled: props.student.manualPaymentEnabled,
        stage: props.student.stage,
        studentStage: props.student.studentStage,
        candidateStage: props.student.candidateStage,
        graduateStage: props.student.graduateStage,
        bankName: props.student.bankName,
        bankBranch: props.student.bankBranch,
        bankAccountNumber: props.student.bankAccountNumber,
        bankAuthorized: props.student.bankAuthorized,
        blender: props.student.blender,
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)
        if (values.contractId === DEFAULT_CONTRACT_FOR_COHORT) values.contractId = undefined

        editStudent({ variables: { data: values } })
        trackEvent('editStudent', values)
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, values }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field
            name="stage"
            label="Stage"
            options={Object.values(Stage).map(name => ({
              value: name,
              label: formatStage(name),
            }))}
            component={CustomSelectComponent}
          />
          {values.stage === Stage.Candidate ? (
            <Field
              name="candidateStage"
              label="Substage"
              options={Object.values(CandidateStage).map(name => ({
                value: name,
                label: formatSubstage(name),
              }))}
              component={CustomSelectComponent}
            />
          ) : null}
          {values.stage === Stage.Student ? (
            <Field
              name="studentStage"
              label="Substage"
              options={Object.values(StudentStage).map(name => ({
                value: name,
                label: formatSubstage(name),
              }))}
              component={CustomSelectComponent}
            />
          ) : null}
          {values.stage === Stage.Graduate ? (
            <Field
              name="graduateStage"
              label="Substage"
              options={Object.values(GraduateStage).map(name => ({
                value: name,
                label: formatSubstage(name),
              }))}
              component={CustomSelectComponent}
            />
          ) : null}
          <Field name="firstName" label="First Name" component={CustomInputComponent} />
          <Field name="lastName" label="Last Name" component={CustomInputComponent} />
          <Field name="phone" label="Phone" component={CustomInputComponent} />
          <Field name="email" label="Email" type="email" component={CustomInputComponent} />
          <Field name="street" label="Street" component={CustomInputComponent} />
          <Field name="city" label="City" component={CustomInputComponent} />
          <CountrySelectField />
          <Field name="zip" label="ZIP" component={CustomInputComponent} />
          <Field
            name="passportIdNumber"
            label="ID / Passport Number"
            component={CustomInputComponent}
          />
          {cohorts && (
            <Field
              name="cohortId"
              label="Cohort"
              options={cohorts.map(cohort => ({
                value: cohort.id,
                label: `${cohort.name} - ${cohort.program.name} - ${cohort.program.school.name}`,
              }))}
              component={CustomSelectComponent}
              onChange={(cohortId: string) => {
                setSelectedCohortId(cohortId)
              }}
            />
          )}

          {contracts && (
            <Field
              name="contractId"
              label="Contract"
              options={[
                { value: DEFAULT_CONTRACT_FOR_COHORT, label: 'Default for cohort' },
                ...contracts.map(contract => ({
                  value: contract.id,
                  label: `${contract.name}`,
                })),
              ]}
              component={CustomSelectComponent}
            />
          )}
          <Field
            name="plaidPaymentEnabled"
            label="Plaid Payment Enabled"
            component={CustomCheckboxComponent}
          />
          <Field
            name="stripePaymentEnabled"
            label="Stripe Payment Enabled"
            component={CustomCheckboxComponent}
          />
          <Field
            name="manualPaymentEnabled"
            label="Manual Payment Enabled"
            component={CustomCheckboxComponent}
          />

          <Field name="bankName" label="Bank Name" component={CustomInputComponent} />
          <Field name="bankBranch" label="Bank Branch" component={CustomInputComponent} />
          <Field
            name="bankAccountNumber"
            label="Bank Account Number"
            component={CustomInputComponent}
          />
          <Field
            name="bankAuthorized"
            label="Bank Authorized"
            component={CustomCheckboxComponent}
          />
          <Field name="blender" label="Blender" component={CustomCheckboxComponent} />

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
