import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useParams } from 'react-router-dom'
import { openErrorNotification } from '../components/Notification'
import { apiCall } from '../utils/rest'
import { STRIPE_PUBLIC_KEY } from '../utils/config'
import { trackEvent } from '../utils/analytics'
import { Loading } from '../components/Loading'

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Title = styled.h2`
  margin-bottom: 15px;
`

const Description = styled.p`
  max-width: 400px;
  margin-bottom: 35px;
  text-align: center;
`

interface StripePaymentProps {}

export const StripePayment: React.FC<StripePaymentProps> = props => {
  const { program, studentEmail } = useParams<{ program: string; studentEmail: string }>()
  const [stripePromise, setStripePromise] = React.useState<any>()

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  // See: https://github.com/stripe/stripe-js/issues/43#issuecomment-644256884
  React.useEffect(() => {
    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)
    setStripePromise(stripePromise)
  }, [])

  const handleClick = async () => {
    trackEvent('Click Stripe Add Payment Info Button', { program, email: studentEmail })

    try {
      const { sessionId } = await apiCall(`/stripe/create-session/${program}/${studentEmail}`)

      const stripe = await stripePromise
      if (!stripe) return
      const { error } = await stripe.redirectToCheckout({ sessionId })

      // we only get here if redirect didn't happen
      console.error('error', error)

      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      openErrorNotification({ description: error.message })
    } catch (error) {
      openErrorNotification({ description: error.message })
    }
  }

  return (
    <Wrapper>
      <Title>Spark</Title>
      <Description>
        On the next screen you'll be asked to fill in your payment information. Once you've
        graduated and find employment you will be charged through this payment method.
      </Description>
      <Button type="primary" role="link" onClick={handleClick}>
        Add Payment Info
      </Button>
    </Wrapper>
  )
}

export const StripePaymentDirect: React.FC<StripePaymentProps> = props => {
  const { program } = useParams<{ program: string }>()
  const [stripePromise, setStripePromise] = React.useState<any>()

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  // See: https://github.com/stripe/stripe-js/issues/43#issuecomment-644256884
  React.useEffect(() => {
    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)
    setStripePromise(stripePromise)

    const redirectToStripe = async () => {
      trackEvent('Load Stripe direct payment')

      try {
        const { sessionId } = await apiCall(`/stripe/create-payment-session/${program}`)

        const stripe = await stripePromise
        if (!stripe) return
        const { error } = await stripe.redirectToCheckout({ sessionId })

        // we only get here if redirect didn't happen
        console.error('error', error)

        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        openErrorNotification({ description: error.message })
      } catch (error) {
        openErrorNotification({ description: error.message })
      }
    }

    redirectToStripe()
  }, [program])

  return <Loading />
}
