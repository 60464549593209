import { sentenceCase } from 'change-case'
import { Stage, CandidateStage, StudentStage, GraduateStage, Student } from '../generated/graphql'

export const formatStage = (stage: Stage | 'ALL') => {
  return sentenceCase(stage)
}

export const formatSubstage = (substage: string) => {
  return sentenceCase(substage)
}

export const stageColors = {
  [Stage.Candidate]: 'purple',
  [Stage.Onboarding]: 'magenta',
  [Stage.Student]: 'gold',
  [Stage.Graduate]: 'green',
}

export const candidateStageColors = {
  [CandidateStage.Interviewing]: 'gold',
  [CandidateStage.Accepted]: 'green',
  [CandidateStage.Rejected]: 'red',
}

export const studentStageColors = {
  [StudentStage.Studying]: 'gold',
  [StudentStage.Completed]: 'green',
  [StudentStage.DropOut]: 'red',
}

export const graduateStageColors = {
  [GraduateStage.Graduate]: 'gold',
  [GraduateStage.Employed]: 'green',
  [GraduateStage.Unemployed]: 'red',
}

export const substageColors = {
  ...candidateStageColors,
  ...studentStageColors,
  ...graduateStageColors,
}

export const getSubstageColors = (
  stage: Stage,
  substages: Pick<Student, 'candidateStage' | 'studentStage' | 'graduateStage'>
) => {
  switch (stage) {
    case Stage.Candidate:
      return substages.candidateStage ? candidateStageColors[substages.candidateStage] : undefined
    case Stage.Student:
      return substages.studentStage ? candidateStageColors[substages.studentStage] : undefined
    case Stage.Graduate:
      return substages.graduateStage ? candidateStageColors[substages.graduateStage] : undefined
  }
}
