import React from 'react'
import { Card } from 'antd'
import { gql } from 'apollo-boost'
import { ViewActionGroup, ACTION_FRAGMENT } from '../actions/ActionGroups'
import { SetActionGroupForm } from '../components/SetActionGroupForm'
import { Loading } from '../components/Loading'
import { ErrorGeneral } from '../components/ErrorPages'
import { useProgramActionGroupQuery, useEditProgramActionGroupMutation } from '../generated/graphql'
import { trackEvent } from '../utils/analytics'

gql`
  ${ACTION_FRAGMENT}

  query ProgramActionGroup($programId: ID!) {
    program(id: $programId) {
      id
      defaultActionGroupId
      defaultActionGroup {
        name
        actions {
          ...Action
        }
      }
    }
  }
`

gql`
  mutation EditProgramActionGroup($defaultActionGroupId: String!, $programId: String!) {
    editProgramActionGroup(defaultActionGroupId: $defaultActionGroupId, programId: $programId) {
      id
      defaultActionGroupId
    }
  }
`

interface ProgramSettingsProps {
  programId: string
}

export const ProgramSettings: React.FC<ProgramSettingsProps> = ({ programId }) => {
  const { data, loading, error } = useProgramActionGroupQuery({
    variables: { programId },
  })
  const [editProgramActionGroup] = useEditProgramActionGroupMutation()

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  return (
    <Card
      headStyle={{ borderBottom: 'none' }}
      bordered={false}
      title={`Default Actions Group`}
      bodyStyle={{ padding: '0 32px 40px 32px' }}
    >
      <SetActionGroupForm
        actionGroupId={data.program.defaultActionGroupId || undefined}
        editActionGroup={async defaultActionGroupId => {
          const variables = { programId: data.program.id, defaultActionGroupId }
          editProgramActionGroup({ variables })
          trackEvent('editProgramActionGroup', variables)
        }}
      />

      <div style={{ paddingTop: 20 }}>
        {data.program.defaultActionGroupId && (
          <ViewActionGroup actionGroupId={data.program.defaultActionGroupId} />
        )}
      </div>
    </Card>
  )
}
