import React from 'react'
import { Form, Button } from 'antd'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { ActionGroupSelectField } from './ActionGroupSelectField'

interface SetActionsGroupFormProps {
  actionGroupId?: string
  editActionGroup: (actionGroupId: string) => Promise<void>
  onCreate?: () => void
}

export const SetActionGroupForm: React.FC<SetActionsGroupFormProps> = ({
  actionGroupId,
  editActionGroup,
  onCreate,
}) => {
  return (
    <Formik<{ actionGroupId?: string | null }>
      initialValues={{ actionGroupId: actionGroupId || null }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)

        if (!values.actionGroupId) return

        await editActionGroup(values.actionGroupId)
        setSubmitting(false)
        onCreate && onCreate()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form layout="inline" onFinish={() => handleSubmit()} onReset={handleReset}>
          <ActionGroupSelectField />

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
              Update
            </Button>
          </Form.Item>

          <Form.Item>
            <Link to="/action-groups">Create New</Link>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
