import { Button, Form } from 'antd'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import { Moment } from 'moment'
import { openErrorNotification } from '../components/Notification'

import { formItemLayout, tailFormItemLayout } from '../components/Form'
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomDateRangeComponent,
} from '../components/FormikCustom'
import {
  CreateCohortInput,
  useCreateCohortMutation,
  useGetSchoolContractsFullQuery,
} from '../generated/graphql'
import { GET_PROGRAM } from './Program'
import { Link } from 'react-router-dom'
import { trackEvent } from '../utils/analytics'

export const CREATE_COHORT = gql`
  fragment CreateCohort on Cohort {
    id
    name
    startDate
    endDate
    tag
    onboardingTrack {
      id
      name
    }
  }

  mutation CreateCohort($data: CreateCohortInput!) {
    createCohort(data: $data) {
      ...CreateCohort
    }
  }
`

gql`
  query GetSchoolContracts($schoolId: ID!) {
    contractsForSchool(schoolId: $schoolId) {
      id
      name
    }
  }
`

interface CreateCohortFormProps {
  programId: string
  schoolId: string
  onCreate?: () => void
}

export const CreateCohortForm: React.FC<CreateCohortFormProps> = props => {
  const { programId, schoolId } = props
  const [createCohort] = useCreateCohortMutation({
    refetchQueries: [{ query: GET_PROGRAM, variables: { programId } }],
  })
  const { data } = useGetSchoolContractsFullQuery({
    fetchPolicy: 'cache-and-network',
    variables: { schoolId },
  })
  const contracts = data?.contractsForSchool

  return (
    <Formik<CreateCohortInput & { dates?: Moment[] }>
      initialValues={{
        name: '',
        startDate: new Date(),
        endDate: new Date(),
        programId,
        tag: undefined,
        defaultContractId: undefined,
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (!values.name) {
          openErrorNotification({ message: 'Error', description: 'Name is required' })
          setSubmitting(false)
          return
        }

        if (!values.dates || !values.dates[0] || !values.dates[1]) {
          openErrorNotification({ message: 'Error', description: 'Dates are required' })
          setSubmitting(false)
          return
        }

        const data = {
          name: values.name,
          startDate: values.dates[0].toDate(),
          endDate: values.dates[1].toDate(),
          tag: values.tag,
          programId: values.programId,
          defaultContractId: values.defaultContractId,
        }
        createCohort({ variables: { data } })
        trackEvent('createCohort', data)
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="name" label="Name" component={CustomInputComponent} />
          <Field name="dates" label="Dates" component={CustomDateRangeComponent} />
          <Field name="tag" label="Tag" component={CustomInputComponent} />

          {contracts && (
            <Field
              name="defaultContractId"
              label="Contract"
              options={contracts.map(contract => ({
                value: contract.id,
                label: `${contract.name}`,
              }))}
              component={CustomSelectComponent}
            />
          )}
          <Form.Item {...tailFormItemLayout}>
            <Link to="/contracts">Create Contract</Link>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Add
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
