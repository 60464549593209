import React from 'react'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import { Link, useParams } from 'react-router-dom'
import { Tag } from 'antd'
import { gql } from 'apollo-boost'
import { useGetSchoolQuery, useStudentsByTagQuery } from '../generated/graphql'
import { Loading } from '../components/Loading'
import { STUDENT_FRAGMENT, StudentsContent } from './Students'

gql`
  ${STUDENT_FRAGMENT}

  query StudentsByTag($schoolId: ID, $tag: String) {
    students(schoolId: $schoolId, tag: $tag) {
      ...Student
    }
  }
`

interface StudentsCohortTagProps {}

export const StudentsCohortTag: React.FC<StudentsCohortTagProps> = props => {
  const { schoolId, tag } = useParams<{ schoolId: string; tag: string }>()

  const { data, loading } = useGetSchoolQuery({ variables: { schoolId } })
  const { data: dataStudents, loading: loadingStudents } = useStudentsByTagQuery({
    variables: { schoolId, tag },
  })

  if (loading || loadingStudents) return <Loading />
  if (!data || !dataStudents) return null

  const { school } = data
  const { students } = dataStudents

  return (
    <PageHeaderWrapper
      breadcrumb={{
        itemRender: ({ path, breadcrumbName }) => <Link to={path}>{breadcrumbName}</Link>,
        routes: [
          {
            breadcrumbName: school.name,
            path: `/school/${schoolId}`,
          },
          {
            breadcrumbName: tag,
            path: '#',
          },
        ],
      }}
      title={
        <>
          {school.name} {tag ? <Tag color="green">{tag}</Tag> : null}
        </>
      }
      content={`${students.length || 0} students`}
    >
      <StudentsContent
        students={students}
        showSchoolInfo={false}
        showSalary={true}
        title="Students"
      />
    </PageHeaderWrapper>
  )
}
