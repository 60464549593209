import React from 'react'
import { Button, Result } from 'antd'
import { blue } from '@ant-design/colors'
import styled from 'styled-components'
import { Loading } from '../../components/Loading'
import { openSuccessNotification, openErrorNotification } from '../../components/Notification'

const ContentWrapper = styled.div`
  padding-bottom: 20px;
`

const IFrame = styled.iframe`
  width: 100%;
  border: 1px solid ${blue[0]};
  height: 300px;
`

// const PlainContent = styled.div`
//   white-space: pre-wrap;
// `

interface SendEmailProps {
  onSend: () => Promise<any>
  onSuccess?: () => void
  subject: string
  htmlContentWithDynamicData: string
  plainContent: string
  loading?: boolean
  error?: any
}

export const SendEmail: React.FC<SendEmailProps> = props => {
  if (props.loading) return <Loading />
  if (props.error) {
    console.error('props.error', props.error)
    return (
      <Result status="404" title="Error" subTitle="Sorry, there is no template for this email." />
    )
  }

  const onSendClick = async () => {
    try {
      await props.onSend()
      openSuccessNotification({ description: 'Sent email' })
      props.onSuccess?.()
    } catch (error) {
      console.log('onSendClick -> error', error)
      openErrorNotification({ description: 'Error sending email' })
    }
  }

  return (
    <div>
      <ContentWrapper>
        <h3>Subject: {props.subject}</h3>
        <h3>HTML Content:</h3>
        <IFrame title="Email content" srcDoc={props.htmlContentWithDynamicData} />

        {/* <h3>Plain Content</h3>
        <PlainContent>{props.plainContent}</PlainContent>

        <Divider /> */}

        <div>
          <a href="https://mc.sendgrid.com/dynamic-templates" target="blank">
            Edit template on Sendgrid
          </a>{' '}
          Use{' '}
          <a href="https://handlebarsjs.com/guide/" target="blank">
            Handlebars
          </a>{' '}
          syntax like so {`{{var}}`} for variables.
        </div>
      </ContentWrapper>

      <Button type="primary" onClick={onSendClick}>
        Send Email
      </Button>
    </div>
  )
}
