import React from 'react'
import styled from 'styled-components'
import { Card, Row, Col, Tag } from 'antd'
import { green } from '@ant-design/colors'
import { Link } from 'react-router-dom'
import { formatContractType, formatThresholdType } from '../../contract/utils'
import { formatAmount } from '../../utils/currency'
import { formatDate } from '../../utils/date'
import { GetStudentStudentFragment, Student } from '../../generated/graphql'
import { CardWrapper } from '../../components/Card'

const Subheading = styled.span`
  font-weight: bold;
  line-height: 40px;
  text-align: left;
`

const CardDatum: React.FC<{ label: string; value?: string | React.ReactNode }> = props => {
  return (
    <div>
      <Subheading>{props.label}: </Subheading>
      <span>{props.value ?? ''}</span>
    </div>
  )
}

interface OverviewProps {
  student: GetStudentStudentFragment
}

export const Overview: React.FC<OverviewProps> = props => {
  const { student } = props

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={8}>
        <CardWrapper>
          <Card title="Personal Information">
            <CardDatum label="Spark ID" value={student.id} />
            <CardDatum label="ID/Passport" value={student.passportIdNumber} />
            <CardDatum
              label="Email"
              value={<a href={`mailto:${student.email}`}>{student.email}</a>}
            />
            <CardDatum label="Address" value={student.address} />
            <CardDatum label="Added" value={formatDate(new Date(student.createdAt))} />
          </Card>
        </CardWrapper>

        <Card title="Google Drive">
          <CardDatum
            label="Folder"
            value={
              student.driveFolder?.id ? (
                <a
                  href={`https://drive.google.com/drive/u/0/folders/${student.driveFolder.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              ) : (
                'Not found'
              )
            }
          />
          <CardDatum
            label="Contract"
            value={
              student.driveContract?.id && student.driveFolder?.id ? (
                <a
                  href={`https://drive.google.com/drive/u/0/folders/${student.driveFolder.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              ) : (
                'Not found'
              )
            }
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <CardWrapper>
          <Card
            title="Employment Status"
            extra={
              <Tag color={student.currentEmployment ? 'green' : 'red'}>
                {student.currentEmployment ? 'EMPLOYED' : 'UNEMPLOYED'}
              </Tag>
            }
          >
            {student.currentEmployment ? (
              <>
                <CardDatum
                  label="Annual Salary"
                  value={formatAmount(
                    (student.currentEmployment.amountPerMonth ?? 0) * 12,
                    student.currentEmployment.currency
                  )}
                />
                <CardDatum
                  label="Monthly Salary"
                  value={formatAmount(
                    student.currentEmployment.amountPerMonth ?? 0,
                    student.currentEmployment.currency
                  )}
                />
                <CardDatum
                  label="Hourly Salary"
                  value={formatAmount(
                    student.currentEmployment.amountPerHour ?? 0,
                    student.currentEmployment.currency
                  )}
                />
                <CardDatum label="Current Employer" value={student.currentEmployment.companyName} />
                <CardDatum label="Payment Day" value={student.currentEmployment.paymentDay} />
              </>
            ) : (
              <div>Employment information will appear here once the graduate finds a job.</div>
            )}
          </Card>
        </CardWrapper>

        <CardWrapper>
          <Card title="Payment Integrations">
            <CardDatum label="Plaid" value={student.plaidPaymentEnabled ? 'Enabled' : 'Disabled'} />
            <CardDatum label="Stripe" value={<StripeCell {...student} />} />
            <CardDatum
              label="Manual Integration"
              value={student.manualPaymentEnabled ? 'Enabled' : 'Disabled'}
            />
          </Card>
        </CardWrapper>

        <CardWrapper>
          <Card title="Bank Details">
            <CardDatum label="Bank" value={student.bankName} />
            <CardDatum label="Branch" value={student.bankBranch} />
            <CardDatum label="Account No." value={student.bankAccountNumber} />
            <CardDatum label="Authorized" value={student.bankAuthorized ? 'Yes' : 'No'} />
            <CardDatum label="Blender" value={student.blender ? 'Yes' : 'No'} />
          </Card>
        </CardWrapper>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <CardWrapper>
          <Card
            title="Payment Status"
            // extra={
            //   <Tag color={onTime ? 'green' : 'red'}>{onTime ? 'ON TIME' : 'DELAYED'}</Tag>
            // }
          >
            {student.paymentSummary ? (
              <>
                <CardDatum
                  label="Payments Completed"
                  value={`${student.paymentSummary.paymentsPaid || 0}/${
                    student.paymentSummary.paymentsExpected
                  }`}
                />
                <CardDatum
                  label="First Payment"
                  value={
                    student.paymentSummary.firstPayment
                      ? formatDate(new Date(student.paymentSummary.firstPayment))
                      : 'No payments made'
                  }
                />
                <CardDatum
                  label="Last Payment"
                  value={
                    student.paymentSummary.lastPayment
                      ? formatDate(new Date(student.paymentSummary.lastPayment))
                      : 'No payments made'
                  }
                />
                <CardDatum
                  label="Balance Paid"
                  value={`${formatAmount(
                    student.paymentSummary.balancePaid,
                    student.paymentSummary.currency
                  )}/${formatAmount(
                    student.paymentSummary.balanceExpected,
                    student.paymentSummary.currency
                  )}`}
                />
              </>
            ) : (
              <div>Payment summary not available</div>
            )}
          </Card>
        </CardWrapper>

        {student.contract && (
          <CardWrapper>
            <ContractCard contract={student.contract} viewLink />
          </CardWrapper>
        )}
      </Col>
    </Row>
  )
}

interface ContractCardProps {
  contract: GetStudentStudentFragment['contract']
  viewLink: boolean
  titlePrefix?: string
}

export const ContractCard: React.FC<ContractCardProps> = props => {
  const { contract, viewLink, titlePrefix = '' } = props

  if (!contract) return null

  return (
    <Card
      title={`${titlePrefix}${contract.name}`}
      extra={viewLink ? <Link to={`/contract/${contract.id}`}>View Contract</Link> : null}
    >
      <CardDatum label="Type" value={formatContractType(contract.type)} />
      <CardDatum label="Threshold" value={formatAmount(contract.threshold, contract.currency)} />
      <CardDatum label="Rate" value={contract.ratePercentage + '%'} />
      <CardDatum label="Payment Period" value={`${contract.periodPostEmploymentMonths} months`} />
      <CardDatum label="Contract Period" value={`${contract.periodPostGraduationMonths} months`} />
      <CardDatum label="Cap" value={formatAmount(contract.cap, contract.currency)} />
      <CardDatum
        label="Upfront Payment"
        value={formatAmount(contract.upfrontPayment, contract.currency)}
      />
      <CardDatum label="Threshold Type" value={formatThresholdType(contract.thresholdType)} />
      <CardDatum label="Dropout Interest" value={contract.dropoutInterest + '%'} />
      <CardDatum
        label="Dropout Principal"
        value={formatAmount(contract.dropoutPrincipal, contract.currency)}
      />
      <CardDatum
        label="File URL"
        value={
          contract.fileUrl ? (
            <a href={contract.fileUrl} target="_blank" rel="noopener noreferrer">
              {contract.fileUrl}
            </a>
          ) : (
            '-'
          )
        }
      />
    </Card>
  )
}

interface StripeCellProps
  extends Pick<
    Student,
    'id' | 'email' | 'stripeCustomerId' | 'stripePaymentEnabled' | 'stripePaymentMethodId'
  > {}

export const StripeCell: React.FC<StripeCellProps> = props => {
  return (
    <>
      {props.stripePaymentEnabled ? (
        props.stripePaymentMethodId ? (
          <span style={{ color: green.primary }}>Connected</span>
        ) : (
          <>
            Enabled (<a href={`/stripe/program/${props.email}`}>Link</a>)
          </>
        )
      ) : (
        'Disabled'
      )}
    </>
  )
}
