import React from 'react'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import { Table, Button, Space, List } from 'antd'
import styled from 'styled-components'
import { gql } from 'apollo-boost'
import { ColumnProps } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { formatAmount } from '../utils/currency'
import { formatDate } from '../utils/date'
import { Loading } from '../components/Loading'
import {
  usePaymentsQuery,
  usePendingPaymentsQuery,
  PaymentFragment,
  PaymentMethod,
  useChargeEmployedStudentsMutation,
  useConfirmPendingPaymentMutation,
  Payment,
} from '../generated/graphql'
import { SERVER_URL, STRIPE_DASHBOARD_URL } from '../../src/utils/config'
import { FileUpload } from '../components/FileUpload'
import { ErrorGeneral } from '../components/ErrorPages'
import { openSuccessNotification, openErrorNotification } from '../components/Notification'
import { formatPaymentMethod } from './utils'
import { useTab } from '../utils/location'
import { PENDING_PAYMENTS_COUNT_QUERY } from '../components/RightNavContent'
import { useAuth0 } from '../auth/auth0'
import {
  BulkResultList,
  BulkResultListSuccess,
  BulkResultListError,
} from '../components/BulkResultList'
import { trackEvent } from '../utils/analytics'

const PAYMENT_FRAGMENT = gql`
  fragment Payment on Payment {
    id
    amount
    currency
    paymentDate
    paymentMethod
    stripePaymentIntentId
    createdAt
    note
    student {
      id
      name
    }
  }
`

export const PAYMENTS_QUERY = gql`
  query Payments {
    payments {
      ...Payment
    }
  }

  ${PAYMENT_FRAGMENT}
`

export const PENDING_PAYMENTS_QUERY = gql`
  query PendingPayments {
    pendingPayments {
      ...Payment
    }
  }

  ${PAYMENT_FRAGMENT}
`

gql`
  mutation ChargeEmployedStudents {
    chargeEmployedStudents
  }
`

gql`
  mutation ConfirmPendingPayment($id: String!, $confirm: Boolean!) {
    confirmPendingPayment(pendingPaymentId: $id, confirm: $confirm) {
      ...Payment
    }
  }

  ${PAYMENT_FRAGMENT}
`

const FileUploadWrapper = styled.div`
  margin-bottom: 20px;
`

export const paymentIdColumn: ColumnProps<PaymentFragment> = {
  title: 'Payment ID',
  dataIndex: 'id',
  key: 'id',
}

export const studentColumn: ColumnProps<PaymentFragment> = {
  title: 'Student',
  dataIndex: 'student',
  key: 'student',
  render: (student: PaymentFragment['student']) => {
    return <Link to={`/student/${student.id}`}>{student.name}</Link>
  },
}

export const amountColumn: ColumnProps<PaymentFragment> = {
  title: 'Amount',
  dataIndex: 'amount',
  key: 'amount',
  render: (amount: number, row) => {
    return <>{formatAmount(amount, row.currency)}</>
  },
  sorter: (a, b) => a.amount - b.amount,
}

export const paymentDateColumn: ColumnProps<PaymentFragment> = {
  title: 'Payment Date',
  dataIndex: 'paymentDate',
  key: 'paymentDate',
  render: paymentDate => <>{paymentDate && formatDate(new Date(paymentDate))}</>,
  defaultSortOrder: 'descend',
  sorter: (a, b) => +new Date(a.paymentDate) - +new Date(b.paymentDate),
}

export const pendingPaymentCreatedAtDateColumn: ColumnProps<PaymentFragment> = {
  title: 'Created Date',
  dataIndex: 'createdAt',
  key: 'createdAt',
  render: createdAt => <>{createdAt && formatDate(new Date(createdAt))}</>,
  defaultSortOrder: 'descend',
  sorter: (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt),
}

export const paymentMethodColumn: ColumnProps<PaymentFragment> = {
  title: 'Payment Method',
  dataIndex: 'paymentMethod',
  key: 'paymentMethod',
  render: (value: PaymentMethod, row) => {
    if (value === PaymentMethod.Stripe) {
      return (
        <a href={`${STRIPE_DASHBOARD_URL}/payments/${row.stripePaymentIntentId}`}>
          {formatPaymentMethod(value)}
        </a>
      )
    }

    return <>{formatPaymentMethod(value)}</>
  },
}

export const noteColumn: ColumnProps<PaymentFragment> = {
  title: 'Note',
  dataIndex: 'note',
  key: 'note',
}

const Confirm: React.FC<{ pendingPayment: PaymentFragment }> = props => {
  const [confirmPendingPayment] = useConfirmPendingPaymentMutation({
    refetchQueries: [
      { query: PAYMENTS_QUERY },
      { query: PENDING_PAYMENTS_QUERY },
      { query: PENDING_PAYMENTS_COUNT_QUERY },
    ],
  })

  return (
    <Space direction="horizontal" size="small">
      <Button
        type="primary"
        onClick={() => {
          const variables = { id: props.pendingPayment.id, confirm: true }
          confirmPendingPayment({ variables })
          trackEvent('confirmPendingPayment', variables)
        }}
      >
        Confirm
      </Button>
      <Button
        type="primary"
        danger
        onClick={() => {
          const variables = { id: props.pendingPayment.id, confirm: false }
          confirmPendingPayment({ variables })
          trackEvent('declinePendingPayment', variables)
        }}
      >
        Reject
      </Button>
    </Space>
  )
}

export const confirmColumn: ColumnProps<PaymentFragment> = {
  title: 'Confirm',
  dataIndex: 'confirm',
  key: 'confirm',
  render: (_value, row) => {
    return <Confirm pendingPayment={row} />
  },
}

interface PaymentsTableProps {
  payments: PaymentFragment[]
  columns: ColumnProps<PaymentFragment>[]
}

export const PaymentsTable: React.FC<PaymentsTableProps> = props => {
  return (
    <Table
      rowKey="id"
      dataSource={props.payments}
      columns={props.columns}
      pagination={{ pageSize: 50 }}
    />
  )
}

type PaymentBulkUploadResult =
  | Payment
  | {
      status?: string
      message?: string
      row?: any
    }

type PaymentTab = 'All' | 'NeedConfirm'

interface PaymentsProps {}

export const Payments: React.FC<PaymentsProps> = props => {
  const { user } = useAuth0()
  const [state, setState] = React.useState({ charging: false })
  const [bulkUploadResult, setBulkUploadResult] = React.useState<PaymentBulkUploadResult[]>()

  const [selectedTab, setSelectedTab] = useTab<PaymentTab>('All')

  const { data, loading, error, refetch } = usePaymentsQuery()
  const [chargeStudents] = useChargeEmployedStudentsMutation()

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  return (
    <PageHeaderWrapper
      title="Payments"
      extra={
        user?.adminUser ? (
          <Button
            type="primary"
            loading={state.charging}
            disabled={state.charging}
            onClick={async () => {
              try {
                setState({ charging: true })
                await chargeStudents({
                  refetchQueries: [
                    { query: PAYMENTS_QUERY },
                    { query: PENDING_PAYMENTS_QUERY },
                    { query: PENDING_PAYMENTS_COUNT_QUERY },
                  ],
                })
                openSuccessNotification({ description: 'Charged students' })
              } catch (error) {
                console.error(error)
                openErrorNotification({ description: 'Error charging students' })
              } finally {
                setState({ charging: false })
              }
            }}
          >
            Charge Employed Stripe Students
          </Button>
        ) : null
      }
      tabList={[
        { key: 'All', tab: 'All' },
        { key: 'NeedConfirm', tab: 'Waiting' },
      ]}
      tabActiveKey={selectedTab}
      onTabChange={tab => {
        setSelectedTab(tab as PaymentTab)
      }}
    >
      {selectedTab === 'All' ? (
        <>
          {user?.adminUser ? (
            <FileUploadWrapper>
              <FileUpload
                action={`${SERVER_URL}/payment/upload-csv`}
                onSuccess={bulkUploadResult => {
                  setBulkUploadResult(bulkUploadResult)
                  refetch()
                }}
                sampleFile="https://docs.google.com/spreadsheets/d/1nF9xQ55m1Kx2S97cLoKtFAf9IJNIhGtiURepOUvdSnI"
              />
              {bulkUploadResult && (
                <BulkResultList
                  bulkUploadResult={bulkUploadResult}
                  renderItem={(item: PaymentBulkUploadResult) => {
                    return (
                      <List.Item>
                        {isPaymentMessage(item) ? (
                          <BulkResultListSuccess
                            message={
                              <span>
                                Student id:{' '}
                                <Link to={`/student/${item.studentId}`}>{item.studentId}</Link>{' '}
                                {item.amount / 100} {item.currency}
                              </span>
                            }
                          />
                        ) : (
                          <BulkResultListError status={item.status} message={item.message} />
                        )}
                      </List.Item>
                    )
                  }}
                />
              )}
            </FileUploadWrapper>
          ) : null}

          <AllPayments payments={data.payments} />
        </>
      ) : (
        <NeedConfirmPayments payments={data.payments} />
      )}
    </PageHeaderWrapper>
  )
}

const AllPayments: React.FC<{ payments: PaymentFragment[] }> = props => {
  const columns: ColumnProps<PaymentFragment>[] = [
    paymentIdColumn,
    studentColumn,
    amountColumn,
    paymentDateColumn,
    paymentMethodColumn,
    noteColumn,
  ]

  return <PaymentsTable payments={props.payments} columns={columns} />
}

const NeedConfirmPayments: React.FC<{ payments: PaymentFragment[] }> = props => {
  const { user } = useAuth0()
  const { data, loading, error } = usePendingPaymentsQuery()
  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  const columns: ColumnProps<PaymentFragment>[] = [studentColumn, amountColumn, paymentMethodColumn]

  if (user?.adminUser) columns.push(confirmColumn)

  return <PaymentsTable payments={data.pendingPayments} columns={columns} />
}

function isPaymentMessage(result: PaymentBulkUploadResult): result is Payment {
  return (result as Payment).id !== undefined
}
