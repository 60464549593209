import React from 'react'

// based off of: https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // just see logs
      return null
      // // You can render any custom fallback UI
      // return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}
