import React from 'react'
import { Table } from 'antd'
import { blue } from '@ant-design/colors'
import { DownloadOutlined } from '@ant-design/icons'
import { CardWrapper } from '../../components/Card'
import { GetStudentStudentFragment } from '../../generated/graphql'

const documentsColumns = [
  {
    title: 'Document',
    dataIndex: 'document',
    key: 'document',
  },
  {
    title: 'File',
    dataIndex: 'file',
    key: 'file',
    render: file => {
      return (
        <span>
          <DownloadOutlined style={{ color: blue.primary }} />{' '}
          <a href={file} key={file}>
            Download File
          </a>
        </span>
      )
    },
  },
]

interface DocumentsProps {
  student: GetStudentStudentFragment
}

export const Documents: React.FC<DocumentsProps> = props => {
  const { student } = props

  return (
    <CardWrapper>
      <Table
        rowKey="document"
        dataSource={[
          { document: 'Id Front Image Url', file: student.idFrontImageUrl },
          { document: 'Id Back Image Url', file: student.idBackImageUrl },
          { document: 'Stub Image Url', file: student.stubImageUrl },
          { document: 'Check Image Url', file: student.checkImageUrl },
          { document: 'Signature Image Url', file: student.signatureImageUrl },
          { document: 'Agreement Video Url', file: student.agreementVideoUrl },
        ]}
        columns={documentsColumns}
        pagination={false}
      />
    </CardWrapper>
  )
}
