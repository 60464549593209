import { Popconfirm, message, Button } from 'antd'
import { gql } from 'apollo-boost'
import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import { Loading } from '../components/Loading'
import { useGetCohortQuery, useDeleteCohortMutation } from '../generated/graphql'
import { ButtonModalLink } from '../components/ButtonModalLink'
import { EditCohortForm } from './EditCohort'
import { formatGraphQLError } from '../utils/error'
import { StudentsContent, STUDENT_FRAGMENT } from '../students/Students'
import { ContractCard } from '../students/StudentAdmin/Overview'
import { CONTRACT_FRAGMENT } from '../students/fragments'
import { CardWrapper } from '../components/Card'
import { useTab } from '../utils/location'
import { CohortSettings } from './CohortSettings'
import { ExtraActions } from '../components/ExtraActions'
import { trackEvent } from '../utils/analytics'
import { TagLink } from '../components/TagLink'
import { CreateStudentForm } from '../students/CreateStudent'
import { StudentsProgress } from '../students/StudentsProgress'

export const GET_COHORT = gql`
  ${STUDENT_FRAGMENT}
  ${CONTRACT_FRAGMENT}

  fragment GetCohortCohort on Cohort {
    id
    name
    programId
    startDate
    endDate
    tag
    students {
      ...Student
      progresses @include(if: $includeProgress) {
        id
        status
        actionId
      }
    }
    defaultContractId
    defaultContract {
      ...Contract
    }
    program {
      id
      schoolId
      name
      school {
        id
        name
      }
    }
  }

  query GetCohort($cohortId: ID!, $includeProgress: Boolean!) {
    cohort(id: $cohortId) {
      ...GetCohortCohort
    }
  }
`

gql`
  mutation DeleteCohort($cohortId: String!) {
    deleteCohort(cohortId: $cohortId)
  }
`

const Tab = {
  OVERVIEW: 'overview',
  PROGRESS: 'progress',
  SETTINGS: 'settings',
}

export const Cohort: React.FC<{}> = props => {
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useTab<string>(Tab.OVERVIEW)
  const { cohortId } = useParams<{ cohortId: string }>()
  const { data, loading } = useGetCohortQuery({ variables: { cohortId, includeProgress: true } })
  const [deleteCohort] = useDeleteCohortMutation({ variables: { cohortId } })

  if (loading) return <Loading />
  if (!data?.cohort) return <p>Cohort not found.</p>
  console.log('data', data.cohort.students)

  const { cohort } = data

  return (
    <PageHeaderWrapper
      breadcrumb={{
        itemRender: ({ path, breadcrumbName }) => <Link to={path}>{breadcrumbName}</Link>,
        routes: [
          {
            breadcrumbName: cohort.program.school.name,
            path: `/school/${cohort.program.school.id}`,
          },
          {
            breadcrumbName: cohort.program.name,
            path: `/program/${cohort.program.id}`,
          },
          {
            breadcrumbName: cohort.name,
            path: '#',
          },
        ],
      }}
      title={
        <>
          {cohort.name}{' '}
          {cohort.tag ? <TagLink tag={cohort.tag} schoolId={cohort.program.school.id} /> : null}
        </>
      }
      content={`${cohort.students.length || 0} students`}
      tabList={[
        { key: Tab.OVERVIEW, tab: 'Overview' },
        { key: Tab.PROGRESS, tab: 'Progress' },
        { key: Tab.SETTINGS, tab: 'Settings' },
      ]}
      tabActiveKey={selectedTab}
      onTabChange={tab => {
        setSelectedTab(tab)
        history.replace({ pathname: history.location.pathname, search: `?tab=${tab}` })
      }}
      extra={
        <ExtraActions>
          <ButtonModalLink
            title="Add Student"
            content={closeModal => <CreateStudentForm onCreate={closeModal} cohortId={cohort.id} />}
          />
          <ButtonModalLink
            title="Edit"
            content={closeModal => (
              <EditCohortForm
                onCreate={closeModal}
                schoolId={cohort.program.schoolId}
                cohort={cohort}
              />
            )}
          />
          <Popconfirm
            title="Are you sure you want to delete this task?"
            onConfirm={async () => {
              try {
                await deleteCohort()
                trackEvent('deleteCohort', { cohortId: cohort.id })
                message.success('Deleted cohort')
              } catch (error) {
                console.error(error)
                message.error(formatGraphQLError(error))
              }
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </ExtraActions>
      }
    >
      {selectedTab === Tab.OVERVIEW && (
        <>
          <CardWrapper>
            <StudentsContent
              students={cohort.students}
              showSchoolInfo={false}
              showSalary={true}
              title="Students"
            />
          </CardWrapper>

          {cohort.defaultContract ? (
            <ContractCard
              contract={cohort.defaultContract}
              viewLink
              titlePrefix="Default Contract: "
            />
          ) : (
            <div>There is no default contract for this cohort</div>
          )}
        </>
      )}
      {selectedTab === Tab.PROGRESS && (
        <StudentsProgress
          students={cohort.students}
          showSchoolInfo={false}
          showSalary={false}
          title={'Students'}
          cohortId={cohort.id}
        />
      )}
      {selectedTab === Tab.SETTINGS && <CohortSettings cohortId={cohortId} />}
    </PageHeaderWrapper>
  )
}
