import { Avatar, Card, Col, Dropdown, Input, List, Menu, Progress, Row } from 'antd'
import gql from 'graphql-tag'
import produce from 'immer'
import React from 'react'
import { Link } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import styled from 'styled-components'

import { Loading } from '../components/Loading'
import { ButtonModalAdd } from '../components/ButtonModalAdd'
import { ButtonModalLink } from '../components/ButtonModalLink'
import { SchoolFragment, useGetCountsQuery, useGetSchoolsQuery } from '../generated/graphql'
import { CreateSchoolForm } from './CreateSchool'
import { EditSchoolForm } from './EditSchool'
import { ErrorGeneral } from '../components/ErrorPages'
import { useFormatCountry } from '../utils/country'
// import { useAuth0 } from '../../auth/auth0'

const Enrolled = styled.div`
  text-align: center;
`

const { Search } = Input

const Info: React.FC<{
  title: React.ReactNode
  value: React.ReactNode
  bordered?: boolean
}> = ({ title, value, bordered }) => (
  <div
    style={{
      textAlign: 'center',
    }}
  >
    <span
      style={{
        marginBottom: 4,
        color: 'rgba(0,0,0,.45)',
        fontSize: 14,
        lineHeight: '22px',
      }}
    >
      {title}
    </span>
    <p
      style={{
        fontSize: 24,
        margin: 0,
        color: 'rgba(0,0,0,.85)',
        lineHeight: 1.333,
      }}
    >
      {value}
    </p>
    {bordered && (
      <em
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 1,
          height: 56,
          backgroundColor: '#e8e8e8',
        }}
      />
    )}
  </div>
)

// const MoreButton: React.FC<{
//   school: SchoolFragment
// }> = ({ school }) => (
//   <Dropdown
//     overlay={
//       <Menu>
//         <Menu.Item key="delete">Delete</Menu.Item>
//       </Menu>
//     }
//   >
//     <a>
//       More <DownOutlined />
//     </a>
//   </Dropdown>
// )

// function displayNumber(numberStudents) {
//   return numberStudents || 0
// }

const ListContent = ({ school }: { school: SchoolFragment }) => {
  const formatCountry = useFormatCountry()

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Enrolled
        style={{
          padding: '0 20px',
          textAlign: 'center',
        }}
      >
        <div>Enrolled</div>
        <div>{school.studentCount.total}</div>
      </Enrolled>

      <div
        style={{
          // padding: '0 20px',
          width: 90,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {formatCountry(school.country)}
      </div>
      <div
        style={{
          padding: '0 20px',
        }}
      >
        <Progress
          percent={Math.round(
            (school.studentCount.employed /
              (school.studentCount.employed + school.studentCount.unemployed)) *
              100
          )}
          // status={status}
          strokeWidth={6}
          style={{ width: 180 }}
        />
      </div>
    </div>
  )
}

export const GET_SCHOOLS = gql`
  fragment School on School {
    id
    name
    description
    imageUrl
    country
    studentCount {
      total
      unemployed
      employed
    }
  }

  query GetSchools {
    schools {
      ...School
    }
  }
`

export const GET_COUNTS = gql`
  query GetCounts {
    schoolsCount
    studentsCount
    countriesCount
  }
`

export const Schools: React.FC<{}> = () => {
  const { data, loading, error } = useGetSchoolsQuery()
  const { data: counts } = useGetCountsQuery()

  const [table, setTable] = React.useState({
    search: '',
    page: 1,
  })

  const onSearch = (value: string) => {
    setTable(
      produce(table => {
        table.search = value
      })
    )
  }

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  const { schools } = data

  const filteredSchools = table.search
    ? schools.filter(school => {
        return school.name.toLowerCase().includes(table.search.toLowerCase())
      })
    : schools

  return (
    <>
      <PageHeaderWrapper title="Schools">
        <div>
          {/* <Profile /> */}

          <Card bordered={false}>
            <Row>
              <Col sm={8} xs={24}>
                <Info title="Schools" value={counts?.schoolsCount} bordered />
              </Col>
              <Col sm={8} xs={24}>
                <Info title="Students" value={counts?.studentsCount} bordered />
              </Col>
              <Col sm={8} xs={24}>
                <Info title="Countries" value={counts?.countriesCount} />
              </Col>
            </Row>
          </Card>

          <Card
            headStyle={{
              borderBottom: 'none',
            }}
            bordered={false}
            title="Schools"
            style={{ marginTop: 24 }}
            bodyStyle={{ padding: '0 32px 40px 32px' }}
            extra={<Search placeholder="Search" onSearch={onSearch} />}
          >
            <ButtonModalAdd
              title="Add School"
              form={closeModal => <CreateSchoolForm onCreate={closeModal} />}
            />
            <List
              size="large"
              rowKey="id"
              loading={false}
              dataSource={filteredSchools}
              renderItem={school => (
                <List.Item
                  actions={[
                    <ButtonModalLink
                      title="Edit"
                      content={closeModal => (
                        <EditSchoolForm onCreate={closeModal} school={school} />
                      )}
                    />,
                    // <MoreButton key="more" school={school} />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      school.imageUrl ? (
                        <Avatar src={school.imageUrl} shape="square" size="large" />
                      ) : (
                        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                          {school.name.substr(0, 2)}
                        </Avatar>
                      )
                    }
                    title={<Link to={`/school/${school.id}`}>{school.name}</Link>}
                    description={school.description.substr(0, 100)}
                  />
                  <ListContent school={school} />
                </List.Item>
              )}
            />
          </Card>
        </div>
      </PageHeaderWrapper>
    </>
  )
}
