import { gql } from 'apollo-boost'
import { useCountriesQuery, Country } from '../generated/graphql'

gql`
  fragment Country on CountryFull {
    name
    enum
  }

  query Countries {
    countries {
      ...Country
    }
  }
`

export const useFormatCountry = () => {
  const { data } = useCountriesQuery()

  const formatCountry = (country?: Country | null) => {
    if (!country) return ''

    return data?.countries.find(c => c.enum === country)?.name || ''
  }

  return formatCountry
}
