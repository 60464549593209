import React from 'react'
import styled from 'styled-components'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import { Card, List, Button, Form, Checkbox, InputNumber, Select } from 'antd'
import { Field, Formik } from 'formik'
import { gql } from 'apollo-boost'
import { ButtonModalAdd } from '../components/ButtonModalAdd'
import { ButtonModalLink } from '../components/ButtonModalLink'
import {
  useActionGroupQuery,
  useActionGroupsQuery,
  CreateActionGroupInput,
  useCreateActionGroupMutation,
  useSendgridTemplatesQuery,
  useDeleteActionGroupMutation,
  ActionType,
} from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorGeneral } from '../components/ErrorPages'
import { CustomInputComponent } from '../components/FormikCustom'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { openSuccessNotification, openErrorNotification } from '../components/Notification'
import { trackEvent } from '../utils/analytics'

const ACTION_GROUP_FRAGMENT = gql`
  fragment ActionGroup on ActionGroup {
    id
    name
  }
`

const GET_ACTION_GROUPS = gql`
  ${ACTION_GROUP_FRAGMENT}

  query ActionGroups {
    actionGroups {
      ...ActionGroup
    }
  }
`

export const ACTION_FRAGMENT = gql`
  fragment Action on Action {
    id
    type
    actionGroupId
    sendgridTemplateId
    reminderSendgridTemplateId
    enabled
    order
  }
`

gql`
  ${ACTION_GROUP_FRAGMENT}
  ${ACTION_FRAGMENT}

  query ActionGroup($id: ID!) {
    actionGroup(id: $id) {
      ...ActionGroup
      actions {
        ...Action
      }
    }
  }
`

gql`
  ${ACTION_GROUP_FRAGMENT}

  mutation CreateActionGroup($data: CreateActionGroupInput!) {
    createActionGroup(data: $data) {
      ...ActionGroup
    }
  }
`

gql`
  mutation DeleteActionGroup($actionGroupId: ID!) {
    deleteActionGroup(actionGroupId: $actionGroupId)
  }
`

gql`
  query SendgridTemplates {
    sendgridTemplates {
      id
      name
    }
  }
`

interface ActionGroupsProps {}

export const ActionGroups: React.FC<ActionGroupsProps> = props => {
  const { data, loading, error } = useActionGroupsQuery()

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  const { actionGroups } = data

  return (
    <PageHeaderWrapper title="Action Groups">
      <Card
        headStyle={{ borderBottom: 'none' }}
        bordered={false}
        style={{ marginTop: 24 }}
        bodyStyle={{ padding: '32px 32px 40px 32px' }}
      >
        <ButtonModalAdd
          title="Add Action Group"
          form={closeModal => <CreateActionGroupForm onCreate={closeModal} />}
          width={800}
        />
        <List
          size="large"
          rowKey="id"
          loading={false}
          dataSource={actionGroups}
          renderItem={actionGroup => (
            <List.Item
              actions={[
                <ButtonModalLink
                  buttonText="View"
                  title={actionGroup.name}
                  content={closeModal => {
                    return <ViewActionGroup actionGroupId={actionGroup.id} showDelete />
                  }}
                />,
              ]}
            >
              {actionGroup.name}
            </List.Item>
          )}
        />
      </Card>
    </PageHeaderWrapper>
  )
}

interface CreateActionGroupFormProps {
  onCreate: () => void
}

export const CreateActionGroupForm: React.FC<CreateActionGroupFormProps> = props => {
  const { data, loading } = useSendgridTemplatesQuery()
  const [createActionGroup] = useCreateActionGroupMutation({
    refetchQueries: [{ query: GET_ACTION_GROUPS }],
  })

  if (loading) return <Loading />
  if (!data) return null

  const actions = Object.values(ActionType).map((actionType, index) => {
    return {
      type: actionType,
      order: index,
      sendgridTemplateId: undefined,
      reminderSendgridTemplateId: undefined,
      enabled: true,
    }
  })

  return (
    <Formik<CreateActionGroupInput>
      initialValues={{ name: '', actions }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)
        createActionGroup({ variables: { data: values } })
        trackEvent('createActionGroup', values)
        setSubmitting(false)
        props.onCreate()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, setFieldValue, values }) => {
        return (
          <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
            <Field name="name" label="Name" component={CustomInputComponent} />

            {actions
              // .sort((a, b) => a.order - b.order)
              .map((action, index) => {
                return (
                  <ActionsRow key={action.type}>
                    <ActionsRowActionType>
                      <Checkbox
                        checked={!!values.actions[index]?.enabled}
                        onChange={event => {
                          setFieldValue(`actions.${index}.enabled`, event.target.checked)
                        }}
                      >
                        {action.type}
                      </Checkbox>
                    </ActionsRowActionType>
                    <ActionsRowOrder>
                      <InputNumber
                        defaultValue={index + 1}
                        min={1}
                        max={actions.length}
                        step={1}
                        onChange={value => {
                          setFieldValue(`actions.${index}.order`, value)
                        }}
                      />
                    </ActionsRowOrder>
                    <ActionsRowOrder>
                      <Select
                        style={{ width: 200 }}
                        placeholder="SendGrid Template"
                        onChange={value => {
                          setFieldValue(`actions.${index}.sendgridTemplateId`, value)
                        }}
                      >
                        {data.sendgridTemplates.map(option => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </ActionsRowOrder>
                    <Select
                      style={{ width: 200 }}
                      placeholder="Reminder SendGrid Template"
                      onChange={value => {
                        setFieldValue(`actions.${index}.reminderSendgridTemplateId`, value)
                      }}
                    >
                      {data.sendgridTemplates.map(option => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </ActionsRow>
                )
              })}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                Create
              </Button>
            </Form.Item>
          </Form>
        )
      }}
    </Formik>
  )
}

interface ViewActionGroupProps {
  actionGroupId: string
  showDelete?: boolean
}

export const ViewActionGroup: React.FC<ViewActionGroupProps> = props => {
  const { data, loading } = useActionGroupQuery({ variables: { id: props.actionGroupId } })
  const {
    data: dataSendgridTemplates,
    loading: loadingSendgridTemplates,
  } = useSendgridTemplatesQuery()
  const [deleteActionGroup] = useDeleteActionGroupMutation()

  if (loading || loadingSendgridTemplates) return <Loading />
  if (!data) return null

  const { actionGroup } = data

  return (
    <div>
      {actionGroup.actions
        .sort((a, b) => a.order - b.order)
        .map(action => {
          const sendgridTemplate = dataSendgridTemplates?.sendgridTemplates.find(
            sg => sg.id === action.sendgridTemplateId
          )
          const reminderSendgridTemplate = dataSendgridTemplates?.sendgridTemplates.find(
            sg => sg.id === action.reminderSendgridTemplateId
          )

          return (
            <ActionsRow key={action.id}>
              <ActionsRowActionType>
                <Checkbox checked={action.enabled}>{action.type}</Checkbox>
              </ActionsRowActionType>

              <div style={{ width: 400, display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <div>{sendgridTemplate?.name || '-'}</div>
                <div>{reminderSendgridTemplate?.name || '-'}</div>
              </div>
            </ActionsRow>
          )
        })}

      {props.showDelete && (
        <div style={{ marginTop: 20 }}>
          <Button
            type="primary"
            danger
            onClick={async () => {
              try {
                const { actionGroupId } = props
                await deleteActionGroup({ variables: { actionGroupId } })
                trackEvent('deleteActionGroup', { actionGroupId })
                openSuccessNotification({ description: 'Deleted' })
              } catch (error) {
                console.error(error)
                openErrorNotification({
                  description: 'Unable to delete group. It may be attached to existing cohorts.',
                })
              }
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  )
}

const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const ActionsRowActionType = styled.div`
  flex: 1;
`

const ActionsRowOrder = styled.div`
  margin-right: 5px;
`
