import React from 'react'
import { List, Typography } from 'antd'
import styled from 'styled-components'
import { green } from '@ant-design/colors'

const Wrapper = styled.div`
  margin-top: 15px;
  background-color: white;
`

const ListItemWrapper = styled.div`
  display: flex;
`

const Message = styled.div`
  flex: 1;
  margin-left: 10px;
`

interface BulkResultListProps<T = any> {
  bulkUploadResult: T[]
  renderItem: (item: T, index: number) => React.ReactNode
}

export const BulkResultList: React.FC<BulkResultListProps> = props => {
  return (
    <Wrapper>
      <List
        header={<div>Result</div>}
        bordered
        dataSource={props.bulkUploadResult}
        renderItem={props.renderItem}
      />
    </Wrapper>
  )
}

export const BulkResultListSuccess: React.FC<{ message?: React.ReactNode | string }> = props => {
  return (
    <ListItemWrapper>
      <Typography.Text style={{ color: green.primary }}>Success</Typography.Text>
      <Message>{props.message}</Message>
    </ListItemWrapper>
  )
}

export const BulkResultListError: React.FC<{ status?: string; message?: string }> = props => {
  const message = typeof props.message === 'string' ? props.message : JSON.stringify(props.message)

  return (
    <ListItemWrapper>
      <Typography.Text type="danger">{props.status}</Typography.Text>
      <Message>{message}</Message>
    </ListItemWrapper>
  )
}
