import React from 'react'
import { Route } from 'react-router-dom'

interface PrivateRouteProps {
  path: string
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, path }) => {
  return <Route path={path} render={() => children} />
}
