import { Field } from 'formik'
import React from 'react'
import { useCountriesQuery } from '../generated/graphql'
import { CustomSelectComponent } from './FormikCustom'

interface CountrySelectProps {}

/**
 * Expected to be used in a Formik form
 */
export const CountrySelectField: React.FC<CountrySelectProps> = props => {
  const { data, loading } = useCountriesQuery()
  const countries = data?.countries || []

  if (loading) return null

  return (
    <Field
      name="country"
      label="Country"
      options={countries.map(country => ({
        value: country.enum,
        label: country.name,
      }))}
      component={CustomSelectComponent}
    />
  )
}
