import { Button, Form } from 'antd'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import { useEditSchoolMutation, EditSchoolInput } from '../generated/graphql'
import { CustomInputComponent, CustomTextAreaComponent } from '../components/FormikCustom'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { CountrySelectField } from '../components/CountrySelectField'
import { GET_SCHOOLS } from './Schools'
import { trackEvent } from '../utils/analytics'

export const EDIT_SCHOOL = gql`
  fragment EditSchool on School {
    id
    name
    description
    imageUrl
    country
  }

  mutation EditSchool($data: EditSchoolInput!) {
    editSchool(data: $data) {
      ...School
    }
  }
`

interface EditSchoolFormProps {
  onCreate?: () => void
  school: EditSchoolInput
}

export const EditSchoolForm: React.FC<EditSchoolFormProps> = props => {
  const [editSchool] = useEditSchoolMutation({
    refetchQueries: [{ query: GET_SCHOOLS }],
  })

  return (
    <Formik<EditSchoolInput>
      initialValues={{
        id: props.school.id,
        name: props.school.name,
        description: props.school.description,
        imageUrl: props.school.imageUrl,
        country: props.school.country,
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)
        editSchool({ variables: { data: values } })
        trackEvent('editSchool', values)
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="name" label="Name" component={CustomInputComponent} />
          <Field name="description" label="Description" component={CustomTextAreaComponent} />
          <Field name="imageUrl" label="Image URL" type="url" component={CustomInputComponent} />
          <CountrySelectField />
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
