import { Button, Form } from 'antd'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomCheckboxComponent,
} from '../components/FormikCustom'
import {
  Country,
  CreateStudentInput,
  useCreateStudentMutation,
  useGetCohortsQuery,
} from '../generated/graphql'
import { CountrySelectField } from '../components/CountrySelectField'
import { GET_STUDENTS } from './Students'
import { useAuth0 } from '../auth/auth0'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { trackEvent } from '../utils/analytics'
import { openSuccessNotification, openErrorNotification } from '../components/Notification'

gql`
  fragment CreateStudent on Student {
    id
    firstName
    lastName
    email
    phone
  }

  mutation CreateStudent($data: CreateStudentInput!) {
    createStudent(data: $data) {
      ...Student
    }
  }
`

gql`
  fragment Cohort on Cohort {
    id
    name
    program {
      id
      name
      school {
        id
        name
        contracts {
          id
          name
        }
      }
    }
  }

  query GetCohorts {
    cohorts {
      ...Cohort
    }
  }
`

interface CreateStudentFormProps {
  onCreate?: () => void
  cohortId?: string
}

export const CreateStudentForm: React.FC<CreateStudentFormProps> = props => {
  const { user } = useAuth0()
  const [createStudent] = useCreateStudentMutation({
    refetchQueries: [
      {
        query: GET_STUDENTS,
        variables: { schoolId: user?.adminUser ? undefined : user?.schoolId },
      },
    ],
  })

  const { data } = useGetCohortsQuery()
  const cohorts = data?.cohorts || []
  const [selectedCohortId, setSelectedCohortId] = React.useState<string | undefined>(
    props.cohortId || undefined
  )
  const selectedCohort = cohorts.find(({ id }) => selectedCohortId === id) || cohorts?.[0]

  const contracts = selectedCohort?.program.school.contracts

  const DEFAULT_CONTRACT_FOR_COHORT = 'DEFAULT_CONTRACT_FOR_COHORT'

  return (
    <Formik<CreateStudentInput>
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        country: Country.Israel,
        zip: '',
        passportIdNumber: '',
        cohortId: props.cohortId || undefined,
        contractId: undefined,
        plaidPaymentEnabled: true,
        stripePaymentEnabled: true,
        manualPaymentEnabled: true,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)
        if (values.contractId === DEFAULT_CONTRACT_FOR_COHORT) values.contractId = undefined

        try {
          const createdStudent = await createStudent({ variables: { data: values } })
          trackEvent('createStudent', values)
          openSuccessNotification({
            description: (
              <>
                Successfully created{' '}
                <a href={`/student/${createdStudent.data?.createStudent.id}`}>
                  {values.firstName} {values.lastName}
                </a>
              </>
            ),
          })
          props.onCreate?.()
        } catch (error) {
          console.error(error)
          openErrorNotification({
            description: 'Unable to delete group. It may be attached to existing cohorts.',
          })
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="firstName" label="First Name" component={CustomInputComponent} />
          <Field name="lastName" label="Last Name" component={CustomInputComponent} />
          <Field name="phone" label="Phone" component={CustomInputComponent} />
          <Field name="email" label="Email" type="email" component={CustomInputComponent} />
          <Field name="street" label="Street" component={CustomInputComponent} />
          <Field name="city" label="City" component={CustomInputComponent} />
          <CountrySelectField />
          <Field name="zip" label="ZIP" component={CustomInputComponent} />
          <Field name="passportIdNumber" label="Passport/ID" component={CustomInputComponent} />
          {cohorts && (
            <Field
              name="cohortId"
              label="Cohort"
              options={cohorts.map(cohort => ({
                value: cohort.id,
                label: `${cohort.name} - ${cohort.program.name} - ${cohort.program.school.name}`,
              }))}
              component={CustomSelectComponent}
              onChange={(cohortId: string) => {
                setSelectedCohortId(cohortId)
              }}
            />
          )}
          {contracts && (
            <Field
              name="contractId"
              label="Contract"
              options={[
                { value: DEFAULT_CONTRACT_FOR_COHORT, label: 'Default for cohort' },
                ...contracts.map(contract => ({
                  value: contract.id,
                  label: `${contract.name}`,
                })),
              ]}
              component={CustomSelectComponent}
            />
          )}
          <Field
            name="plaidPaymentEnabled"
            label="Plaid Payment Enabled"
            component={CustomCheckboxComponent}
          />
          <Field
            name="stripePaymentEnabled"
            label="Stripe Payment Enabled"
            component={CustomCheckboxComponent}
          />
          <Field
            name="manualPaymentEnabled"
            label="Manual Payment Enabled"
            component={CustomCheckboxComponent}
          />

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Add
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
