import { Button, Modal } from 'antd'
import { ButtonType } from 'antd/lib/button'
import React from 'react'

interface ButtonModalLinkProps {
  title: string
  buttonText?: string
  buttonType?: ButtonType
  content: (onCreate: () => void) => React.ReactNode
}

export const ButtonModalLink: React.FC<ButtonModalLinkProps> = props => {
  const [state, setState] = React.useState({
    modalVisible: false,
  })
  const closeModal = () => setState({ modalVisible: false })

  return (
    <>
      <Button
        type={props.buttonType || 'link'}
        // style={{ width: '100%' }}
        onClick={() => {
          setState({ modalVisible: true })
        }}
      >
        {props.buttonText || props.title}
      </Button>
      <Modal
        title={props.title}
        width={640}
        bodyStyle={{ padding: '28px 20px 10px' }}
        destroyOnClose
        visible={state.modalVisible}
        onCancel={closeModal}
        footer={null}
      >
        {props.content(closeModal)}
      </Modal>
    </>
  )
}
