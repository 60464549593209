import React from 'react'
import gql from 'graphql-tag'
import { Field, Formik } from 'formik'
import { Button, Form } from 'antd'
import { useCreateSchoolMutation, CreateSchoolInput, Country } from '../generated/graphql'
import { CustomInputComponent, CustomTextAreaComponent } from '../components/FormikCustom'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { CountrySelectField } from '../components/CountrySelectField'
import { GET_SCHOOLS } from './Schools'
import { trackEvent } from '../utils/analytics'

export const CREATE_SCHOOL = gql`
  fragment CreateSchool on School {
    id
    name
    description
    imageUrl
  }

  mutation CreateSchool($data: CreateSchoolInput!) {
    createSchool(data: $data) {
      ...School
    }
  }
`

interface CreateSchoolFormProps {
  onCreate?: () => void
}

export const CreateSchoolForm: React.FC<CreateSchoolFormProps> = props => {
  const [createSchool] = useCreateSchoolMutation({
    refetchQueries: [{ query: GET_SCHOOLS }],
  })

  return (
    <Formik<CreateSchoolInput>
      initialValues={{ name: '', description: '', imageUrl: '', country: Country.Israel }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)
        createSchool({ variables: { data: values } })
        trackEvent('createSchool', values)
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="name" label="Name" component={CustomInputComponent} />
          <Field name="description" label="Description" component={CustomTextAreaComponent} />
          <Field name="imageUrl" label="Image URL" type="url" component={CustomInputComponent} />
          <CountrySelectField />
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Add
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
