import React from 'react'
import gql from 'graphql-tag'
import { Field, Formik } from 'formik'
import { Button, Form } from 'antd'
import {
  useCreateContractMutation,
  useEditContractMutation,
  CreateContractInput,
  Currency,
  ContractType,
  ThresholdType,
  useGetSchoolsQuery,
  ContractFragment,
  SalaryType,
} from '../generated/graphql'
import {
  CustomInputComponent,
  CustomTextAreaComponent,
  CustomSelectComponent,
} from '../components/FormikCustom'
import { CurrencySelectField } from '../components/CurrencySelectField'
import { GET_CONTRACTS } from './Contracts'
import { formatContractType, formatThresholdType } from './utils'
import { amountToServerAmount, serverAmountToAmount } from '../utils/currency'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { useAuth0 } from '../auth/auth0'
import { CONTRACT_FRAGMENT } from '../students/fragments'
import { trackEvent } from '../utils/analytics'

gql`
  ${CONTRACT_FRAGMENT}

  mutation CreateContract($data: CreateContractInput!) {
    createContract(data: $data) {
      ...Contract
    }
  }
`

gql`
  ${CONTRACT_FRAGMENT}

  mutation EditContract($data: EditContractInput!) {
    editContract(data: $data) {
      ...Contract
    }
  }
`

interface ContractFormProps {
  contract?: ContractFragment
  onCreate?: () => void
}

export const ContractForm: React.FC<ContractFormProps> = props => {
  const { user } = useAuth0()
  const schoolId = user?.schoolId || ''
  const { contract } = props

  const [createContract] = useCreateContractMutation({
    refetchQueries: [{ query: GET_CONTRACTS }],
  })
  const [editContract] = useEditContractMutation({
    refetchQueries: [{ query: GET_CONTRACTS }],
  })

  return (
    <Formik<CreateContractInput>
      initialValues={{
        name: contract?.name || '',
        description: contract?.description || '',
        type: contract?.type || ContractType.Isa,
        ratePercentage: contract?.ratePercentage || 0,
        periodPostEmploymentMonths: contract?.periodPostEmploymentMonths || 24,
        periodPostGraduationMonths: contract?.periodPostGraduationMonths || 24,
        cap: serverAmountToAmount(contract?.cap || 0),
        threshold: serverAmountToAmount(contract?.threshold || 0),
        thresholdType: contract?.thresholdType || ThresholdType.Monthly,
        upfrontPayment: serverAmountToAmount(contract?.upfrontPayment || 0),
        currency: contract?.currency || Currency.Ils,
        dropoutInterest: contract?.dropoutInterest ?? undefined,
        dropoutPrincipal:
          typeof contract?.dropoutPrincipal === 'number'
            ? serverAmountToAmount(contract?.dropoutPrincipal)
            : undefined,
        fileUrl: contract?.fileUrl || undefined,
        schoolId: contract?.schoolId || schoolId,
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)
        const cap = amountToServerAmount(values.cap || 0)
        const threshold = amountToServerAmount(values.threshold || 0)
        const upfrontPayment = amountToServerAmount(values.upfrontPayment || 0)
        const dropoutPrincipal =
          typeof values.dropoutPrincipal === 'number'
            ? amountToServerAmount(values.dropoutPrincipal || 0)
            : undefined

        if (contract?.id) {
          const data = {
            ...values,
            cap,
            threshold,
            upfrontPayment,
            dropoutPrincipal,
            id: contract.id,
          }
          editContract({ variables: { data } })
          trackEvent('editCohort', data)
        } else {
          const data = {
            ...values,
            cap,
            threshold,
            upfrontPayment,
          }
          createContract({ variables: { data } })
          trackEvent('createContract', data)
        }
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="name" label="Name" component={CustomInputComponent} />
          <Field name="description" label="Description" component={CustomTextAreaComponent} />
          <Field
            name="type"
            label="Type"
            options={Object.values(ContractType).map(contractType => ({
              value: contractType,
              label: formatContractType(contractType),
            }))}
            component={CustomSelectComponent}
          />
          <Field
            name="periodPostEmploymentMonths"
            type="number"
            label="Payment Period (months)"
            min={0}
            component={CustomInputComponent}
          />
          <Field
            name="periodPostGraduationMonths"
            type="number"
            label="Contract Period (months)"
            min={0}
            component={CustomInputComponent}
          />
          <Field
            name="ratePercentage"
            type="number"
            label="Rate (%)"
            min={0}
            max={100}
            component={CustomInputComponent}
          />
          <CurrencySelectField />
          <Field name="cap" type="number" label="Cap" min={0} component={CustomInputComponent} />
          <Field
            name="threshold"
            type="number"
            label="Threshold"
            min={0}
            component={CustomInputComponent}
          />
          <Field
            name="thresholdType"
            label="Threshold Type"
            options={Object.values(ThresholdType).map(thresholdType => ({
              value: thresholdType,
              label: formatThresholdType(thresholdType),
            }))}
            component={CustomSelectComponent}
          />
          <Field
            name="upfrontPayment"
            type="number"
            label="Upfront Payment"
            min={0}
            component={CustomInputComponent}
          />
          <Field
            name="dropoutInterest"
            type="number"
            label="Dropout Interest"
            min={0}
            step={0.01}
            component={CustomInputComponent}
          />
          <Field
            name="dropoutPrincipal"
            type="number"
            label="Dropout Principal"
            min={0}
            component={CustomInputComponent}
          />
          <Field
            name="fileUrl"
            type="url"
            label="File URL (e.g. Google Drive)"
            component={CustomInputComponent}
          />
          {user?.adminUser ? <SchoolSelectField /> : null}

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              {contract ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}

export const SchoolSelectField: React.FC<{}> = props => {
  const { data, loading } = useGetSchoolsQuery()

  if (loading) return null
  if (!data) return null

  const options = data.schools.map(school => ({
    value: school.id,
    label: school.name,
  }))

  return (
    <Field name="schoolId" label="School" options={options} component={CustomSelectComponent} />
  )
}
