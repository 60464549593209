import { gql } from 'apollo-boost'
import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import { Card, Popconfirm, message, Tag } from 'antd'
import { Loading } from '../components/Loading'
import { useGetContractQuery, useDeleteContractMutation } from '../generated/graphql'
import { StudentsContent, STUDENT_FRAGMENT } from '../students/Students'
import { ContractCard } from '../students/StudentAdmin/Overview'
import { CONTRACT_FRAGMENT } from '../students/fragments'
import { CardWrapper } from '../components/Card'
import { ButtonModalLink } from '../components/ButtonModalLink'
import { ContractForm } from './ContractForm'
import { ExtraActions } from '../components/ExtraActions'
import { formatGraphQLError } from '../utils/error'
import { trackEvent } from '../utils/analytics'

export const GET_CONTRACT = gql`
  ${STUDENT_FRAGMENT}
  ${CONTRACT_FRAGMENT}

  fragment GetContractContract on Contract {
    ...Contract
    students {
      ...Student
    }
    cohorts {
      id
      name
    }
  }

  query GetContract($contractId: ID!) {
    contract(id: $contractId) {
      ...GetContractContract
    }
  }
`

gql`
  mutation DeleteContract($contractId: ID!) {
    deleteContract(contractId: $contractId)
  }
`

export const Contract: React.FC<{}> = props => {
  const { contractId } = useParams<{ contractId: string }>()
  const { data, loading } = useGetContractQuery({ variables: { contractId } })
  const [deleteContract] = useDeleteContractMutation({
    variables: { contractId },
  })

  if (loading) return <Loading />
  if (!data?.contract) return <p>Contract not found.</p>

  const { contract } = data

  return (
    <PageHeaderWrapper
      title={
        <>
          {contract.name} {contract.deleted && <Tag color="red">DELETED</Tag>}
        </>
      }
      content={contract.description}
      extra={
        <ExtraActions>
          <ButtonModalLink
            title="Edit"
            content={closeModal => <ContractForm onCreate={closeModal} contract={contract} />}
          />

          <Popconfirm
            title="Are you sure you want to delete this contract?"
            onConfirm={async () => {
              try {
                await deleteContract()
                trackEvent('deleteContract', { contractId })
                message.success('Deleted contract')
              } catch (error) {
                console.error(error)
                message.error(formatGraphQLError(error))
              }
            }}
            okText="Delete"
            cancelText="Cancel"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        </ExtraActions>
      }
    >
      <ContractCard contract={contract} viewLink={false} />

      <CardWrapper>
        <StudentsContent
          students={contract.students}
          showSchoolInfo
          showSalary={true}
          title="Students"
        />
      </CardWrapper>

      <Card title="Cohorts">
        <ul>
          {contract.cohorts.map(cohort => {
            return (
              <li key={cohort.id}>
                <Link to={`/cohort/${cohort.id}`}>{cohort.name}</Link>
              </li>
            )
          })}
        </ul>
      </Card>
    </PageHeaderWrapper>
  )
}
