import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'

export interface User {
  roles: string[]
  schoolId?: string
  adminUser: boolean
  schoolUser: boolean
  studentUser: boolean
  nickname: string
  name: string
  picture: string
  updated_at: string
  email: string
  email_verified: boolean
  sub: string
}

interface Context {
  getTokenSilently: () => Promise<any> | undefined
  isAuthenticated: boolean
  loading: boolean
  user?: User
  loginWithRedirect: (options: {}) => void
  logout: () => void
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const Auth0Context = React.createContext<Context | undefined>(undefined)

export const useAuth0 = () => {
  const context = useContext(Auth0Context)

  if (!context) throw new Error('Parent must be wrapped in Provider')

  return context
}

// Based off of:
// https://auth0.com/docs/quickstart/spa/react/01-login#create-a-sample-application
export const Auth0Provider: React.FC<Auth0ClientOptions & {
  onRedirectCallback: (appState: any) => void
}> = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<User>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  // const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()

        const roles = user['https://api.withspark.org/roles']
        const schoolId = user['https://api.withspark.org/schoolId']

        setUser({
          ...user,
          schoolId,
          adminUser: roles?.includes('admin'),
          schoolUser: roles?.includes('school'),
          studentUser: roles?.includes('student'),
        })
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const loginWithPopup = async (params = {}) => {
  //   setPopupOpen(true)
  //   try {
  //     await auth0Client.loginWithPopup(params)
  //   } catch (error) {
  //     console.error(error)
  //   } finally {
  //     setPopupOpen(false)
  //   }
  //   const user = await auth0Client.getUser()
  //   setUser(user)
  //   setIsAuthenticated(true)
  // }

  // const handleRedirectCallback = async () => {
  //   setLoading(true)
  //   await auth0Client.handleRedirectCallback()
  //   const user = await auth0Client.getUser()
  //   setLoading(false)
  //   setIsAuthenticated(true)
  //   setUser(user)
  // }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        // popupOpen,
        // loginWithPopup,
        // handleRedirectCallback,
        // getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        // getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client?.logout({ ...p, returnTo: window.location.origin }),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

export const useAuth0Token = () => {
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState('')

  React.useEffect(() => {
    const getToken = async () => setToken(await getTokenSilently())
    getToken()
  }, [getTokenSilently])

  return token
}
