import React from 'react'
import { Card } from 'antd'
import { gql } from 'apollo-boost'
import { ViewActionGroup, ACTION_FRAGMENT } from '../actions/ActionGroups'
import { SetActionGroupForm } from '../components/SetActionGroupForm'
import { Loading } from '../components/Loading'
import { ErrorGeneral } from '../components/ErrorPages'
import { useCohortActionGroupQuery, useEditCohortActionGroupMutation } from '../generated/graphql'
import { trackEvent } from '../utils/analytics'

gql`
  ${ACTION_FRAGMENT}

  query CohortActionGroup($cohortId: ID!) {
    cohort(id: $cohortId) {
      id
      actionGroupId
      actionGroup {
        name
        actions {
          ...Action
        }
      }
    }
  }
`

gql`
  mutation EditCohortActionGroup($actionGroupId: String!, $cohortId: String!) {
    editCohortActionGroup(actionGroupId: $actionGroupId, cohortId: $cohortId) {
      id
      actionGroupId
    }
  }
`

interface CohortSettingsProps {
  cohortId: string
}

export const CohortSettings: React.FC<CohortSettingsProps> = ({ cohortId }) => {
  const { data, loading, error } = useCohortActionGroupQuery({ variables: { cohortId } })
  const [editCohortActionGroup] = useEditCohortActionGroupMutation()

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  return (
    <Card
      headStyle={{ borderBottom: 'none' }}
      bordered={false}
      title={`Default Actions Group`}
      bodyStyle={{ padding: '0 32px 40px 32px' }}
    >
      <SetActionGroupForm
        actionGroupId={data.cohort.actionGroupId || undefined}
        editActionGroup={async actionGroupId => {
          const variables = { cohortId: data.cohort.id, actionGroupId }
          editCohortActionGroup({ variables })
          trackEvent('editCohortActionGroup', variables)
        }}
      />

      <div style={{ paddingTop: 20 }}>
        {data.cohort.actionGroupId && <ViewActionGroup actionGroupId={data.cohort.actionGroupId} />}
      </div>
    </Card>
  )
}
