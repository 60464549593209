import { gql } from 'apollo-boost'
import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Card, List } from 'antd'
import { PageHeaderWrapper } from '@ant-design/pro-layout'

import { ButtonModalAdd } from '../components/ButtonModalAdd'
import { Loading } from '../components/Loading'
import { useGetProgramQuery } from '../generated/graphql'
import { CreateCohortForm } from './CreateCohort'
import { ButtonModalLink } from '../components/ButtonModalLink'
import { EditProgramForm } from './EditProgram'
import { formatDate } from '../utils/date'
import { useTab } from '../utils/location'
import { ProgramSettings } from './ProgramSettings'

export const GET_PROGRAM = gql`
  fragment GetProgram on Program {
    id
    name
    description
    schoolId
    cohorts {
      id
      name
      startDate
      endDate
    }
  }

  query GetProgram($programId: ID!) {
    program(id: $programId) {
      ...GetProgram
      school {
        id
        name
      }
    }
  }
`

const Tab = {
  OVERVIEW: 'overview',
  SETTINGS: 'settings',
}

export const Program: React.FC<{}> = props => {
  const history = useHistory()
  const { programId } = useParams<{ programId: string }>()
  const { data, loading } = useGetProgramQuery({ variables: { programId } })
  const [selectedTab, setSelectedTab] = useTab<string>(Tab.OVERVIEW)

  if (loading) return <Loading />
  if (!data?.program) return <p>Program not found.</p>

  const { program } = data
  const editButton = (
    <ButtonModalLink
      title="Edit"
      content={closeModal => <EditProgramForm onCreate={closeModal} program={program} />}
    />
  )

  return (
    <PageHeaderWrapper
      title={program.name}
      content={program.description}
      extra={editButton}
      breadcrumb={{
        itemRender: ({ path, breadcrumbName }) => <Link to={path}>{breadcrumbName}</Link>,
        routes: [
          {
            breadcrumbName: program.school.name,
            path: `/school/${program.school.id}`,
          },
          {
            breadcrumbName: program.name,
            path: '#',
          },
        ],
      }}
      tabList={[
        { key: Tab.OVERVIEW, tab: 'Overview' },
        { key: Tab.SETTINGS, tab: 'Settings' },
      ]}
      tabActiveKey={selectedTab}
      onTabChange={tab => {
        setSelectedTab(tab)
        history.replace({ pathname: history.location.pathname, search: `?tab=${tab}` })
      }}
    >
      {selectedTab === Tab.OVERVIEW && (
        <Card
          headStyle={{
            borderBottom: 'none',
          }}
          bordered={false}
          title="Cohorts"
          style={{ marginTop: 24 }}
          bodyStyle={{ padding: '0 32px 40px 32px' }}
        >
          <ButtonModalAdd
            title="Add Cohort"
            form={closeModal => (
              <CreateCohortForm
                schoolId={program.schoolId}
                programId={programId}
                onCreate={closeModal}
              />
            )}
          />
          <List
            size="large"
            rowKey="id"
            loading={false}
            dataSource={program.cohorts.sort(
              (a, b) => +new Date(a.startDate) - +new Date(b.startDate)
            )}
            renderItem={cohort => (
              <List.Item actions={[<Link to={`/cohort/${cohort.id}`}>View</Link>]}>
                <List.Item.Meta
                  title={<Link to={`/cohort/${cohort.id}`}>{cohort.name}</Link>}
                  description={`${formatDate(new Date(cohort.startDate))} - ${formatDate(
                    new Date(cohort.endDate)
                  )}`}
                />
              </List.Item>
            )}
          />
        </Card>
      )}
      {selectedTab === Tab.SETTINGS && <ProgramSettings programId={programId} />}
    </PageHeaderWrapper>
  )
}
