import { sentenceCase } from 'change-case'
import { ContractType, ThresholdType, SalaryType } from '../generated/graphql'

// default to sentence case if not in map
const contractTypeMap = {
  [ContractType.Isa]: 'ISA',
  [ContractType.PrinciplePlusPercent]: 'Principle Plus Percent',
}

export const formatContractType = (contractType: ContractType) => {
  return contractTypeMap[contractType] || sentenceCase(contractType)
}

export const formatThresholdType = (thresholdType: ThresholdType) => {
  return sentenceCase(thresholdType)
}
