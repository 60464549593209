import React from 'react'
import amplitude from 'amplitude-js'
import { AMPLITUDE_API_KEY } from './config'
import { useHistory } from 'react-router-dom'
import { User } from '../auth/auth0'

if (AMPLITUDE_API_KEY) amplitude.getInstance().init(AMPLITUDE_API_KEY)

export function identifyUser(user: User) {
  if (!AMPLITUDE_API_KEY) return

  const identify = new amplitude.Identify()
  identify.set(
    'userType',
    user.adminUser ? 'Admin' : user.schoolUser ? 'School Manager' : 'Student'
  )
  identify.set('email', user.email)
  identify.set('name', user.name)
  identify.set('id', user.sub)
  identify.set('nickname', user.nickname)
  if (user.schoolId) identify.set('schoolId', user.schoolId)
  amplitude.identify(identify)
}

export function trackEvent(eventType: string, eventProperties?: any) {
  if (!AMPLITUDE_API_KEY) return

  console.log(eventType, eventProperties)
  amplitude.getInstance().logEvent(eventType, eventProperties)
}

export const useTracking = () => {
  const { listen } = useHistory()

  React.useEffect(() => {
    if (!AMPLITUDE_API_KEY) {
      console.log('Not tracking. Amplitude key missing')
      return
    }

    const unlisten = listen(location => {
      const search = new URLSearchParams(location.search)
      const tab = search.get('tab')
      const options = location
      if (tab) (options as any).tab = tab
      trackEvent('View page', options)
    })

    return unlisten
  }, [listen])
}
