import { Button, Form } from 'antd'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'

import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { CustomInputComponent, CustomTextAreaComponent } from '../components/FormikCustom'
import { CreateProgramInput, useCreateProgramMutation } from '../generated/graphql'
import { GET_SCHOOL } from './School'
import { trackEvent } from '../utils/analytics'

export const CREATE_PROGRAM = gql`
  fragment CreateProgram on Program {
    id
    name
    description
  }

  mutation CreateProgram($data: CreateProgramInput!) {
    createProgram(data: $data) {
      ...CreateProgram
    }
  }
`

interface CreateProgramFormProps {
  schoolId: string
  onCreate?: () => void
}

export const CreateProgramForm: React.FC<CreateProgramFormProps> = props => {
  const { schoolId } = props
  const [createProgram] = useCreateProgramMutation({
    refetchQueries: [{ query: GET_SCHOOL, variables: { schoolId } }],
  })

  return (
    <Formik<CreateProgramInput>
      initialValues={{ name: '', description: '', schoolId }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)
        createProgram({ variables: { data: values } })
        trackEvent('createProgram', values)
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="name" label="Name" component={CustomInputComponent} />
          <Field name="description" label="Description" component={CustomTextAreaComponent} />
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Add
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
