import React from 'react'
import { useCohortActionGroupQuery } from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorGeneral } from '../components/ErrorPages'
import { StudentsContentProps, StudentsContent, getColumns } from './Students'

export const StudentsProgress: React.FC<StudentsContentProps & { cohortId: string }> = props => {
  const { data, loading, error } = useCohortActionGroupQuery({
    variables: { cohortId: props.cohortId },
  })

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  const actions = data.cohort.actionGroup?.actions || []

  return (
    <StudentsContent
      {...props}
      columns={getColumns(
        { schoolInfo: props.showSchoolInfo, salary: false, stripe: false, contract: false },
        undefined,
        actions
      )}
    />
  )
}
