import { Table, Card, List, Button, Dropdown, Menu, Tag, Typography } from 'antd'
import { green, red } from '@ant-design/colors'
import { DownOutlined } from '@ant-design/icons'
import { ColumnProps } from 'antd/lib/table'
import { SortOrder } from 'antd/lib/table/interface'
import Search from 'antd/lib/input/Search'
import { MenuProps } from 'antd/lib/menu'
import { gql } from 'apollo-boost'
import React from 'react'
import { Link } from 'react-router-dom'
import produce from 'immer'
import { uniqBy } from 'lodash'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import styled from 'styled-components'
import { sentenceCase } from 'change-case'

import { ButtonModalAdd } from '../components/ButtonModalAdd'
import { Loading } from '../components/Loading'
import {
  StudentFragment,
  useGetStudentsQuery,
  useSendEmailBulkMutation,
  useUpdateStageBulkMutation,
  Cohort,
  Student,
  Stage,
  ActionType,
  CandidateStage,
  StudentStage,
  GraduateStage,
  useActionTypeDetailsQuery,
  useCohortsQuery,
  ActionFragment,
  ActionProgress,
  ActionProgressStatus,
} from '../generated/graphql'
import { CreateStudentForm } from './CreateStudent'
import { FileUpload } from '../components/FileUpload'
import { SERVER_URL } from '../utils/config'
import { useAuth0 } from '../auth/auth0'
import { ErrorGeneral } from '../components/ErrorPages'
import {
  BulkResultList,
  BulkResultListSuccess,
  BulkResultListError,
} from '../components/BulkResultList'
import { formatStage, formatSubstage, stageColors, substageColors } from './utils'
import { StripeCell } from './StudentAdmin/Overview'
import { openSuccessNotification, openErrorNotification } from '../components/Notification'
import { EMPLOYMENT_FRAGMENT } from './fragments'
import { formatAmount } from '../utils/currency'
import { formatActionProgressStatus } from '../utils/action'

export const STUDENT_FRAGMENT = gql`
  ${EMPLOYMENT_FRAGMENT}

  fragment Student on Student {
    id
    name
    createdAt
    email
    stage
    substage
    stripeCustomerId
    stripePaymentMethodId
    stripePaymentEnabled
    currentEmployment {
      ...Employment
    }
    contract {
      id
      name
      threshold
      currency
    }
    cohorts {
      id
      name
      program {
        id
        name
        school {
          id
          name
        }
      }
    }
  }
`

export const GET_STUDENTS = gql`
  ${STUDENT_FRAGMENT}

  query GetStudents($schoolId: ID, $stage: Stage) {
    students(schoolId: $schoolId, stage: $stage) {
      ...Student
    }
  }
`

gql`
  query Cohorts {
    cohorts {
      id
      name
      program {
        id
        name
        school {
          id
          name
        }
      }
    }
  }
`

gql`
  mutation SendEmailBulk($data: [SendBulkEmailInput!]!) {
    sendEmailBulk(data: $data)
  }
`

gql`
  mutation UpdateStageBulk($studentIds: [ID!]!, $stage: Stage!) {
    updateStageBulk(studentIds: $studentIds, stage: $stage) {
      id
      stage
    }
  }
`

type StudentCol = ColumnProps<StudentFragment & { progresses?: ActionProgress[] }>

export function getColumns(
  showColumns: {
    schoolInfo?: boolean
    salary?: boolean
    contract?: boolean
    stripe?: boolean
  },
  options?: {
    cohorts?: Array<{ id: string; name: string }>
    programs?: Array<{ id: string; name: string }>
    schools?: Array<{ id: string; name: string }>
  },
  actions?: ActionFragment[]
) {
  const progressColumns: StudentCol[] =
    actions?.map(action => {
      return {
        title: sentenceCase(action.type),
        key: action.id,
        width: 130,
        render: (value, row) => {
          const progress = row.progresses?.find(progress => {
            return progress.actionId === action.id
          })

          return progress?.status && sentenceCase(progress.status)
        },
        filters: Object.values(ActionProgressStatus).map(status => ({
          value: status,
          text: formatActionProgressStatus(status),
        })),
        onFilter: (value, record) => {
          const progress = record.progresses?.find(progress => {
            return progress.actionId === action.id
          })

          return progress?.status === value.toString()
        },
      }
    }) || []

  const contractColumns: ColumnProps<StudentFragment>[] = showColumns.contract
    ? [
        {
          title: 'Contract',
          key: 'contract',
          width: 130,
          render: (value, student) => {
            const contract = student.contract
            if (!contract) return null
            return <Link to={`/contract/${contract.id}`}>{contract.name}</Link>
          },
        },
      ]
    : []

  const stripeColumns: ColumnProps<StudentFragment>[] = showColumns.stripe
    ? [
        {
          title: 'Stripe',
          dataIndex: 'stripeCustomerId',
          key: 'stripeCustomerId',
          width: 130,
          render: (value, student) => {
            return <StripeCell {...student} />
          },
          filters: [
            { text: 'Connected', value: 'Connected' },
            { text: 'Not Connected', value: 'Not Connected' },
            { text: 'Disabled', value: 'Disabled' },
          ],
          onFilter: (value, record) => {
            if (value === 'Not Connected') return !record.stripeCustomerId
            if (value === 'Connected') return !!record.stripeCustomerId
            if (value === 'Disabled') return !record.stripePaymentEnabled
            return true
          },
        },
      ]
    : []

  const columns: StudentCol[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: 'left',
      render: (value: string, row) => {
        return <Link to={`/student/${row.id}`}>{value}</Link>
      },
      // onFilter: (value, record) => record.name.indexOf(value.toString()) === 0,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      width: 130,
      render: (value: Stage) => {
        return <Tag color={stageColors[value]}>{value}</Tag>
      },
      filters: Object.values(Stage).map(name => ({
        value: name,
        text: formatStage(name),
      })),
      onFilter: (value, record) => {
        return record.stage === value.toString()
      },
      sorter: (a, b) => a.stage.localeCompare(b.stage),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Substage',
      dataIndex: 'substage',
      key: 'substage',
      width: 130,
      render: (value: CandidateStage | StudentStage | GraduateStage | null) => {
        return value && <Tag color={substageColors[value]}>{value}</Tag>
      },
      filters: [
        ...Object.values(CandidateStage),
        ...Object.values(StudentStage),
        ...Object.values(GraduateStage),
      ].map(name => ({
        value: name,
        text: formatSubstage(name),
      })),
      onFilter: (value, record) => {
        return record?.substage === value.toString()
      },
      sorter: (a, b) => (a.substage || '').localeCompare(b.substage || ''),
      sortDirections: ['ascend', 'descend'],
    },
    ...contractColumns,
    ...stripeColumns,
    ...progressColumns,
    ...(showColumns.salary
      ? [
          {
            title: 'Salary (monthly)',
            key: 'montlySalary',
            width: 130,
            render: (value, student) => {
              const aboveThreshold =
                (student.currentEmployment?.amountPerMonth || 0) >
                (student.contract?.threshold || 0)

              return (
                <Typography.Text style={{ color: aboveThreshold ? green.primary : red.primary }}>
                  {student.currentEmployment?.currency
                    ? formatAmount(
                        student.currentEmployment?.amountPerMonth,
                        student.currentEmployment?.currency
                      )
                    : null}
                </Typography.Text>
              )
            },
            sorter: (a, b) =>
              (a.currentEmployment?.amountPerMonth || 0) -
              (b.currentEmployment?.amountPerMonth || 0),
            sortDirections: ['ascend', 'descend'] as SortOrder[],
          },
          {
            title: 'Threshold',
            key: 'threshold',
            width: 130,
            render: (value, student) => {
              return (
                <div>
                  {student.contract?.currency
                    ? formatAmount(student.contract?.threshold, student.contract?.currency)
                    : null}
                </div>
              )
            },
            sorter: (a, b) => (a.contract?.threshold || 0) - (b.contract?.threshold || 0),
            sortDirections: ['ascend', 'descend'] as SortOrder[],
          },
        ]
      : []),
    ...(showColumns.schoolInfo
      ? [
          {
            title: 'Cohorts',
            dataIndex: 'cohorts',
            key: 'cohorts',
            width: 130,
            render: (cohorts: Cohort[]) => {
              return cohorts.map((cohort, index) => (
                <Link key={cohort.id} to={`/cohort/${cohort.id}`}>
                  {(index ? ', ' : '') + cohort.name}
                </Link>
              ))
            },
            filters: options?.cohorts?.map(({ id, name }) => ({
              value: id,
              text: name,
            })),
            onFilter: (value, record) => {
              return value === record.cohorts?.[0]?.id
            },
          },
          {
            title: 'Program',
            dataIndex: 'cohorts',
            key: 'programs',
            width: 130,
            render: (cohorts: Cohort[]) => {
              const programs = uniqBy(
                cohorts.map(cohort => cohort.program),
                program => program.id
              )

              return programs.map((program, index) => (
                <React.Fragment key={program.id}>
                  <Link to={`/program/${program.id}`}>{program.name}</Link>
                  {index === programs.length - 1 ? '' : ', '}
                </React.Fragment>
              ))
            },
            filters: options?.programs?.map(({ id, name }) => ({
              value: id,
              text: name,
            })),
            onFilter: (value, record) => {
              return value === record.cohorts?.[0].program.id
            },
          },
          {
            title: 'School',
            dataIndex: 'cohorts',
            key: 'school',
            width: 130,
            render: (cohorts: Cohort[]) => {
              const schools = uniqBy(
                cohorts.map(cohort => cohort.program.school),
                school => school.id
              )

              return schools.map((school, index) => (
                <React.Fragment key={school.id}>
                  <Link to={`/school/${school.id}`}>{school.name}</Link>
                  {index === schools.length - 1 ? '' : ', '}
                </React.Fragment>
              ))
            },
            filters: options?.schools?.map(({ id, name }) => ({
              value: id,
              text: name,
            })),
            onFilter: (value, record) => {
              return value === record.cohorts?.[0].program.school.id
            },
          },
        ]
      : []),
  ]

  return columns
}

type StudentBulkUploadResult =
  | Student
  | {
      status?: string
      message?: string
      row?: any
    }

interface StudentsProps {
  stage: Stage
}

export const Students: React.FC<StudentsProps> = props => {
  const { user } = useAuth0()
  const { data, loading, error, refetch } = useGetStudentsQuery({
    variables: {
      schoolId: user?.adminUser ? undefined : user?.schoolId,
      stage: props.stage,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [bulkUploadResult, setBulkUploadResult] = React.useState<StudentBulkUploadResult[]>()

  if (loading && !data) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  const titleMap = {
    [Stage.Candidate]: 'Candidates',
    [Stage.Onboarding]: 'Onboarding',
    [Stage.Student]: 'Students',
    [Stage.Graduate]: 'Graduates',
  }
  const title = titleMap[props.stage]

  return (
    <PageHeaderWrapper title={title}>
      <ButtonModalAdd
        title="Add Student"
        form={closeModal => <CreateStudentForm onCreate={closeModal} />}
      />
      <ButtonModalAdd
        title="Bulk Upload Students"
        form={closeModal => (
          <>
            <div style={{ paddingBottom: 20 }}>
              <FileUpload
                action={`${SERVER_URL}/student/upload-csv`}
                onSuccess={result => {
                  // closeModal()
                  console.log('result', result)
                  refetch()
                  setBulkUploadResult(result)
                }}
                sampleFile="https://docs.google.com/spreadsheets/d/1rEJ3Ktr1pK2e7OOkwhggWo9XXEA84x1OjXhTkrpcy-Y"
              />
            </div>
            {bulkUploadResult && (
              <div style={{ paddingBottom: 20 }}>
                <BulkResultList
                  bulkUploadResult={bulkUploadResult}
                  renderItem={(item: StudentBulkUploadResult) => {
                    return (
                      <List.Item>
                        {isStudentMessage(item) ? (
                          <BulkResultListSuccess
                            message={
                              <Link to={`/student/${item.id}`}>
                                {item.firstName} {item.lastName} {item.passportIdNumber}
                              </Link>
                            }
                          />
                        ) : (
                          <BulkResultListError status={item.status} message={item.message} />
                        )}
                      </List.Item>
                    )
                  }}
                />
              </div>
            )}
          </>
        )}
      />
      <StudentsContent
        students={data.students}
        showSchoolInfo
        showSalary={props.stage === Stage.Graduate}
        title={title}
        stage={props.stage}
      />
    </PageHeaderWrapper>
  )
}

function isStudentMessage(result: StudentBulkUploadResult): result is Student {
  return (result as Student).id !== undefined
}

const StudentsFilters = styled.div`
  display: flex;
`

const StageFilterWrapper = styled.div`
  margin-right: 10px;
`

const BulkActions = {
  UpdateStageCandidate: 'Update Stage to Candidate',
  UpdateStageOnboarding: 'Update Stage to Onboarding',
  UpdateStageStudent: 'Update Stage to Student',
  UpdateStageGraduate: 'Update Stage to Graduate',
}

export interface StudentsContentProps {
  students: StudentFragment[]
  showSchoolInfo: boolean
  showSalary: boolean
  title: string
  stage?: Stage
  columns?: ColumnProps<StudentFragment>[]
}

export const StudentsContent: React.FC<StudentsContentProps> = props => {
  const { students, title, showSalary } = props
  const { data, loading, error } = useActionTypeDetailsQuery()
  const { data: dataCohorts } = useCohortsQuery()

  if (error) console.error(`StudentsContent - ${error}`)

  const bulkActions = [
    ...Object.values(BulkActions).map(action => ({
      value: action,
      label: action,
    })),
    ...(data?.actionTypeDetails.map(actionType => ({
      value: actionType.type,
      label: `Send ${actionType.label} email`,
    })) || []),
  ]

  const [sendEmailBulk] = useSendEmailBulkMutation()
  const [updateStageBulk] = useUpdateStageBulkMutation()

  const [table, setTable] = React.useState<{
    search: string
    page: number
    stage: Stage | 'ALL'
    selectedRows: string[]
  }>({
    search: '',
    page: 1,
    stage: 'ALL',
    selectedRows: [],
  })

  const onSearch = (value: string) => {
    setTable(
      produce(table => {
        table.search = value
      })
    )
  }

  const onStageChange = (item: { key: any }) => {
    setTable(
      produce(table => {
        table.stage = item.key
      })
    )
  }

  const onBulkActionClicked: MenuProps['onClick'] = async item => {
    if (Object.values(ActionType).includes(item.key as ActionType)) {
      try {
        const emails = table.selectedRows.map(studentId => {
          return {
            studentId,
            type: item.key as ActionType,
          }
        })
        await sendEmailBulk({ variables: { data: emails } })
        openSuccessNotification({ description: 'Bulk sent emails' })
      } catch (error) {
        console.error(error)
        openErrorNotification({ description: 'Error sending Stripe bulk emails' })
      }
    } else if (Object.values(BulkActions).includes(item.key as string)) {
      const stages = {
        [BulkActions.UpdateStageCandidate]: Stage.Candidate,
        [BulkActions.UpdateStageOnboarding]: Stage.Onboarding,
        [BulkActions.UpdateStageStudent]: Stage.Student,
        [BulkActions.UpdateStageGraduate]: Stage.Graduate,
      }
      try {
        updateStageBulk({ variables: { studentIds: table.selectedRows, stage: stages[item.key] } })
        openSuccessNotification({ description: 'Bulk updated stage' })
      } catch (error) {
        console.error(error)
        openErrorNotification({ description: 'Error bulk updating stage' })
      }
    }
  }

  const onRowSelectChange = (studentIds: React.Key[]) => {
    setTable(
      produce(table => {
        table.selectedRows = studentIds
      })
    )
  }

  const cohorts =
    dataCohorts?.cohorts.map(cohort => {
      return {
        id: cohort.id,
        name: `${cohort.name}, ${cohort.program.name}, ${cohort.program.school.name}`,
      }
    }) || []

  const programs = uniqBy(
    dataCohorts?.cohorts.map(cohort => ({
      ...cohort.program,
      name: `${cohort.program.name}, ${cohort.program.school.name}`,
    })) || [],
    program => program.id
  )
  const schools = uniqBy(
    programs.map(program => program.school),
    school => school.id
  )

  const filteredStudents =
    table.search || table.stage
      ? students.filter(student => {
          const isStage = !table.stage || table.stage === 'ALL' || student.stage === table.stage
          return isStage && student.name.toLowerCase().includes(table.search.toLowerCase())
        })
      : students

  const stages = Object.values(Stage).map(name => ({
    value: name,
    label: formatStage(name),
  }))

  const [totalStudents, setTotalStudents] = React.useState<number>(filteredStudents.length)

  return (
    <>
      <Card
        headStyle={{ borderBottom: 'none' }}
        bordered={false}
        // with stage we have issues as numbers don't get reset
        title={`${title} ${props.stage ? '' : `(${totalStudents ?? ''})`}`}
        style={{ marginTop: 24 }}
        bodyStyle={{ padding: '0 32px' }}
        extra={
          <StudentsFilters>
            <StageFilterWrapper>
              <Dropdown
                overlay={
                  <Menu onClick={onBulkActionClicked}>
                    {bulkActions.map(bulkAction => {
                      return <Menu.Item key={bulkAction.value}>{bulkAction.label}</Menu.Item>
                    })}
                  </Menu>
                }
              >
                <Button>
                  Action In Progress <DownOutlined />
                </Button>
              </Dropdown>
            </StageFilterWrapper>

            <StageFilterWrapper>
              <Dropdown
                overlay={
                  <Menu onClick={onBulkActionClicked}>
                    {bulkActions.map(bulkAction => {
                      return <Menu.Item key={bulkAction.value}>{bulkAction.label}</Menu.Item>
                    })}
                  </Menu>
                }
              >
                <Button>
                  Bulk Actions <DownOutlined />
                </Button>
              </Dropdown>
            </StageFilterWrapper>
            <StageFilterWrapper>
              <Dropdown
                overlay={
                  <Menu onClick={onStageChange}>
                    <Menu.Item key={'ALL'}>All</Menu.Item>
                    {stages.map(stage => {
                      return <Menu.Item key={stage.value}>{stage.label}</Menu.Item>
                    })}
                  </Menu>
                }
              >
                <Button>
                  {formatStage(table.stage)} <DownOutlined />
                </Button>
              </Dropdown>
            </StageFilterWrapper>
            <Search placeholder="Search name" onSearch={onSearch} />
          </StudentsFilters>
        }
      />
      <Table
        rowKey="id"
        columns={
          props.columns ||
          getColumns(
            { schoolInfo: props.showSchoolInfo, salary: showSalary, stripe: true, contract: true },
            { cohorts, programs, schools }
          )
        }
        dataSource={filteredStudents}
        pagination={{
          defaultPageSize: 50,
          showTotal: total => `${total} students`,
        }}
        rowSelection={{ onChange: onRowSelectChange }}
        scroll={{ x: 800 }}
        onChange={(pagination, filters, sorter, extra) => {
          const { currentDataSource } = extra
          setTotalStudents(currentDataSource.length)
        }}
      />
    </>
  )
}
