import { gql } from 'apollo-boost'
import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Avatar, List, Card, Space, Tabs, Row, Col, Tag } from 'antd'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import { useGetSchoolQuery } from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ButtonModalAdd } from '../components/ButtonModalAdd'
import { ButtonModalLink } from '../components/ButtonModalLink'
import { CreateProgramForm } from './CreateProgram'
import { EditSchoolForm } from './EditSchool'
import { EditProgramForm } from '../program/EditProgram'
import { ErrorGeneral } from '../components/ErrorPages'
import { BarChart } from './BarChart'
import { PieChart } from './PieChart'
import { useTab } from '../utils/location'
import { SchoolSettings } from './SchoolSettings'
import { TabProps } from './types'
import { EditCohortForm } from '../cohort/EditCohort'
import { TagLink } from '../components/TagLink'

export const GET_SCHOOL = gql`
  fragment GetSchoolSchool on School {
    id
    name
    description
    imageUrl
    country
    studentCount {
      total
      unemployed
      employed
    }
    programs {
      id
      name
      description
      schoolId
      cohorts {
        id
        name
        studentsCount
        startDate
        endDate
        programId
        defaultContractId
        tag
      }
    }
  }

  query GetSchool($schoolId: ID!) {
    school(id: $schoolId) {
      ...GetSchoolSchool
    }
  }
`

const TabsStyled = styled(Tabs)`
  .ant-tabs-bar {
    padding-left: 16px;
    .ant-tabs-nav .ant-tabs-tab {
      padding-top: 16px;
      padding-bottom: 14px;
      line-height: 24px;
    }
  }
  .ant-tabs-extra-content {
    padding-right: 24px;
    line-height: 55px;
  }
  .ant-card-head {
    position: relative;
  }
  .ant-card-head-title {
    align-items: normal;
  }
`

const PieChartWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const PieChartPercentage = styled.h3`
  position: absolute;
  top: calc(50% + 5px);
`

const Tab = {
  OVERVIEW: 'overview',
  SETTINGS: 'settings',
}

export const School: React.FC<{}> = props => {
  const { schoolId } = useParams<{ schoolId: string }>()
  const { data, loading, error } = useGetSchoolQuery({ variables: { schoolId } })
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useTab<string>(Tab.OVERVIEW)

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  if (!data?.school) return <p>School not found.</p>

  const { school } = data

  return (
    <PageHeaderWrapper
      avatar={{ size: 'large', shape: 'square', src: school.imageUrl }}
      title={school.name}
      content={school.description || 'No description'}
      extra={
        <ButtonModalLink
          title="Edit"
          content={closeModal => <EditSchoolForm onCreate={closeModal} school={school} />}
        />
      }
      tabList={[
        { key: Tab.OVERVIEW, tab: 'Overview' },
        { key: Tab.SETTINGS, tab: 'Settings' },
      ]}
      tabActiveKey={selectedTab}
      onTabChange={tab => {
        setSelectedTab(tab)
        history.replace({ pathname: history.location.pathname, search: `?tab=${tab}` })
      }}
    >
      {selectedTab === Tab.OVERVIEW && <Overview school={school} />}
      {selectedTab === Tab.SETTINGS && <SchoolSettings school={school} />}
    </PageHeaderWrapper>
  )
}

const Overview: React.FC<TabProps> = props => {
  const { school } = props
  const { programs } = school
  const cohorts = programs
    .map(program => {
      return program.cohorts.map(cohort => {
        return {
          ...cohort,
          program,
        }
      })
    })
    .flat()

  const placementPercentage =
    Math.round(
      (school.studentCount.employed /
        (school.studentCount.employed + school.studentCount.unemployed)) *
        100
    ) || 0

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      {!!school.programs.length && (
        <Row gutter={16}>
          <Col sm={24} md={18}>
            <Card bordered={false} bodyStyle={{ padding: 0 }}>
              <TabsStyled
                defaultActiveKey="1"
                onChange={key => console.log(key)}
                size="large"
                tabBarStyle={{ marginBottom: 24 }}
              >
                {school.programs.map(program => {
                  const data = program.cohorts.map(cohort => {
                    return {
                      name: cohort.name,
                      studentsCount: cohort.studentsCount,
                    }
                  })

                  return (
                    <Tabs.TabPane key={program.id} tab={program.name}>
                      <BarChart data={data} dataKey="studentsCount" name="Students" />
                    </Tabs.TabPane>
                  )
                })}
              </TabsStyled>
            </Card>
          </Col>
          <Col sm={24} md={6}>
            <Card title="Placement Rate" style={{ height: '100%' }}>
              <PieChartWrapper>
                <PieChart percentage={placementPercentage} />
                <PieChartPercentage>{placementPercentage}%</PieChartPercentage>
              </PieChartWrapper>
            </Card>
          </Col>
        </Row>
      )}

      <Card
        headStyle={{ borderBottom: 'none' }}
        bordered={false}
        title="Cohorts"
        bodyStyle={{ padding: '0 32px 40px 32px' }}
      >
        <List
          size="large"
          rowKey="id"
          loading={false}
          dataSource={cohorts}
          renderItem={cohort => {
            return (
              <List.Item
                actions={[
                  <ButtonModalLink
                    title="Edit"
                    content={closeModal => (
                      <EditCohortForm onCreate={closeModal} cohort={cohort} schoolId={school.id} />
                    )}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                      {cohort.name[0]}
                    </Avatar>
                  }
                  title={
                    <Link to={`/cohort/${cohort.id}`}>
                      {cohort.name}, {cohort.program.name}
                    </Link>
                  }
                  description={
                    cohort.tag ? <TagLink tag={cohort.tag} schoolId={school.id} /> : null
                  }
                />
              </List.Item>
            )
          }}
        />
      </Card>

      <Card
        headStyle={{ borderBottom: 'none' }}
        bordered={false}
        title="Programs"
        bodyStyle={{ padding: '0 32px 40px 32px' }}
      >
        <ButtonModalAdd
          title="Add Program"
          form={closeModal => <CreateProgramForm schoolId={school.id} onCreate={closeModal} />}
        />
        <List
          size="large"
          rowKey="id"
          loading={false}
          dataSource={programs}
          renderItem={program => (
            <List.Item
              actions={[
                <ButtonModalLink
                  title="Edit"
                  content={closeModal => (
                    <EditProgramForm onCreate={closeModal} program={program} />
                  )}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                    {program.name[0]}
                  </Avatar>
                }
                title={<Link to={`/program/${program.id}`}>{program.name}</Link>}
                description={program.description}
              />
            </List.Item>
          )}
        />
      </Card>
    </Space>
  )
}
