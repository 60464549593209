import React, { SetStateAction, Dispatch } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export function useSearchQuery() {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export function useTab<T extends string | number = string>(
  initialValue: T,
  tabKey = 'tab'
): [T, Dispatch<SetStateAction<T>>] {
  const query = useSearchQuery()
  const history = useHistory()

  const tab = query.get(tabKey)

  const t = typeof initialValue === 'number' ? parseInt(tab || '0') : tab || ''
  const [selectedTab, setSelectedTab] = React.useState<T>((t as T) || initialValue)

  React.useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search: `${history.location.search[0] === '?' ? '&' : '?'}${tabKey}=${selectedTab}`,
    })
  }, [history, selectedTab, tabKey])

  return [selectedTab, setSelectedTab]
}
