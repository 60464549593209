import { sentenceCase } from 'change-case'
import { PaymentStatus, PaymentMethod } from '../generated/graphql'

export const formatPaymentStatus = (paymentStatus: PaymentStatus) => {
  return sentenceCase(paymentStatus)
}

export const formatPaymentMethod = (paymentMethod: PaymentMethod) => {
  return sentenceCase(paymentMethod)
}
