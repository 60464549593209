import { Button, Form } from 'antd'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import { CustomInputComponent, CustomTextAreaComponent } from '../components/FormikCustom'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { useEditProgramMutation, EditProgramInput, GetProgramFragment } from '../generated/graphql'
import { GET_PROGRAM } from './Program'
import { trackEvent } from '../utils/analytics'

export const EDIT_PROGRAM = gql`
  mutation EditProgram($data: EditProgramInput!) {
    editProgram(data: $data) {
      id
      name
      description
    }
  }
`

interface EditProgramFormProps {
  onCreate?: () => void
  program: GetProgramFragment
}

export const EditProgramForm: React.FC<EditProgramFormProps> = props => {
  const [editProgram] = useEditProgramMutation({
    refetchQueries: [{ query: GET_PROGRAM, variables: { programId: props.program.id } }],
  })

  return (
    <Formik<EditProgramInput>
      enableReinitialize
      initialValues={{
        id: props.program.id,
        name: props.program.name,
        description: props.program.description,
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)

        editProgram({ variables: { data: values } })
        trackEvent('editProgram', values)
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="name" label="Program Name" component={CustomInputComponent} />
          <Field name="description" label="Description" component={CustomTextAreaComponent} />
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
