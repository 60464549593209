import { Field } from 'formik'
import React from 'react'
import { Currency } from '../generated/graphql'
import { CustomSelectComponent } from './FormikCustom'

interface CurrencySelectProps {
  disabled?: boolean
}

/**
 * Expected to be used in a Formik form
 */
export const CurrencySelectField: React.FC<CurrencySelectProps> = props => {
  return (
    <Field
      name="currency"
      label="Currency"
      options={Object.values(Currency).map(name => ({
        value: name,
        label: name,
      }))}
      component={CustomSelectComponent}
      disabled={props.disabled}
    />
  )
}
