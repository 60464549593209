import { Field } from 'formik'
import React from 'react'
import { useActionGroupsQuery } from '../generated/graphql'
import { CustomSelectComponent } from './FormikCustom'
import { Loading } from './Loading'

interface ActionGroupSelectProps {}

/**
 * Expected to be used in a Formik form
 */
export const ActionGroupSelectField: React.FC<ActionGroupSelectProps> = props => {
  const { data, loading, error } = useActionGroupsQuery({ fetchPolicy: 'cache-and-network' })

  if (loading) return <Loading />

  if (error) {
    console.error(error)
    return <p>Error loading. {error.message}.</p>
  }

  return (
    <Field
      name="actionGroupId"
      label=""
      options={data?.actionGroups.map(actionGroup => ({
        value: actionGroup.id,
        label: actionGroup.name,
      }))}
      component={CustomSelectComponent}
      style={{ minWidth: 150 }}
    />
  )
}
