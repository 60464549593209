import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Card } from 'antd'
import styled from 'styled-components'
import { useGetStudentQuery } from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ProgressTabs } from './ProgressTabs'

const Subheading = styled.span`
  font-weight: bold;
  line-height: 40px;
`

export const StudentView: React.FC<{}> = props => {
  const { studentId } = useParams<{ studentId: string }>()
  const { data, loading } = useGetStudentQuery({ variables: { studentId } })

  if (loading) return <Loading />

  if (!data?.student) return <p>Something went wrong.</p>

  const { student } = data

  return (
    <div>
      <Card
        title="Personal Information"
        extra={<a href="#">Edit</a>}
        style={{ width: 400, display: 'inline-block' }}
      >
        <Subheading>Name: </Subheading>
        <span>
          {student.firstName} {student.lastName}
        </span>
        <br></br>
        <Subheading>Spark ID: </Subheading>
        <span>{student.id}</span>
        <br></br>
        <Subheading>ID/Passport: </Subheading>
        <span>{student.passportIdNumber}</span>
        <br></br>
        <Subheading>Email: </Subheading>
        <span>{student.email}</span>
        <br></br>
        <Subheading>Address: </Subheading>
        <span>{student.address}</span>
        <br></br>
        <Subheading>Date Created: </Subheading>
        <span>{student.createdAt}</span>
        <br></br>
        <Subheading>Cohorts: </Subheading>
        {student.cohorts?.map(cohort => (
          <span key={cohort.id}>
            <Link to={`/school/${cohort.program.school.id}`}>{cohort.program.school.name} </Link>{' '}
            <span> / </span>
            <Link to={`/program/${cohort.program.id}`}>{cohort.program.name}</Link>
            <span> / </span>
            <Link to={`/cohort/${cohort.id}`}>{cohort.name}</Link>
          </span>
        ))}
      </Card>
      <ProgressTabs />
    </div>
  )
}
