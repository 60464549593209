import React from 'react'
import styled from 'styled-components'
// import { Spin, Icon } from 'antd'
import { PageLoading } from '@ant-design/pro-layout'

interface LoadingProps {}

export const Loading: React.FC<LoadingProps> = props => {
  return (
    <LoadingWrapper>
      <PageLoading />
    </LoadingWrapper>
  )
  // return <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
}

const LoadingWrapper = styled.div`
  /* antd-pro adds 100px padding top */
  padding-bottom: 100px;
`
