import React from 'react'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import styled from 'styled-components'
import { Link, useParams, useHistory } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { Tag, Popconfirm, message } from 'antd'
import { Loading } from '../../components/Loading'
import { useGetStudentQuery, useDeleteStudentMutation } from '../../generated/graphql'
import { Employment } from './Employment'
import { Payment } from './Payment'
import { STUDENT_FRAGMENT } from '../fragments'
import { ErrorGeneral } from '../../components/ErrorPages'
import { stageColors, getSubstageColors } from '../utils'
import { Timelines } from './Timelines'
import { useTab } from '../../utils/location'
import { CardWrapper } from '../../components/Card'
import { Overview } from './Overview'
import { Notes } from './Notes'
import { ButtonModalLink } from '../../components/ButtonModalLink'
import { formatGraphQLError } from '../../utils/error'
import { EditStudentForm } from '../EditStudent'
import { ExtraActions } from '../../components/ExtraActions'
import { trackEvent } from '../../utils/analytics'
import { Documents } from './Documents'

const StudentContainer = styled.div`
  justify-content: center;
`

export const GET_STUDENT = gql`
  query GetStudent($studentId: ID!) {
    student(id: $studentId) {
      ...GetStudentStudent
    }
  }

  ${STUDENT_FRAGMENT}
`

gql`
  mutation DeleteStudent($studentId: ID!) {
    deleteStudent(studentId: $studentId)
  }
`

const Tab = {
  OVERVIEW: 'overview',
  TIMELINES: 'timelines',
  EMPLOYMENT: 'employment',
  PAYMENTS: 'payments',
  DOCUMENTS: 'documents',
  CAREER_ACCELERATION: 'career-acceleration',
  NOTES: 'notes',
}

export const Student: React.FC<{}> = props => {
  const { studentId } = useParams<{ studentId: string }>()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useTab<string>(Tab.OVERVIEW)
  const { data, loading, error } = useGetStudentQuery({ variables: { studentId } })
  const [deleteStudent] = useDeleteStudentMutation({ variables: { studentId } })

  if (loading) return <Loading />

  if (!data?.student || error) {
    console.error('error', error)
    return <ErrorGeneral error={error} />
  }

  const { student } = data

  return (
    <>
      <PageHeaderWrapper
        title={
          <div className="ant-page-header-heading">
            <span className="ant-page-header-heading-title">
              {student.firstName} {student.lastName}{' '}
              <Tag color={stageColors[student.stage]}>{student.stage}</Tag>
              <Tag color={getSubstageColors(student.stage, student || {})}>
                {student.graduateStage ||
                  student.candidateStage ||
                  student.studentStage ||
                  'NO SUBSTAGE'}
              </Tag>
              {student.deleted && <Tag color="red">DELETED</Tag>}
            </span>
          </div>
        }
        content={
          <div>
            {student.cohorts?.map(cohort => (
              <div key={cohort.id}>
                <Link to={`/school/${cohort.program.school.id}`}>
                  {cohort.program.school.name}{' '}
                </Link>{' '}
                <span> / </span>
                <Link to={`/program/${cohort.program.id}`}>{cohort.program.name}</Link>
                <span> / </span>
                <Link to={`/cohort/${cohort.id}`}>{cohort.name}</Link>
              </div>
            ))}
          </div>
        }
        tabList={[
          { key: Tab.OVERVIEW, tab: 'Overview' },
          { key: Tab.TIMELINES, tab: 'Timelines' },
          { key: Tab.EMPLOYMENT, tab: 'Employment' },
          { key: Tab.PAYMENTS, tab: 'Payments' },
          // { key: Tab.DOCUMENTS, tab: 'Documents' },
          // { key: Tab.CAREER_ACCELERATION, tab: 'Career Acceleration' },
          { key: Tab.NOTES, tab: 'Notes' },
        ]}
        tabActiveKey={selectedTab}
        onTabChange={tab => {
          setSelectedTab(tab)
          history.replace({ pathname: history.location.pathname, search: `?tab=${tab}` })
        }}
        extra={
          <ExtraActions>
            <ButtonModalLink
              title="Edit"
              content={closeModal => <EditStudentForm onCreate={closeModal} student={student} />}
            />
            <Popconfirm
              title="Are you sure you want to delete this student?"
              onConfirm={async () => {
                try {
                  await deleteStudent()
                  trackEvent('deleteStudent', { studentId })
                  message.success('Deleted student')
                } catch (error) {
                  console.error(error)
                  message.error(formatGraphQLError(error))
                }
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <a href="#">Delete</a>
            </Popconfirm>
          </ExtraActions>
        }
      >
        <StudentContainer>
          {selectedTab === Tab.OVERVIEW && <Overview student={data.student} />}
          {selectedTab === Tab.TIMELINES && <Timelines studentId={studentId} student={student} />}
          {selectedTab === Tab.EMPLOYMENT && (
            <Employment
              studentId={studentId}
              currency={student.contract?.currency}
              employments={student.employments}
            />
          )}
          {selectedTab === Tab.PAYMENTS && <Payment payments={student.payments as any} />}
          {selectedTab === Tab.DOCUMENTS && <Documents student={student} />}
          {selectedTab === Tab.CAREER_ACCELERATION && 'Coming soon...'}
          {selectedTab === Tab.NOTES && <Notes student={student} />}
        </StudentContainer>
      </PageHeaderWrapper>
    </>
  )
}
