import { Card } from 'antd'
import gql from 'graphql-tag'
import React from 'react'
import { Link } from 'react-router-dom'
import { PageHeaderWrapper } from '@ant-design/pro-layout'
import Table, { ColumnProps } from 'antd/lib/table'

import { Loading } from '../components/Loading'
import { ButtonModalAdd } from '../components/ButtonModalAdd'
import {
  GetContractsContractFragment,
  Contract,
  useGetContractsQuery,
  useGetSchoolContractsFullQuery,
} from '../generated/graphql'
import { ContractForm } from './ContractForm'
import { ErrorGeneral } from '../components/ErrorPages'
import { formatContractType, formatThresholdType } from './utils'
import { formatAmount } from '../utils/currency'
import { useAuth0 } from '../auth/auth0'

const CONTRACT_FRAGMENT = gql`
  fragment GetContractsContract on Contract {
    id
    name
    description
    type
    ratePercentage
    periodPostEmploymentMonths
    periodPostGraduationMonths
    cap
    threshold
    thresholdType
    upfrontPayment
    currency
  }
`

export const GET_CONTRACTS = gql`
  query GetContracts {
    contracts {
      ...GetContractsContract
    }
  }

  ${CONTRACT_FRAGMENT}
`

export const GET_SCHOOL_CONTRACTS = gql`
  query GetSchoolContractsFull($schoolId: ID!) {
    contractsForSchool(schoolId: $schoolId) {
      ...GetContractsContract
    }
  }

  ${CONTRACT_FRAGMENT}
`

const columns: ColumnProps<GetContractsContractFragment>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value: Contract['name'], row) => {
      return <Link to={`/contract/${row.id}`}>{value}</Link>
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (value: Contract['type']) => {
      return <>{formatContractType(value)}</>
    },
  },
  {
    title: 'Payment Period',
    dataIndex: 'periodPostEmploymentMonths',
    key: 'periodPostEmploymentMonths',
    render: (value: Contract['periodPostEmploymentMonths']) => {
      return <>{value} months</>
    },
  },
  {
    title: 'Contract Period',
    dataIndex: 'periodPostGraduationMonths',
    key: 'periodPostGraduationMonths',
    render: (value: Contract['periodPostGraduationMonths']) => {
      return <>{value} months</>
    },
  },
  {
    title: 'Rate',
    dataIndex: 'ratePercentage',
    key: 'ratePercentage',
    render: (value: Contract['ratePercentage']) => {
      return <>{value}%</>
    },
  },
  {
    title: 'Cap',
    dataIndex: 'cap',
    key: 'cap',
    render: (value: Contract['cap'], row) => {
      return <>{formatAmount(value, row.currency)}</>
    },
  },
  {
    title: 'Threshold',
    dataIndex: 'threshold',
    key: 'threshold',
    render: (value: Contract['threshold'], row) => {
      return <>{formatAmount(value, row.currency)}</>
    },
  },
  {
    title: 'Threshold Type',
    dataIndex: 'thresholdType',
    key: 'thresholdType',
    render: (value: Contract['thresholdType']) => {
      return <>{formatThresholdType(value)}</>
    },
  },
  {
    title: 'Upfront Payment',
    dataIndex: 'upfrontPayment',
    key: 'upfrontPayment',
    render: (value: Contract['upfrontPayment'], row) => {
      return <>{formatAmount(value, row.currency)}</>
    },
  },
  // {
  //   title: 'Currency',
  //   dataIndex: 'currency',
  //   key: 'currency',
  // },
]

export const Contracts: React.FC<{}> = () => {
  const { user } = useAuth0()
  console.log('user', user)

  if (user?.adminUser) return <ContractsAll />

  const schoolId = user?.schoolId

  if (!schoolId) return null

  return <ContractsSchool schoolId={schoolId} />
}

const ContractsAll: React.FC<{}> = () => {
  const { data, loading, error } = useGetContractsQuery()

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  const { contracts } = data

  return <ContractsPage contracts={contracts} />
}

const ContractsSchool: React.FC<{ schoolId: string }> = props => {
  const { data, loading, error } = useGetSchoolContractsFullQuery({
    variables: { schoolId: props.schoolId },
  })

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  const { contractsForSchool } = data

  return <ContractsPage contracts={contractsForSchool} />
}

const ContractsPage: React.FC<{ contracts: GetContractsContractFragment[] }> = props => {
  const { user } = useAuth0()
  const { contracts } = props

  return (
    <PageHeaderWrapper title="Contracts">
      <Card bodyStyle={{ padding: '40px 32px' }}>
        {!!user?.adminUser && (
          <ButtonModalAdd
            title="Add Contract"
            form={closeModal => <ContractForm onCreate={closeModal} />}
          />
        )}
        <Table rowKey="id" columns={columns} dataSource={contracts} />
      </Card>
    </PageHeaderWrapper>
  )
}
