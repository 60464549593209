import { Button, Result } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

export const ErrorGeneral: React.FC<{ error?: any }> = props => {
  const { error } = props
  const history = useHistory()

  if (error) {
    Sentry.captureException(error)
    console.error('error', error)
  }

  return (
    <Result
      status="error"
      title="Error"
      subTitle="Sorry, there was an error loading this page."
      extra={
        <>
          {!!props.error && (
            <div style={{ margin: 20 }}>
              <div>Detailed error:</div>
              <div>{JSON.stringify(props.error)}</div>
            </div>
          )}
          <Button type="primary" onClick={() => history.push('/')}>
            Back Home
          </Button>
        </>
      }
    />
  )
}

export const ErrorNotExist: React.FC<{}> = () => {
  const history = useHistory()

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Back Home
        </Button>
      }
    />
  )
}

export const ErrorForbidden: React.FC<{ error: any }> = ({ error }) => {
  const history = useHistory()
  console.error('error', error)

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you don't have permission to access this page."
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Back Home
        </Button>
      }
    />
  )
}
