import { Currency } from '../generated/graphql'

// on the server $13.24 is expressed as 1324 to avoid dealing with decimals
export const serverAmountToAmount = (amount: number): number => amount / 100
export const amountToServerAmount = (amount: number): number => amount * 100

const numberWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const currencySymbolMap = {
  [Currency.Ils]: '₪',
  [Currency.Usd]: '$',
  [Currency.Eur]: '€',
  [Currency.Gbp]: '£',
}

export const formatAmount = (
  serverAmount: number | null | undefined,
  currency: Currency
): string => {
  const symbol = currencySymbolMap[currency]
  const amount = numberWithCommas(serverAmountToAmount(serverAmount || 0))

  // if (currency === Currency.Ils) return amount + symbol

  return symbol + amount
}
