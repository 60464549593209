/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
};

export type Action = {
   __typename?: 'Action',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  type: ActionType,
  sendgridTemplateId?: Maybe<Scalars['String']>,
  reminderSendgridTemplateId?: Maybe<Scalars['String']>,
  enabled: Scalars['Boolean'],
  order: Scalars['Float'],
  actionGroupId: Scalars['String'],
  actionGroup: ActionGroup,
  progress?: Maybe<ActionProgress>,
  sentEmails: Array<Email>,
};


export type ActionProgressArgs = {
  studentId: Scalars['ID']
};


export type ActionSentEmailsArgs = {
  studentId: Scalars['ID']
};

export type ActionGroup = {
   __typename?: 'ActionGroup',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  actionGroup: ActionGroup,
  actions: Array<Action>,
};

export type ActionProgress = {
   __typename?: 'ActionProgress',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  studentId: Scalars['String'],
  student: Student,
  actionId: Scalars['String'],
  action: Action,
  status: ActionProgressStatus,
};

export enum ActionProgressStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

export enum ActionType {
  FirstEmail = 'FIRST_EMAIL',
  ScheduleInterview = 'SCHEDULE_INTERVIEW',
  Interview = 'INTERVIEW',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Welcome = 'WELCOME',
  Blender = 'BLENDER',
  Contract = 'CONTRACT',
  Stripe = 'STRIPE',
  Plaid = 'PLAID',
  ManualPayment = 'MANUAL_PAYMENT',
  Congratulations = 'CONGRATULATIONS'
}

export type ActionTypeDetail = {
   __typename?: 'ActionTypeDetail',
  label: Scalars['String'],
  stage: Scalars['String'],
  type: ActionType,
};

export enum CandidateStage {
  Interviewing = 'INTERVIEWING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export type Cohort = {
   __typename?: 'Cohort',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  startDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  defaultContractId?: Maybe<Scalars['String']>,
  defaultContract?: Maybe<Contract>,
  programId: Scalars['String'],
  program: Program,
  students: Array<Student>,
  onboardingTrackId?: Maybe<Scalars['String']>,
  onboardingTrack?: Maybe<Track>,
  actionGroupId?: Maybe<Scalars['String']>,
  actionGroup?: Maybe<ActionGroup>,
  tag?: Maybe<Scalars['String']>,
  deleted?: Maybe<Scalars['Boolean']>,
  studentsCount: Scalars['Int'],
};

export type Contract = {
   __typename?: 'Contract',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  cap?: Maybe<Scalars['Int']>,
  threshold: Scalars['Int'],
  dropoutPrincipal?: Maybe<Scalars['Int']>,
  upfrontPayment: Scalars['Int'],
  creatorId: Scalars['String'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  type: ContractType,
  ratePercentage: Scalars['Float'],
  periodPostEmploymentMonths: Scalars['Float'],
  periodPostGraduationMonths?: Maybe<Scalars['Float']>,
  dropoutInterest?: Maybe<Scalars['Float']>,
  thresholdType: ThresholdType,
  currency: Currency,
  fileUrl?: Maybe<Scalars['String']>,
  schoolId?: Maybe<Scalars['String']>,
  school?: Maybe<School>,
  deleted?: Maybe<Scalars['Boolean']>,
  cohorts: Array<Cohort>,
  students: Array<Student>,
};

export enum ContractType {
  Isa = 'ISA',
  PrinciplePlusPercent = 'PRINCIPLE_PLUS_PERCENT'
}

export enum Country {
  Afghanistan = 'AFGHANISTAN',
  AlandIslands = 'ALAND_ISLANDS',
  Albania = 'ALBANIA',
  Algeria = 'ALGERIA',
  AmericanSamoa = 'AMERICAN_SAMOA',
  Andorra = 'ANDORRA',
  Angola = 'ANGOLA',
  Anguilla = 'ANGUILLA',
  Antarctica = 'ANTARCTICA',
  AntiguaAndBarbuda = 'ANTIGUA_AND_BARBUDA',
  Argentina = 'ARGENTINA',
  Armenia = 'ARMENIA',
  Aruba = 'ARUBA',
  Australia = 'AUSTRALIA',
  Austria = 'AUSTRIA',
  Azerbaijan = 'AZERBAIJAN',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Barbados = 'BARBADOS',
  Belarus = 'BELARUS',
  Belgium = 'BELGIUM',
  Belize = 'BELIZE',
  Benin = 'BENIN',
  Bermuda = 'BERMUDA',
  Bhutan = 'BHUTAN',
  Bolivia = 'BOLIVIA',
  BosniaAndHerzegovina = 'BOSNIA_AND_HERZEGOVINA',
  Botswana = 'BOTSWANA',
  BouvetIsland = 'BOUVET_ISLAND',
  Brazil = 'BRAZIL',
  BritishIndianOceanTerritory = 'BRITISH_INDIAN_OCEAN_TERRITORY',
  BruneiDarussalam = 'BRUNEI_DARUSSALAM',
  Bulgaria = 'BULGARIA',
  BurkinaFaso = 'BURKINA_FASO',
  Burundi = 'BURUNDI',
  Cambodia = 'CAMBODIA',
  Cameroon = 'CAMEROON',
  Canada = 'CANADA',
  CapeVerde = 'CAPE_VERDE',
  CaymanIslands = 'CAYMAN_ISLANDS',
  CentralAfricanRepublic = 'CENTRAL_AFRICAN_REPUBLIC',
  Chad = 'CHAD',
  Chile = 'CHILE',
  China = 'CHINA',
  ChristmasIsland = 'CHRISTMAS_ISLAND',
  CocosKeelingIslands = 'COCOS_KEELING_ISLANDS',
  Colombia = 'COLOMBIA',
  Comoros = 'COMOROS',
  Congo = 'CONGO',
  CongoTheDemocraticRepublicOfThe = 'CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE',
  CookIslands = 'COOK_ISLANDS',
  CostaRica = 'COSTA_RICA',
  CoteDIvoire = 'COTE_D_IVOIRE',
  Croatia = 'CROATIA',
  Cuba = 'CUBA',
  Cyprus = 'CYPRUS',
  CzechRepublic = 'CZECH_REPUBLIC',
  Denmark = 'DENMARK',
  Djibouti = 'DJIBOUTI',
  Dominica = 'DOMINICA',
  DominicanRepublic = 'DOMINICAN_REPUBLIC',
  Ecuador = 'ECUADOR',
  Egypt = 'EGYPT',
  ElSalvador = 'EL_SALVADOR',
  EquatorialGuinea = 'EQUATORIAL_GUINEA',
  Eritrea = 'ERITREA',
  Estonia = 'ESTONIA',
  Ethiopia = 'ETHIOPIA',
  FalklandIslandsMalvinas = 'FALKLAND_ISLANDS_MALVINAS',
  FaroeIslands = 'FAROE_ISLANDS',
  Fiji = 'FIJI',
  Finland = 'FINLAND',
  France = 'FRANCE',
  FrenchGuiana = 'FRENCH_GUIANA',
  FrenchPolynesia = 'FRENCH_POLYNESIA',
  FrenchSouthernTerritories = 'FRENCH_SOUTHERN_TERRITORIES',
  Gabon = 'GABON',
  Gambia = 'GAMBIA',
  Georgia = 'GEORGIA',
  Germany = 'GERMANY',
  Ghana = 'GHANA',
  Gibraltar = 'GIBRALTAR',
  Greece = 'GREECE',
  Greenland = 'GREENLAND',
  Grenada = 'GRENADA',
  Guadeloupe = 'GUADELOUPE',
  Guam = 'GUAM',
  Guatemala = 'GUATEMALA',
  Guernsey = 'GUERNSEY',
  Guinea = 'GUINEA',
  GuineaBissau = 'GUINEA_BISSAU',
  Guyana = 'GUYANA',
  Haiti = 'HAITI',
  HeardIslandAndMcdonaldIslands = 'HEARD_ISLAND_AND_MCDONALD_ISLANDS',
  HolySeeVaticanCityState = 'HOLY_SEE_VATICAN_CITY_STATE',
  Honduras = 'HONDURAS',
  HongKong = 'HONG_KONG',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  India = 'INDIA',
  Indonesia = 'INDONESIA',
  IranIslamicRepublicOf = 'IRAN_ISLAMIC_REPUBLIC_OF',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  IsleOfMan = 'ISLE_OF_MAN',
  Israel = 'ISRAEL',
  Italy = 'ITALY',
  Jamaica = 'JAMAICA',
  Japan = 'JAPAN',
  Jersey = 'JERSEY',
  Jordan = 'JORDAN',
  Kazakhstan = 'KAZAKHSTAN',
  Kenya = 'KENYA',
  Kiribati = 'KIRIBATI',
  DemocraticPeopleSRepublicOfKorea = 'DEMOCRATIC_PEOPLE_S_REPUBLIC_OF_KOREA',
  KoreaRepublicOf = 'KOREA_REPUBLIC_OF',
  Kosovo = 'KOSOVO',
  Kuwait = 'KUWAIT',
  Kyrgyzstan = 'KYRGYZSTAN',
  LaoPeopleSDemocraticRepublic = 'LAO_PEOPLE_S_DEMOCRATIC_REPUBLIC',
  Latvia = 'LATVIA',
  Lebanon = 'LEBANON',
  Lesotho = 'LESOTHO',
  Liberia = 'LIBERIA',
  LibyanArabJamahiriya = 'LIBYAN_ARAB_JAMAHIRIYA',
  Liechtenstein = 'LIECHTENSTEIN',
  Lithuania = 'LITHUANIA',
  Luxembourg = 'LUXEMBOURG',
  Macao = 'MACAO',
  MacedoniaTheFormerYugoslavRepublicOf = 'MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF',
  Madagascar = 'MADAGASCAR',
  Malawi = 'MALAWI',
  Malaysia = 'MALAYSIA',
  Maldives = 'MALDIVES',
  Mali = 'MALI',
  Malta = 'MALTA',
  MarshallIslands = 'MARSHALL_ISLANDS',
  Martinique = 'MARTINIQUE',
  Mauritania = 'MAURITANIA',
  Mauritius = 'MAURITIUS',
  Mayotte = 'MAYOTTE',
  Mexico = 'MEXICO',
  MicronesiaFederatedStatesOf = 'MICRONESIA_FEDERATED_STATES_OF',
  MoldovaRepublicOf = 'MOLDOVA_REPUBLIC_OF',
  Monaco = 'MONACO',
  Mongolia = 'MONGOLIA',
  Montenegro = 'MONTENEGRO',
  Montserrat = 'MONTSERRAT',
  Morocco = 'MOROCCO',
  Mozambique = 'MOZAMBIQUE',
  Myanmar = 'MYANMAR',
  Namibia = 'NAMIBIA',
  Nauru = 'NAURU',
  Nepal = 'NEPAL',
  Netherlands = 'NETHERLANDS',
  NetherlandsAntilles = 'NETHERLANDS_ANTILLES',
  NewCaledonia = 'NEW_CALEDONIA',
  NewZealand = 'NEW_ZEALAND',
  Nicaragua = 'NICARAGUA',
  Niger = 'NIGER',
  Nigeria = 'NIGERIA',
  Niue = 'NIUE',
  NorfolkIsland = 'NORFOLK_ISLAND',
  NorthernMarianaIslands = 'NORTHERN_MARIANA_ISLANDS',
  Norway = 'NORWAY',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Palau = 'PALAU',
  PalestinianTerritoryOccupied = 'PALESTINIAN_TERRITORY_OCCUPIED',
  Panama = 'PANAMA',
  PapuaNewGuinea = 'PAPUA_NEW_GUINEA',
  Paraguay = 'PARAGUAY',
  Peru = 'PERU',
  Philippines = 'PHILIPPINES',
  Pitcairn = 'PITCAIRN',
  Poland = 'POLAND',
  Portugal = 'PORTUGAL',
  PuertoRico = 'PUERTO_RICO',
  Qatar = 'QATAR',
  Reunion = 'REUNION',
  Romania = 'ROMANIA',
  RussianFederation = 'RUSSIAN_FEDERATION',
  Rwanda = 'RWANDA',
  SaintHelena = 'SAINT_HELENA',
  SaintKittsAndNevis = 'SAINT_KITTS_AND_NEVIS',
  SaintLucia = 'SAINT_LUCIA',
  SaintPierreAndMiquelon = 'SAINT_PIERRE_AND_MIQUELON',
  SaintVincentAndTheGrenadines = 'SAINT_VINCENT_AND_THE_GRENADINES',
  Samoa = 'SAMOA',
  SanMarino = 'SAN_MARINO',
  SaoTomeAndPrincipe = 'SAO_TOME_AND_PRINCIPE',
  SaudiArabia = 'SAUDI_ARABIA',
  Senegal = 'SENEGAL',
  Serbia = 'SERBIA',
  Seychelles = 'SEYCHELLES',
  SierraLeone = 'SIERRA_LEONE',
  Singapore = 'SINGAPORE',
  Slovakia = 'SLOVAKIA',
  Slovenia = 'SLOVENIA',
  SolomonIslands = 'SOLOMON_ISLANDS',
  Somalia = 'SOMALIA',
  SouthAfrica = 'SOUTH_AFRICA',
  SouthGeorgiaAndTheSouthSandwichIslands = 'SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS',
  Spain = 'SPAIN',
  SriLanka = 'SRI_LANKA',
  Sudan = 'SUDAN',
  Suriname = 'SURINAME',
  SvalbardAndJanMayen = 'SVALBARD_AND_JAN_MAYEN',
  Swaziland = 'SWAZILAND',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  SyrianArabRepublic = 'SYRIAN_ARAB_REPUBLIC',
  Taiwan = 'TAIWAN',
  Tajikistan = 'TAJIKISTAN',
  TanzaniaUnitedRepublicOf = 'TANZANIA_UNITED_REPUBLIC_OF',
  Thailand = 'THAILAND',
  TimorLeste = 'TIMOR_LESTE',
  Togo = 'TOGO',
  Tokelau = 'TOKELAU',
  Tonga = 'TONGA',
  TrinidadAndTobago = 'TRINIDAD_AND_TOBAGO',
  Tunisia = 'TUNISIA',
  Turkey = 'TURKEY',
  Turkmenistan = 'TURKMENISTAN',
  TurksAndCaicosIslands = 'TURKS_AND_CAICOS_ISLANDS',
  Tuvalu = 'TUVALU',
  Uganda = 'UGANDA',
  Ukraine = 'UKRAINE',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedStates = 'UNITED_STATES',
  UnitedStatesMinorOutlyingIslands = 'UNITED_STATES_MINOR_OUTLYING_ISLANDS',
  Uruguay = 'URUGUAY',
  Uzbekistan = 'UZBEKISTAN',
  Vanuatu = 'VANUATU',
  Venezuela = 'VENEZUELA',
  VietNam = 'VIET_NAM',
  VirginIslandsBritish = 'VIRGIN_ISLANDS_BRITISH',
  VirginIslandsUS = 'VIRGIN_ISLANDS_U_S',
  WallisAndFutuna = 'WALLIS_AND_FUTUNA',
  WesternSahara = 'WESTERN_SAHARA',
  Yemen = 'YEMEN',
  Zambia = 'ZAMBIA',
  Zimbabwe = 'ZIMBABWE'
}

export type CountryFull = {
   __typename?: 'CountryFull',
  name: Scalars['String'],
  enum: Country,
};

export type CreateActionGroupActionsInput = {
  order: Scalars['Int'],
  type: ActionType,
  sendgridTemplateId?: Maybe<Scalars['String']>,
  reminderSendgridTemplateId?: Maybe<Scalars['String']>,
  enabled?: Maybe<Scalars['Boolean']>,
};

export type CreateActionGroupInput = {
  name: Scalars['String'],
  actions: Array<CreateActionGroupActionsInput>,
};

export type CreateCohortInput = {
  name: Scalars['String'],
  startDate: Scalars['DateTime'],
  endDate: Scalars['DateTime'],
  programId: Scalars['String'],
  defaultContractId?: Maybe<Scalars['String']>,
  tag?: Maybe<Scalars['String']>,
};

export type CreateContractInput = {
  cap?: Maybe<Scalars['Int']>,
  threshold: Scalars['Int'],
  upfrontPayment?: Maybe<Scalars['Int']>,
  dropoutPrincipal?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  type: ContractType,
  ratePercentage: Scalars['Float'],
  periodPostEmploymentMonths: Scalars['Float'],
  periodPostGraduationMonths: Scalars['Float'],
  thresholdType: ThresholdType,
  currency: Currency,
  dropoutInterest?: Maybe<Scalars['Float']>,
  fileUrl?: Maybe<Scalars['String']>,
  schoolId: Scalars['String'],
};

export type CreateEmploymentInput = {
  amountPerMonth?: Maybe<Scalars['Int']>,
  amountPerHour?: Maybe<Scalars['Int']>,
  studentId: Scalars['String'],
  companyName: Scalars['String'],
  startDate: Scalars['DateTime'],
  endDate?: Maybe<Scalars['DateTime']>,
  country?: Maybe<Country>,
  notes?: Maybe<Scalars['String']>,
  currency: Currency,
  firstPaymentDate: Scalars['DateTime'],
  salaryType: SalaryType,
};

export type CreateNoteInput = {
  text: Scalars['String'],
  studentId?: Maybe<Scalars['String']>,
};

export type CreateProgramInput = {
  name: Scalars['String'],
  description: Scalars['String'],
  schoolId: Scalars['String'],
};

export type CreateSchoolInput = {
  name: Scalars['String'],
  description: Scalars['String'],
  imageUrl: Scalars['String'],
  country: Country,
};

export type CreateStudentInput = {
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  phone: Scalars['String'],
  street: Scalars['String'],
  city: Scalars['String'],
  zip: Scalars['String'],
  country: Country,
  passportIdNumber?: Maybe<Scalars['String']>,
  cohortId?: Maybe<Scalars['String']>,
  contractId?: Maybe<Scalars['String']>,
  plaidPaymentEnabled?: Maybe<Scalars['Boolean']>,
  stripePaymentEnabled?: Maybe<Scalars['Boolean']>,
  manualPaymentEnabled?: Maybe<Scalars['Boolean']>,
};

export type CreateTrackInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  plaidPaymentEnabled?: Maybe<Scalars['String']>,
  manualPaymentEnabled?: Maybe<Scalars['Boolean']>,
};

export enum Currency {
  Brl = 'BRL',
  Chf = 'CHF',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Ils = 'ILS',
  Inr = 'INR',
  Rub = 'RUB',
  Usd = 'USD'
}


export type EditCohortInput = {
  id: Scalars['String'],
  name: Scalars['String'],
  startDate?: Maybe<Scalars['DateTime']>,
  endDate?: Maybe<Scalars['DateTime']>,
  programId: Scalars['String'],
  defaultContractId?: Maybe<Scalars['String']>,
  tag: Scalars['String'],
};

export type EditContractInput = {
  id: Scalars['ID'],
  cap?: Maybe<Scalars['Int']>,
  threshold: Scalars['Int'],
  upfrontPayment?: Maybe<Scalars['Int']>,
  dropoutPrincipal?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  type: ContractType,
  ratePercentage: Scalars['Float'],
  periodPostEmploymentMonths: Scalars['Float'],
  periodPostGraduationMonths: Scalars['Float'],
  thresholdType: ThresholdType,
  currency: Currency,
  dropoutInterest?: Maybe<Scalars['Float']>,
  fileUrl?: Maybe<Scalars['String']>,
  schoolId: Scalars['String'],
};

export type EditNoteInput = {
  id: Scalars['ID'],
  text: Scalars['String'],
};

export type EditProgramInput = {
  id: Scalars['String'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
};

export type EditSchoolInput = {
  id: Scalars['String'],
  name: Scalars['String'],
  description: Scalars['String'],
  imageUrl: Scalars['String'],
  country: Country,
};

export type EditStudentInput = {
  id: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
  country?: Maybe<Country>,
  passportIdNumber?: Maybe<Scalars['String']>,
  cohortId?: Maybe<Scalars['String']>,
  contractId?: Maybe<Scalars['String']>,
  plaidPaymentEnabled?: Maybe<Scalars['Boolean']>,
  stripePaymentEnabled?: Maybe<Scalars['Boolean']>,
  manualPaymentEnabled?: Maybe<Scalars['Boolean']>,
  stage: Stage,
  candidateStage?: Maybe<CandidateStage>,
  studentStage?: Maybe<StudentStage>,
  graduateStage?: Maybe<GraduateStage>,
  bankName?: Maybe<Scalars['String']>,
  bankBranch?: Maybe<Scalars['String']>,
  bankAccountNumber?: Maybe<Scalars['String']>,
  bankAuthorized?: Maybe<Scalars['Boolean']>,
  blender?: Maybe<Scalars['Boolean']>,
};

export type Email = {
   __typename?: 'Email',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  studentId: Scalars['String'],
  student: Student,
  actionId: Scalars['String'],
  action: Action,
  sendGridTemplateId: Scalars['String'],
  reminder: Scalars['Boolean'],
};

export type EmailTemplate = {
   __typename?: 'EmailTemplate',
  templateName: Scalars['String'],
  id: Scalars['String'],
  template_id: Scalars['String'],
  active: Scalars['Float'],
  name: Scalars['String'],
  html_content: Scalars['String'],
  plain_content: Scalars['String'],
  generate_plain_content: Scalars['Boolean'],
  subject: Scalars['String'],
  updated_at: Scalars['String'],
  editor: Scalars['String'],
  thumbnail_url: Scalars['String'],
  htmlContentWithDynamicData?: Maybe<Scalars['String']>,
};

export type Employment = {
   __typename?: 'Employment',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  amountPerMonth?: Maybe<Scalars['Int']>,
  amountPerHour?: Maybe<Scalars['Int']>,
  paymentDay: Scalars['Int'],
  companyName: Scalars['String'],
  startDate: Scalars['DateTime'],
  endDate?: Maybe<Scalars['DateTime']>,
  country?: Maybe<Country>,
  notes?: Maybe<Scalars['String']>,
  currency: Currency,
  studentId: Scalars['String'],
  student: Student,
  firstPaymentDate: Scalars['DateTime'],
  salaryType: SalaryType,
};

export enum GraduateStage {
  Graduate = 'GRADUATE',
  Employed = 'EMPLOYED',
  Unemployed = 'UNEMPLOYED'
}

export type Mutation = {
   __typename?: 'Mutation',
  createStudent: Student,
  editStudent: Student,
  deleteStudent: Scalars['Boolean'],
  sendEmail: Scalars['Boolean'],
  sendEmailBulk: Scalars['Boolean'],
  updateStageBulk: Array<Student>,
  createContract: Contract,
  editContract: Contract,
  deleteContract: Scalars['Boolean'],
  createProgram: Program,
  editProgram: Program,
  editProgramActionGroup: Program,
  createActionGroup: ActionGroup,
  deleteActionGroup: Scalars['Boolean'],
  updateActionProgressStatus: ActionProgress,
  createSchool: School,
  editSchool: School,
  editSchoolActionGroup: School,
  createEmployment: Employment,
  editEmployment: Employment,
  deleteEmployment: Scalars['Boolean'],
  confirmPendingPayment: Payment,
  chargeEmployedStudents: Scalars['Boolean'],
  createNote: Note,
  editNote: Note,
  deleteNote: Scalars['Boolean'],
  addSchoolAdminUser: Scalars['Boolean'],
  createTrack: Track,
  createCohort: Cohort,
  editCohort: Cohort,
  editCohortActionGroup: Cohort,
  deleteCohort: Scalars['Boolean'],
};


export type MutationCreateStudentArgs = {
  data: CreateStudentInput
};


export type MutationEditStudentArgs = {
  data: EditStudentInput
};


export type MutationDeleteStudentArgs = {
  studentId: Scalars['ID']
};


export type MutationSendEmailArgs = {
  data: SendEmailInput
};


export type MutationSendEmailBulkArgs = {
  data: Array<SendBulkEmailInput>
};


export type MutationUpdateStageBulkArgs = {
  stage: Stage,
  studentIds: Array<Scalars['ID']>
};


export type MutationCreateContractArgs = {
  data: CreateContractInput
};


export type MutationEditContractArgs = {
  data: EditContractInput
};


export type MutationDeleteContractArgs = {
  contractId: Scalars['ID']
};


export type MutationCreateProgramArgs = {
  data: CreateProgramInput
};


export type MutationEditProgramArgs = {
  data: EditProgramInput
};


export type MutationEditProgramActionGroupArgs = {
  defaultActionGroupId: Scalars['String'],
  programId: Scalars['String']
};


export type MutationCreateActionGroupArgs = {
  data: CreateActionGroupInput
};


export type MutationDeleteActionGroupArgs = {
  actionGroupId: Scalars['ID']
};


export type MutationUpdateActionProgressStatusArgs = {
  data: UpdateActionProgressInput
};


export type MutationCreateSchoolArgs = {
  data: CreateSchoolInput
};


export type MutationEditSchoolArgs = {
  data: EditSchoolInput
};


export type MutationEditSchoolActionGroupArgs = {
  defaultActionGroupId: Scalars['String'],
  schoolId: Scalars['String']
};


export type MutationCreateEmploymentArgs = {
  data: CreateEmploymentInput
};


export type MutationEditEmploymentArgs = {
  data: CreateEmploymentInput,
  employmentId: Scalars['ID']
};


export type MutationDeleteEmploymentArgs = {
  employmentId: Scalars['ID']
};


export type MutationConfirmPendingPaymentArgs = {
  confirm: Scalars['Boolean'],
  pendingPaymentId: Scalars['String']
};


export type MutationCreateNoteArgs = {
  data: CreateNoteInput
};


export type MutationEditNoteArgs = {
  data: EditNoteInput
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID']
};


export type MutationAddSchoolAdminUserArgs = {
  schoolId: Scalars['ID'],
  userId: Scalars['String']
};


export type MutationCreateTrackArgs = {
  data: CreateTrackInput
};


export type MutationCreateCohortArgs = {
  data: CreateCohortInput
};


export type MutationEditCohortArgs = {
  data: EditCohortInput
};


export type MutationEditCohortActionGroupArgs = {
  actionGroupId: Scalars['String'],
  cohortId: Scalars['String']
};


export type MutationDeleteCohortArgs = {
  cohortId: Scalars['String']
};

export type Note = {
   __typename?: 'Note',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  creatorId: Scalars['String'],
  text: Scalars['String'],
  studentId?: Maybe<Scalars['String']>,
  student?: Maybe<Student>,
  creator: User,
};

export enum PassportIdType {
  Passport = 'PASSPORT',
  Id = 'ID'
}

export type Payment = {
   __typename?: 'Payment',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  paymentNumber?: Maybe<Scalars['Int']>,
  amount: Scalars['Int'],
  currency: Currency,
  dueDate?: Maybe<Scalars['DateTime']>,
  paymentDate?: Maybe<Scalars['DateTime']>,
  status: PaymentStatus,
  paymentMethod: PaymentMethod,
  stripePaymentIntentId?: Maybe<Scalars['String']>,
  studentId: Scalars['String'],
  student: Student,
  schoolId?: Maybe<Scalars['String']>,
  school?: Maybe<School>,
  note?: Maybe<Scalars['String']>,
};

export enum PaymentMethod {
  Stripe = 'STRIPE',
  Plaid = 'PLAID',
  Other = 'OTHER'
}

export enum PaymentStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED'
}

export type PaymentSummary = {
   __typename?: 'PaymentSummary',
  paymentsPaid: Scalars['Int'],
  paymentsExpected: Scalars['Int'],
  balancePaid: Scalars['Int'],
  balanceExpected: Scalars['Int'],
  firstPayment?: Maybe<Scalars['DateTime']>,
  lastPayment?: Maybe<Scalars['DateTime']>,
  currency: Currency,
};

export type PendingPayment = {
   __typename?: 'PendingPayment',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  paymentNumber?: Maybe<Scalars['Int']>,
  amount: Scalars['Int'],
  currency: Currency,
  dueDate?: Maybe<Scalars['DateTime']>,
  paymentDate?: Maybe<Scalars['DateTime']>,
  status: PaymentStatus,
  paymentMethod: PaymentMethod,
  stripePaymentIntentId?: Maybe<Scalars['String']>,
  studentId: Scalars['String'],
  student: Student,
  schoolId?: Maybe<Scalars['String']>,
  school?: Maybe<School>,
  note?: Maybe<Scalars['String']>,
};

export type Program = {
   __typename?: 'Program',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  description: Scalars['String'],
  schoolId: Scalars['String'],
  school: School,
  defaultActionGroupId?: Maybe<Scalars['String']>,
  defaultActionGroup?: Maybe<ActionGroup>,
  cohorts: Array<Cohort>,
  actionGroup: ActionGroup,
};

export type Query = {
   __typename?: 'Query',
  student: Student,
  students: Array<Student>,
  searchStudents: Array<Student>,
  studentsCount: Scalars['Float'],
  sendgridTemplates: Array<SendGridTemplateResult>,
  emailTemplate: EmailTemplate,
  contract: Contract,
  contracts: Array<Contract>,
  contractsForSchool: Array<Contract>,
  program: Program,
  programs: Array<Program>,
  actionGroup: ActionGroup,
  actionGroups: Array<ActionGroup>,
  actionTypeDetails: Array<ActionTypeDetail>,
  school: School,
  schools: Array<School>,
  schoolsCount: Scalars['Float'],
  countriesCount: Scalars['Float'],
  payments: Array<Payment>,
  pendingPayments: Array<Payment>,
  pendingPaymentsCount: Scalars['Float'],
  setting: Setting,
  note: Note,
  notes: Array<Note>,
  users: Array<User>,
  schoolUsers: Array<User>,
  tracks: Array<Track>,
  cohort: Cohort,
  cohorts: Array<Cohort>,
  countries: Array<CountryFull>,
};


export type QueryStudentArgs = {
  id: Scalars['ID']
};


export type QueryStudentsArgs = {
  tag?: Maybe<Scalars['String']>,
  stage?: Maybe<Stage>,
  schoolId?: Maybe<Scalars['ID']>
};


export type QuerySearchStudentsArgs = {
  schoolId?: Maybe<Scalars['ID']>,
  search: Scalars['String']
};


export type QueryEmailTemplateArgs = {
  sendgridTemplateId: Scalars['String']
};


export type QueryContractArgs = {
  id: Scalars['ID']
};


export type QueryContractsForSchoolArgs = {
  schoolId: Scalars['ID']
};


export type QueryProgramArgs = {
  id: Scalars['ID']
};


export type QueryActionGroupArgs = {
  id: Scalars['ID']
};


export type QuerySchoolArgs = {
  id: Scalars['ID']
};


export type QueryNoteArgs = {
  id: Scalars['ID']
};


export type QueryNotesArgs = {
  studentId: Scalars['String']
};


export type QuerySchoolUsersArgs = {
  schoolId: Scalars['ID']
};


export type QueryCohortArgs = {
  id: Scalars['ID']
};

export enum SalaryType {
  Monthly = 'MONTHLY',
  Hourly = 'HOURLY'
}

export type School = {
   __typename?: 'School',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  description: Scalars['String'],
  imageUrl: Scalars['String'],
  country: Country,
  contracts?: Maybe<Array<Contract>>,
  defaultActionGroupId?: Maybe<Scalars['String']>,
  defaultActionGroup?: Maybe<ActionGroup>,
  programs: Array<Program>,
  studentCount: SchoolCount,
};

export type SchoolCount = {
   __typename?: 'SchoolCount',
  total: Scalars['Int'],
  graduated: Scalars['Int'],
  employed: Scalars['Int'],
  unemployed: Scalars['Int'],
};

export type SendBulkEmailInput = {
  studentId: Scalars['String'],
  type: ActionType,
};

export type SendEmailInput = {
  id: Scalars['String'],
  actionId: Scalars['String'],
  sendgridTemplateId: Scalars['String'],
  reminder: Scalars['Boolean'],
};

export type SendGridTemplateResult = {
   __typename?: 'SendGridTemplateResult',
  id: Scalars['String'],
  name: Scalars['String'],
};

export type Setting = {
   __typename?: 'Setting',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  stripeLastGlobalCharge?: Maybe<Scalars['DateTime']>,
};

export enum Stage {
  Candidate = 'CANDIDATE',
  Onboarding = 'ONBOARDING',
  Student = 'STUDENT',
  Graduate = 'GRADUATE'
}

export type Student = {
   __typename?: 'Student',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  stage: Stage,
  candidateStage?: Maybe<CandidateStage>,
  studentStage?: Maybe<StudentStage>,
  graduateStage?: Maybe<GraduateStage>,
  creatorId?: Maybe<Scalars['String']>,
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  street?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Country>,
  zip?: Maybe<Scalars['String']>,
  passportIdNumber?: Maybe<Scalars['String']>,
  passportIdType?: Maybe<PassportIdType>,
  cohorts?: Maybe<Array<Cohort>>,
  contractId?: Maybe<Scalars['String']>,
  contract?: Maybe<Contract>,
  contractSigned?: Maybe<Scalars['Boolean']>,
  employments?: Maybe<Array<Employment>>,
  payments?: Maybe<Array<Payment>>,
  pendingPayments?: Maybe<Array<PendingPayment>>,
  notes?: Maybe<Array<Note>>,
  idFrontImageUrl?: Maybe<Scalars['String']>,
  idBackImageUrl?: Maybe<Scalars['String']>,
  stubImageUrl?: Maybe<Scalars['String']>,
  checkImageUrl?: Maybe<Scalars['String']>,
  signatureImageUrl?: Maybe<Scalars['String']>,
  agreementVideoUrl?: Maybe<Scalars['String']>,
  plaidPaymentEnabled?: Maybe<Scalars['Boolean']>,
  stripePaymentEnabled?: Maybe<Scalars['Boolean']>,
  manualPaymentEnabled?: Maybe<Scalars['Boolean']>,
  stripeCustomerId?: Maybe<Scalars['String']>,
  stripePaymentMethodId?: Maybe<Scalars['String']>,
  bankName?: Maybe<Scalars['String']>,
  bankBranch?: Maybe<Scalars['String']>,
  bankAccountNumber?: Maybe<Scalars['String']>,
  bankAuthorized?: Maybe<Scalars['Boolean']>,
  blender?: Maybe<Scalars['Boolean']>,
  deleted?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  address?: Maybe<Scalars['String']>,
  currentEmployment?: Maybe<Employment>,
  paymentSummary?: Maybe<PaymentSummary>,
  emails: Array<Email>,
  emailTemplate: EmailTemplate,
  substage?: Maybe<Scalars['String']>,
  driveFile?: Maybe<StudentFile>,
  progresses: Array<ActionProgress>,
};


export type StudentEmailTemplateArgs = {
  sendgridTemplateId: Scalars['String']
};


export type StudentDriveFileArgs = {
  fileType: Scalars['String']
};

export type StudentFile = {
   __typename?: 'StudentFile',
  id: Scalars['String'],
};

export enum StudentStage {
  Studying = 'STUDYING',
  Completed = 'COMPLETED',
  DropOut = 'DROP_OUT'
}

export enum ThresholdType {
  Monthly = 'MONTHLY',
  Annually = 'ANNUALLY'
}

export type Track = {
   __typename?: 'Track',
  id: Scalars['ID'],
  version?: Maybe<Scalars['Int']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  plaidPaymentEnabled?: Maybe<Scalars['Boolean']>,
  stripePaymentEnabled?: Maybe<Scalars['Boolean']>,
  manualPaymentEnabled?: Maybe<Scalars['Boolean']>,
  welcomeEmailSendgridTemplateId?: Maybe<Scalars['String']>,
  acceptedEmailSendgridTemplateId?: Maybe<Scalars['String']>,
  rejectedEmailSendgridTemplateId?: Maybe<Scalars['String']>,
};

export type UpdateActionProgressInput = {
  studentId: Scalars['ID'],
  actionId: Scalars['ID'],
  status: ActionProgressStatus,
};

export type User = {
   __typename?: 'User',
  user_id: Scalars['ID'],
  email: Scalars['String'],
  email_verified: Scalars['Boolean'],
  name: Scalars['String'],
  nickname?: Maybe<Scalars['String']>,
  picture?: Maybe<Scalars['String']>,
  last_login?: Maybe<Scalars['String']>,
  last_ip?: Maybe<Scalars['String']>,
  logins_count: Scalars['Float'],
  created_at: Scalars['String'],
  updated_at: Scalars['String'],
};

export type ActionGroupFragment = (
  { __typename?: 'ActionGroup' }
  & Pick<ActionGroup, 'id' | 'name'>
);

export type ActionGroupsQueryVariables = {};


export type ActionGroupsQuery = (
  { __typename?: 'Query' }
  & { actionGroups: Array<(
    { __typename?: 'ActionGroup' }
    & ActionGroupFragment
  )> }
);

export type ActionFragment = (
  { __typename?: 'Action' }
  & Pick<Action, 'id' | 'type' | 'actionGroupId' | 'sendgridTemplateId' | 'reminderSendgridTemplateId' | 'enabled' | 'order'>
);

export type ActionGroupQueryVariables = {
  id: Scalars['ID']
};


export type ActionGroupQuery = (
  { __typename?: 'Query' }
  & { actionGroup: (
    { __typename?: 'ActionGroup' }
    & { actions: Array<(
      { __typename?: 'Action' }
      & ActionFragment
    )> }
    & ActionGroupFragment
  ) }
);

export type CreateActionGroupMutationVariables = {
  data: CreateActionGroupInput
};


export type CreateActionGroupMutation = (
  { __typename?: 'Mutation' }
  & { createActionGroup: (
    { __typename?: 'ActionGroup' }
    & ActionGroupFragment
  ) }
);

export type DeleteActionGroupMutationVariables = {
  actionGroupId: Scalars['ID']
};


export type DeleteActionGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActionGroup'>
);

export type SendgridTemplatesQueryVariables = {};


export type SendgridTemplatesQuery = (
  { __typename?: 'Query' }
  & { sendgridTemplates: Array<(
    { __typename?: 'SendGridTemplateResult' }
    & Pick<SendGridTemplateResult, 'id' | 'name'>
  )> }
);

export type GetCohortCohortFragment = (
  { __typename?: 'Cohort' }
  & Pick<Cohort, 'id' | 'name' | 'programId' | 'startDate' | 'endDate' | 'tag' | 'defaultContractId'>
  & { students: Array<(
    { __typename?: 'Student' }
    & { progresses: Array<(
      { __typename?: 'ActionProgress' }
      & Pick<ActionProgress, 'id' | 'status' | 'actionId'>
    )> }
    & StudentFragment
  )>, defaultContract: Maybe<(
    { __typename?: 'Contract' }
    & ContractFragment
  )>, program: (
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'schoolId' | 'name'>
    & { school: (
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    ) }
  ) }
);

export type GetCohortQueryVariables = {
  cohortId: Scalars['ID'],
  includeProgress: Scalars['Boolean']
};


export type GetCohortQuery = (
  { __typename?: 'Query' }
  & { cohort: (
    { __typename?: 'Cohort' }
    & GetCohortCohortFragment
  ) }
);

export type DeleteCohortMutationVariables = {
  cohortId: Scalars['String']
};


export type DeleteCohortMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCohort'>
);

export type CohortActionGroupQueryVariables = {
  cohortId: Scalars['ID']
};


export type CohortActionGroupQuery = (
  { __typename?: 'Query' }
  & { cohort: (
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'actionGroupId'>
    & { actionGroup: Maybe<(
      { __typename?: 'ActionGroup' }
      & Pick<ActionGroup, 'name'>
      & { actions: Array<(
        { __typename?: 'Action' }
        & ActionFragment
      )> }
    )> }
  ) }
);

export type EditCohortActionGroupMutationVariables = {
  actionGroupId: Scalars['String'],
  cohortId: Scalars['String']
};


export type EditCohortActionGroupMutation = (
  { __typename?: 'Mutation' }
  & { editCohortActionGroup: (
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'actionGroupId'>
  ) }
);

export type EditCohortMutationVariables = {
  data: EditCohortInput
};


export type EditCohortMutation = (
  { __typename?: 'Mutation' }
  & { editCohort: (
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'name' | 'startDate' | 'endDate' | 'programId'>
    & { defaultContract: Maybe<(
      { __typename?: 'Contract' }
      & Pick<Contract, 'id' | 'name'>
    )> }
  ) }
);

export type PendingPaymentsCountQueryVariables = {};


export type PendingPaymentsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pendingPaymentsCount'>
);

export type SearchStudentsQueryVariables = {
  search: Scalars['String']
};


export type SearchStudentsQuery = (
  { __typename?: 'Query' }
  & { searchStudents: Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'firstName' | 'lastName' | 'email'>
    & { cohorts: Maybe<Array<(
      { __typename?: 'Cohort' }
      & Pick<Cohort, 'id' | 'name'>
    )>> }
  )> }
);

export type GetContractContractFragment = (
  { __typename?: 'Contract' }
  & { students: Array<(
    { __typename?: 'Student' }
    & StudentFragment
  )>, cohorts: Array<(
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'name'>
  )> }
  & ContractFragment
);

export type GetContractQueryVariables = {
  contractId: Scalars['ID']
};


export type GetContractQuery = (
  { __typename?: 'Query' }
  & { contract: (
    { __typename?: 'Contract' }
    & GetContractContractFragment
  ) }
);

export type DeleteContractMutationVariables = {
  contractId: Scalars['ID']
};


export type DeleteContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContract'>
);

export type CreateContractMutationVariables = {
  data: CreateContractInput
};


export type CreateContractMutation = (
  { __typename?: 'Mutation' }
  & { createContract: (
    { __typename?: 'Contract' }
    & ContractFragment
  ) }
);

export type EditContractMutationVariables = {
  data: EditContractInput
};


export type EditContractMutation = (
  { __typename?: 'Mutation' }
  & { editContract: (
    { __typename?: 'Contract' }
    & ContractFragment
  ) }
);

export type GetContractsContractFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'id' | 'name' | 'description' | 'type' | 'ratePercentage' | 'periodPostEmploymentMonths' | 'periodPostGraduationMonths' | 'cap' | 'threshold' | 'thresholdType' | 'upfrontPayment' | 'currency'>
);

export type GetContractsQueryVariables = {};


export type GetContractsQuery = (
  { __typename?: 'Query' }
  & { contracts: Array<(
    { __typename?: 'Contract' }
    & GetContractsContractFragment
  )> }
);

export type GetSchoolContractsFullQueryVariables = {
  schoolId: Scalars['ID']
};


export type GetSchoolContractsFullQuery = (
  { __typename?: 'Query' }
  & { contractsForSchool: Array<(
    { __typename?: 'Contract' }
    & GetContractsContractFragment
  )> }
);

export type PaymentFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'id' | 'amount' | 'currency' | 'paymentDate' | 'paymentMethod' | 'stripePaymentIntentId' | 'createdAt' | 'note'>
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'name'>
  ) }
);

export type PaymentsQueryVariables = {};


export type PaymentsQuery = (
  { __typename?: 'Query' }
  & { payments: Array<(
    { __typename?: 'Payment' }
    & PaymentFragment
  )> }
);

export type PendingPaymentsQueryVariables = {};


export type PendingPaymentsQuery = (
  { __typename?: 'Query' }
  & { pendingPayments: Array<(
    { __typename?: 'Payment' }
    & PaymentFragment
  )> }
);

export type ChargeEmployedStudentsMutationVariables = {};


export type ChargeEmployedStudentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'chargeEmployedStudents'>
);

export type ConfirmPendingPaymentMutationVariables = {
  id: Scalars['String'],
  confirm: Scalars['Boolean']
};


export type ConfirmPendingPaymentMutation = (
  { __typename?: 'Mutation' }
  & { confirmPendingPayment: (
    { __typename?: 'Payment' }
    & PaymentFragment
  ) }
);

export type CreateCohortFragment = (
  { __typename?: 'Cohort' }
  & Pick<Cohort, 'id' | 'name' | 'startDate' | 'endDate' | 'tag'>
  & { onboardingTrack: Maybe<(
    { __typename?: 'Track' }
    & Pick<Track, 'id' | 'name'>
  )> }
);

export type CreateCohortMutationVariables = {
  data: CreateCohortInput
};


export type CreateCohortMutation = (
  { __typename?: 'Mutation' }
  & { createCohort: (
    { __typename?: 'Cohort' }
    & CreateCohortFragment
  ) }
);

export type GetSchoolContractsQueryVariables = {
  schoolId: Scalars['ID']
};


export type GetSchoolContractsQuery = (
  { __typename?: 'Query' }
  & { contractsForSchool: Array<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'name'>
  )> }
);

export type EditProgramMutationVariables = {
  data: EditProgramInput
};


export type EditProgramMutation = (
  { __typename?: 'Mutation' }
  & { editProgram: (
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name' | 'description'>
  ) }
);

export type GetProgramFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'id' | 'name' | 'description' | 'schoolId'>
  & { cohorts: Array<(
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'name' | 'startDate' | 'endDate'>
  )> }
);

export type GetProgramQueryVariables = {
  programId: Scalars['ID']
};


export type GetProgramQuery = (
  { __typename?: 'Query' }
  & { program: (
    { __typename?: 'Program' }
    & { school: (
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    ) }
    & GetProgramFragment
  ) }
);

export type ProgramActionGroupQueryVariables = {
  programId: Scalars['ID']
};


export type ProgramActionGroupQuery = (
  { __typename?: 'Query' }
  & { program: (
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'defaultActionGroupId'>
    & { defaultActionGroup: Maybe<(
      { __typename?: 'ActionGroup' }
      & Pick<ActionGroup, 'name'>
      & { actions: Array<(
        { __typename?: 'Action' }
        & ActionFragment
      )> }
    )> }
  ) }
);

export type EditProgramActionGroupMutationVariables = {
  defaultActionGroupId: Scalars['String'],
  programId: Scalars['String']
};


export type EditProgramActionGroupMutation = (
  { __typename?: 'Mutation' }
  & { editProgramActionGroup: (
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'defaultActionGroupId'>
  ) }
);

export type CreateProgramFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'id' | 'name' | 'description'>
);

export type CreateProgramMutationVariables = {
  data: CreateProgramInput
};


export type CreateProgramMutation = (
  { __typename?: 'Mutation' }
  & { createProgram: (
    { __typename?: 'Program' }
    & CreateProgramFragment
  ) }
);

export type CreateSchoolFragment = (
  { __typename?: 'School' }
  & Pick<School, 'id' | 'name' | 'description' | 'imageUrl'>
);

export type CreateSchoolMutationVariables = {
  data: CreateSchoolInput
};


export type CreateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { createSchool: (
    { __typename?: 'School' }
    & SchoolFragment
  ) }
);

export type EditSchoolFragment = (
  { __typename?: 'School' }
  & Pick<School, 'id' | 'name' | 'description' | 'imageUrl' | 'country'>
);

export type EditSchoolMutationVariables = {
  data: EditSchoolInput
};


export type EditSchoolMutation = (
  { __typename?: 'Mutation' }
  & { editSchool: (
    { __typename?: 'School' }
    & SchoolFragment
  ) }
);

export type GetSchoolSchoolFragment = (
  { __typename?: 'School' }
  & Pick<School, 'id' | 'name' | 'description' | 'imageUrl' | 'country'>
  & { studentCount: (
    { __typename?: 'SchoolCount' }
    & Pick<SchoolCount, 'total' | 'unemployed' | 'employed'>
  ), programs: Array<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name' | 'description' | 'schoolId'>
    & { cohorts: Array<(
      { __typename?: 'Cohort' }
      & Pick<Cohort, 'id' | 'name' | 'studentsCount' | 'startDate' | 'endDate' | 'programId' | 'defaultContractId' | 'tag'>
    )> }
  )> }
);

export type GetSchoolQueryVariables = {
  schoolId: Scalars['ID']
};


export type GetSchoolQuery = (
  { __typename?: 'Query' }
  & { school: (
    { __typename?: 'School' }
    & GetSchoolSchoolFragment
  ) }
);

export type SchoolAdminsQueryVariables = {
  schoolId: Scalars['ID']
};


export type SchoolAdminsQuery = (
  { __typename?: 'Query' }
  & { schoolUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'user_id' | 'name' | 'nickname' | 'email' | 'picture'>
  )> }
);

export type SchoolActionGroupQueryVariables = {
  schoolId: Scalars['ID']
};


export type SchoolActionGroupQuery = (
  { __typename?: 'Query' }
  & { school: (
    { __typename?: 'School' }
    & Pick<School, 'id' | 'defaultActionGroupId'>
    & { defaultActionGroup: Maybe<(
      { __typename?: 'ActionGroup' }
      & Pick<ActionGroup, 'name'>
      & { actions: Array<(
        { __typename?: 'Action' }
        & ActionFragment
      )> }
    )> }
  ) }
);

export type GetUsersQueryVariables = {};


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'user_id' | 'name' | 'email'>
  )> }
);

export type AddSchoolAdminUserMutationVariables = {
  userId: Scalars['String'],
  schoolId: Scalars['ID']
};


export type AddSchoolAdminUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSchoolAdminUser'>
);

export type EditSchoolActionGroupMutationVariables = {
  defaultActionGroupId: Scalars['String'],
  schoolId: Scalars['String']
};


export type EditSchoolActionGroupMutation = (
  { __typename?: 'Mutation' }
  & { editSchoolActionGroup: (
    { __typename?: 'School' }
    & Pick<School, 'id' | 'defaultActionGroupId'>
  ) }
);

export type SchoolFragment = (
  { __typename?: 'School' }
  & Pick<School, 'id' | 'name' | 'description' | 'imageUrl' | 'country'>
  & { studentCount: (
    { __typename?: 'SchoolCount' }
    & Pick<SchoolCount, 'total' | 'unemployed' | 'employed'>
  ) }
);

export type GetSchoolsQueryVariables = {};


export type GetSchoolsQuery = (
  { __typename?: 'Query' }
  & { schools: Array<(
    { __typename?: 'School' }
    & SchoolFragment
  )> }
);

export type GetCountsQueryVariables = {};


export type GetCountsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'schoolsCount' | 'studentsCount' | 'countriesCount'>
);

export type CreateStudentFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id' | 'firstName' | 'lastName' | 'email' | 'phone'>
);

export type CreateStudentMutationVariables = {
  data: CreateStudentInput
};


export type CreateStudentMutation = (
  { __typename?: 'Mutation' }
  & { createStudent: (
    { __typename?: 'Student' }
    & StudentFragment
  ) }
);

export type CohortFragment = (
  { __typename?: 'Cohort' }
  & Pick<Cohort, 'id' | 'name'>
  & { program: (
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name'>
    & { school: (
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
      & { contracts: Maybe<Array<(
        { __typename?: 'Contract' }
        & Pick<Contract, 'id' | 'name'>
      )>> }
    ) }
  ) }
);

export type GetCohortsQueryVariables = {};


export type GetCohortsQuery = (
  { __typename?: 'Query' }
  & { cohorts: Array<(
    { __typename?: 'Cohort' }
    & CohortFragment
  )> }
);

export type EditStudentMutationVariables = {
  data: EditStudentInput
};


export type EditStudentMutation = (
  { __typename?: 'Mutation' }
  & { editStudent: (
    { __typename?: 'Student' }
    & GetStudentStudentFragment
  ) }
);

export type CreateEmploymentMutationVariables = {
  data: CreateEmploymentInput
};


export type CreateEmploymentMutation = (
  { __typename?: 'Mutation' }
  & { createEmployment: (
    { __typename?: 'Employment' }
    & EmploymentFragment
  ) }
);

export type EditEmploymentMutationVariables = {
  employmentId: Scalars['ID'],
  data: CreateEmploymentInput
};


export type EditEmploymentMutation = (
  { __typename?: 'Mutation' }
  & { editEmployment: (
    { __typename?: 'Employment' }
    & EmploymentFragment
  ) }
);

export type DeleteEmploymentMutationVariables = {
  employmentId: Scalars['ID']
};


export type DeleteEmploymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEmployment'>
);

export type NoteFragment = (
  { __typename?: 'Note' }
  & Pick<Note, 'id' | 'version' | 'createdAt' | 'updatedAt' | 'creatorId' | 'text' | 'studentId'>
);

export type CreateNoteMutationVariables = {
  data: CreateNoteInput
};


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote: (
    { __typename?: 'Note' }
    & NoteFragment
  ) }
);

export type EditNoteMutationVariables = {
  data: EditNoteInput
};


export type EditNoteMutation = (
  { __typename?: 'Mutation' }
  & { editNote: (
    { __typename?: 'Note' }
    & NoteFragment
  ) }
);

export type DeleteNoteMutationVariables = {
  id: Scalars['ID']
};


export type DeleteNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNote'>
);

export type GetStudentQueryVariables = {
  studentId: Scalars['ID']
};


export type GetStudentQuery = (
  { __typename?: 'Query' }
  & { student: (
    { __typename?: 'Student' }
    & GetStudentStudentFragment
  ) }
);

export type DeleteStudentMutationVariables = {
  studentId: Scalars['ID']
};


export type DeleteStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStudent'>
);

export type SentEmailFragment = (
  { __typename?: 'Email' }
  & Pick<Email, 'id' | 'createdAt' | 'reminder'>
);

export type ActionProgressFragment = (
  { __typename?: 'ActionProgress' }
  & Pick<ActionProgress, 'id' | 'status'>
);

export type StudentActionProgressQueryVariables = {
  cohortId: Scalars['ID'],
  studentId: Scalars['ID']
};


export type StudentActionProgressQuery = (
  { __typename?: 'Query' }
  & { cohort: (
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id'>
    & { actionGroup: Maybe<(
      { __typename?: 'ActionGroup' }
      & Pick<ActionGroup, 'name'>
      & { actions: Array<(
        { __typename?: 'Action' }
        & { progress: Maybe<(
          { __typename?: 'ActionProgress' }
          & ActionProgressFragment
        )>, sentEmails: Array<(
          { __typename?: 'Email' }
          & SentEmailFragment
        )> }
        & ActionFragment
      )> }
    )> }
  ) }
);

export type EmailTemplateQueryVariables = {
  studentId: Scalars['ID'],
  sendgridTemplateId: Scalars['String']
};


export type EmailTemplateQuery = (
  { __typename?: 'Query' }
  & { student: (
    { __typename?: 'Student' }
    & Pick<Student, 'id'>
    & { emailTemplate: (
      { __typename?: 'EmailTemplate' }
      & Pick<EmailTemplate, 'id' | 'htmlContentWithDynamicData' | 'plain_content' | 'subject'>
    ) }
  ) }
);

export type SendEmailMutationVariables = {
  data: SendEmailInput
};


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendEmail'>
);

export type UpdateActionProgressStatusMutationVariables = {
  data: UpdateActionProgressInput
};


export type UpdateActionProgressStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateActionProgressStatus: (
    { __typename?: 'ActionProgress' }
    & Pick<ActionProgress, 'id' | 'studentId' | 'actionId' | 'status'>
  ) }
);

export type ActionTypeDetailsQueryVariables = {};


export type ActionTypeDetailsQuery = (
  { __typename?: 'Query' }
  & { actionTypeDetails: Array<(
    { __typename?: 'ActionTypeDetail' }
    & Pick<ActionTypeDetail, 'label' | 'stage' | 'type'>
  )> }
);

export type StudentFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id' | 'name' | 'createdAt' | 'email' | 'stage' | 'substage' | 'stripeCustomerId' | 'stripePaymentMethodId' | 'stripePaymentEnabled'>
  & { currentEmployment: Maybe<(
    { __typename?: 'Employment' }
    & EmploymentFragment
  )>, contract: Maybe<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'name' | 'threshold' | 'currency'>
  )>, cohorts: Maybe<Array<(
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'name'>
    & { program: (
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name'>
      & { school: (
        { __typename?: 'School' }
        & Pick<School, 'id' | 'name'>
      ) }
    ) }
  )>> }
);

export type GetStudentsQueryVariables = {
  schoolId?: Maybe<Scalars['ID']>,
  stage?: Maybe<Stage>
};


export type GetStudentsQuery = (
  { __typename?: 'Query' }
  & { students: Array<(
    { __typename?: 'Student' }
    & StudentFragment
  )> }
);

export type CohortsQueryVariables = {};


export type CohortsQuery = (
  { __typename?: 'Query' }
  & { cohorts: Array<(
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'name'>
    & { program: (
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name'>
      & { school: (
        { __typename?: 'School' }
        & Pick<School, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type SendEmailBulkMutationVariables = {
  data: Array<SendBulkEmailInput>
};


export type SendEmailBulkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendEmailBulk'>
);

export type UpdateStageBulkMutationVariables = {
  studentIds: Array<Scalars['ID']>,
  stage: Stage
};


export type UpdateStageBulkMutation = (
  { __typename?: 'Mutation' }
  & { updateStageBulk: Array<(
    { __typename?: 'Student' }
    & Pick<Student, 'id' | 'stage'>
  )> }
);

export type StudentsByTagQueryVariables = {
  schoolId?: Maybe<Scalars['ID']>,
  tag?: Maybe<Scalars['String']>
};


export type StudentsByTagQuery = (
  { __typename?: 'Query' }
  & { students: Array<(
    { __typename?: 'Student' }
    & StudentFragment
  )> }
);

export type ContractFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'id' | 'description' | 'name' | 'threshold' | 'thresholdType' | 'cap' | 'periodPostEmploymentMonths' | 'periodPostGraduationMonths' | 'ratePercentage' | 'type' | 'upfrontPayment' | 'currency' | 'dropoutInterest' | 'dropoutPrincipal' | 'fileUrl' | 'schoolId' | 'deleted'>
);

export type EmploymentFragment = (
  { __typename?: 'Employment' }
  & Pick<Employment, 'id' | 'companyName' | 'startDate' | 'endDate' | 'amountPerMonth' | 'amountPerHour' | 'currency' | 'salaryType' | 'paymentDay' | 'firstPaymentDate' | 'country' | 'notes' | 'studentId'>
);

export type GetStudentStudentFragment = (
  { __typename?: 'Student' }
  & Pick<Student, 'id' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt' | 'email' | 'phone' | 'address' | 'street' | 'city' | 'country' | 'zip' | 'stage' | 'candidateStage' | 'studentStage' | 'graduateStage' | 'passportIdNumber' | 'idFrontImageUrl' | 'idBackImageUrl' | 'stubImageUrl' | 'checkImageUrl' | 'signatureImageUrl' | 'agreementVideoUrl' | 'plaidPaymentEnabled' | 'stripePaymentEnabled' | 'manualPaymentEnabled' | 'stripeCustomerId' | 'stripePaymentMethodId' | 'bankName' | 'bankBranch' | 'bankAccountNumber' | 'bankAuthorized' | 'blender' | 'contractId' | 'deleted'>
  & { driveFolder: Maybe<(
    { __typename?: 'StudentFile' }
    & Pick<StudentFile, 'id'>
  )>, driveContract: Maybe<(
    { __typename?: 'StudentFile' }
    & Pick<StudentFile, 'id'>
  )>, cohorts: Maybe<Array<(
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id' | 'name'>
    & { program: (
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name'>
      & { school: (
        { __typename?: 'School' }
        & Pick<School, 'id' | 'name'>
      ) }
    ) }
  )>>, contract: Maybe<(
    { __typename?: 'Contract' }
    & ContractFragment
  )>, employments: Maybe<Array<(
    { __typename?: 'Employment' }
    & EmploymentFragment
  )>>, currentEmployment: Maybe<(
    { __typename?: 'Employment' }
    & EmploymentFragment
  )>, payments: Maybe<Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'amount' | 'currency' | 'paymentDate' | 'paymentMethod'>
  )>>, paymentSummary: Maybe<(
    { __typename?: 'PaymentSummary' }
    & Pick<PaymentSummary, 'paymentsPaid' | 'paymentsExpected' | 'balancePaid' | 'balanceExpected' | 'firstPayment' | 'lastPayment' | 'currency'>
  )>, notes: Maybe<Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'text' | 'createdAt'>
    & { creator: (
      { __typename?: 'User' }
      & Pick<User, 'user_id' | 'name' | 'picture'>
    ) }
  )>> }
);

export type CountryFragment = (
  { __typename?: 'CountryFull' }
  & Pick<CountryFull, 'name' | 'enum'>
);

export type CountriesQueryVariables = {};


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'CountryFull' }
    & CountryFragment
  )> }
);

export const ActionGroupFragmentDoc = gql`
    fragment ActionGroup on ActionGroup {
  id
  name
}
    `;
export const ActionFragmentDoc = gql`
    fragment Action on Action {
  id
  type
  actionGroupId
  sendgridTemplateId
  reminderSendgridTemplateId
  enabled
  order
}
    `;
export const EmploymentFragmentDoc = gql`
    fragment Employment on Employment {
  id
  companyName
  startDate
  endDate
  amountPerMonth
  amountPerHour
  currency
  salaryType
  paymentDay
  firstPaymentDate
  country
  notes
  studentId
}
    `;
export const StudentFragmentDoc = gql`
    fragment Student on Student {
  id
  name
  createdAt
  email
  stage
  substage
  stripeCustomerId
  stripePaymentMethodId
  stripePaymentEnabled
  currentEmployment {
    ...Employment
  }
  contract {
    id
    name
    threshold
    currency
  }
  cohorts {
    id
    name
    program {
      id
      name
      school {
        id
        name
      }
    }
  }
}
    ${EmploymentFragmentDoc}`;
export const ContractFragmentDoc = gql`
    fragment Contract on Contract {
  id
  description
  name
  threshold
  thresholdType
  cap
  periodPostEmploymentMonths
  periodPostGraduationMonths
  ratePercentage
  type
  upfrontPayment
  currency
  dropoutInterest
  dropoutPrincipal
  fileUrl
  schoolId
  deleted
}
    `;
export const GetCohortCohortFragmentDoc = gql`
    fragment GetCohortCohort on Cohort {
  id
  name
  programId
  startDate
  endDate
  tag
  students {
    ...Student
    progresses @include(if: $includeProgress) {
      id
      status
      actionId
    }
  }
  defaultContractId
  defaultContract {
    ...Contract
  }
  program {
    id
    schoolId
    name
    school {
      id
      name
    }
  }
}
    ${StudentFragmentDoc}
${ContractFragmentDoc}`;
export const GetContractContractFragmentDoc = gql`
    fragment GetContractContract on Contract {
  ...Contract
  students {
    ...Student
  }
  cohorts {
    id
    name
  }
}
    ${ContractFragmentDoc}
${StudentFragmentDoc}`;
export const GetContractsContractFragmentDoc = gql`
    fragment GetContractsContract on Contract {
  id
  name
  description
  type
  ratePercentage
  periodPostEmploymentMonths
  periodPostGraduationMonths
  cap
  threshold
  thresholdType
  upfrontPayment
  currency
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on Payment {
  id
  amount
  currency
  paymentDate
  paymentMethod
  stripePaymentIntentId
  createdAt
  note
  student {
    id
    name
  }
}
    `;
export const CreateCohortFragmentDoc = gql`
    fragment CreateCohort on Cohort {
  id
  name
  startDate
  endDate
  tag
  onboardingTrack {
    id
    name
  }
}
    `;
export const GetProgramFragmentDoc = gql`
    fragment GetProgram on Program {
  id
  name
  description
  schoolId
  cohorts {
    id
    name
    startDate
    endDate
  }
}
    `;
export const CreateProgramFragmentDoc = gql`
    fragment CreateProgram on Program {
  id
  name
  description
}
    `;
export const CreateSchoolFragmentDoc = gql`
    fragment CreateSchool on School {
  id
  name
  description
  imageUrl
}
    `;
export const EditSchoolFragmentDoc = gql`
    fragment EditSchool on School {
  id
  name
  description
  imageUrl
  country
}
    `;
export const GetSchoolSchoolFragmentDoc = gql`
    fragment GetSchoolSchool on School {
  id
  name
  description
  imageUrl
  country
  studentCount {
    total
    unemployed
    employed
  }
  programs {
    id
    name
    description
    schoolId
    cohorts {
      id
      name
      studentsCount
      startDate
      endDate
      programId
      defaultContractId
      tag
    }
  }
}
    `;
export const SchoolFragmentDoc = gql`
    fragment School on School {
  id
  name
  description
  imageUrl
  country
  studentCount {
    total
    unemployed
    employed
  }
}
    `;
export const CreateStudentFragmentDoc = gql`
    fragment CreateStudent on Student {
  id
  firstName
  lastName
  email
  phone
}
    `;
export const CohortFragmentDoc = gql`
    fragment Cohort on Cohort {
  id
  name
  program {
    id
    name
    school {
      id
      name
      contracts {
        id
        name
      }
    }
  }
}
    `;
export const NoteFragmentDoc = gql`
    fragment Note on Note {
  id
  version
  createdAt
  updatedAt
  creatorId
  text
  studentId
}
    `;
export const SentEmailFragmentDoc = gql`
    fragment SentEmail on Email {
  id
  createdAt
  reminder
}
    `;
export const ActionProgressFragmentDoc = gql`
    fragment ActionProgress on ActionProgress {
  id
  status
}
    `;
export const GetStudentStudentFragmentDoc = gql`
    fragment GetStudentStudent on Student {
  id
  firstName
  lastName
  createdAt
  updatedAt
  email
  phone
  address
  street
  city
  country
  zip
  stage
  candidateStage
  studentStage
  graduateStage
  passportIdNumber
  idFrontImageUrl
  idBackImageUrl
  stubImageUrl
  checkImageUrl
  signatureImageUrl
  agreementVideoUrl
  plaidPaymentEnabled
  stripePaymentEnabled
  manualPaymentEnabled
  stripeCustomerId
  stripePaymentMethodId
  bankName
  bankBranch
  bankAccountNumber
  bankAuthorized
  blender
  contractId
  driveFolder: driveFile(fileType: "folder") {
    id
  }
  driveContract: driveFile(fileType: "contract") {
    id
  }
  cohorts {
    id
    name
    program {
      id
      name
      school {
        id
        name
      }
    }
  }
  contract {
    ...Contract
  }
  employments {
    ...Employment
  }
  currentEmployment {
    ...Employment
  }
  payments {
    id
    amount
    currency
    paymentDate
    paymentMethod
  }
  paymentSummary {
    paymentsPaid
    paymentsExpected
    balancePaid
    balanceExpected
    firstPayment
    lastPayment
    currency
  }
  notes {
    id
    text
    createdAt
    creator {
      user_id
      name
      picture
    }
  }
  deleted
}
    ${ContractFragmentDoc}
${EmploymentFragmentDoc}`;
export const CountryFragmentDoc = gql`
    fragment Country on CountryFull {
  name
  enum
}
    `;
export const ActionGroupsDocument = gql`
    query ActionGroups {
  actionGroups {
    ...ActionGroup
  }
}
    ${ActionGroupFragmentDoc}`;

/**
 * __useActionGroupsQuery__
 *
 * To run a query within a React component, call `useActionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActionGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActionGroupsQuery, ActionGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<ActionGroupsQuery, ActionGroupsQueryVariables>(ActionGroupsDocument, baseOptions);
      }
export function useActionGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActionGroupsQuery, ActionGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActionGroupsQuery, ActionGroupsQueryVariables>(ActionGroupsDocument, baseOptions);
        }
export type ActionGroupsQueryHookResult = ReturnType<typeof useActionGroupsQuery>;
export type ActionGroupsLazyQueryHookResult = ReturnType<typeof useActionGroupsLazyQuery>;
export type ActionGroupsQueryResult = ApolloReactCommon.QueryResult<ActionGroupsQuery, ActionGroupsQueryVariables>;
export const ActionGroupDocument = gql`
    query ActionGroup($id: ID!) {
  actionGroup(id: $id) {
    ...ActionGroup
    actions {
      ...Action
    }
  }
}
    ${ActionGroupFragmentDoc}
${ActionFragmentDoc}`;

/**
 * __useActionGroupQuery__
 *
 * To run a query within a React component, call `useActionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActionGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActionGroupQuery, ActionGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<ActionGroupQuery, ActionGroupQueryVariables>(ActionGroupDocument, baseOptions);
      }
export function useActionGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActionGroupQuery, ActionGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActionGroupQuery, ActionGroupQueryVariables>(ActionGroupDocument, baseOptions);
        }
export type ActionGroupQueryHookResult = ReturnType<typeof useActionGroupQuery>;
export type ActionGroupLazyQueryHookResult = ReturnType<typeof useActionGroupLazyQuery>;
export type ActionGroupQueryResult = ApolloReactCommon.QueryResult<ActionGroupQuery, ActionGroupQueryVariables>;
export const CreateActionGroupDocument = gql`
    mutation CreateActionGroup($data: CreateActionGroupInput!) {
  createActionGroup(data: $data) {
    ...ActionGroup
  }
}
    ${ActionGroupFragmentDoc}`;
export type CreateActionGroupMutationFn = ApolloReactCommon.MutationFunction<CreateActionGroupMutation, CreateActionGroupMutationVariables>;

/**
 * __useCreateActionGroupMutation__
 *
 * To run a mutation, you first call `useCreateActionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionGroupMutation, { data, loading, error }] = useCreateActionGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateActionGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateActionGroupMutation, CreateActionGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateActionGroupMutation, CreateActionGroupMutationVariables>(CreateActionGroupDocument, baseOptions);
      }
export type CreateActionGroupMutationHookResult = ReturnType<typeof useCreateActionGroupMutation>;
export type CreateActionGroupMutationResult = ApolloReactCommon.MutationResult<CreateActionGroupMutation>;
export type CreateActionGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateActionGroupMutation, CreateActionGroupMutationVariables>;
export const DeleteActionGroupDocument = gql`
    mutation DeleteActionGroup($actionGroupId: ID!) {
  deleteActionGroup(actionGroupId: $actionGroupId)
}
    `;
export type DeleteActionGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteActionGroupMutation, DeleteActionGroupMutationVariables>;

/**
 * __useDeleteActionGroupMutation__
 *
 * To run a mutation, you first call `useDeleteActionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionGroupMutation, { data, loading, error }] = useDeleteActionGroupMutation({
 *   variables: {
 *      actionGroupId: // value for 'actionGroupId'
 *   },
 * });
 */
export function useDeleteActionGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteActionGroupMutation, DeleteActionGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteActionGroupMutation, DeleteActionGroupMutationVariables>(DeleteActionGroupDocument, baseOptions);
      }
export type DeleteActionGroupMutationHookResult = ReturnType<typeof useDeleteActionGroupMutation>;
export type DeleteActionGroupMutationResult = ApolloReactCommon.MutationResult<DeleteActionGroupMutation>;
export type DeleteActionGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteActionGroupMutation, DeleteActionGroupMutationVariables>;
export const SendgridTemplatesDocument = gql`
    query SendgridTemplates {
  sendgridTemplates {
    id
    name
  }
}
    `;

/**
 * __useSendgridTemplatesQuery__
 *
 * To run a query within a React component, call `useSendgridTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendgridTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendgridTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSendgridTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SendgridTemplatesQuery, SendgridTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<SendgridTemplatesQuery, SendgridTemplatesQueryVariables>(SendgridTemplatesDocument, baseOptions);
      }
export function useSendgridTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendgridTemplatesQuery, SendgridTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SendgridTemplatesQuery, SendgridTemplatesQueryVariables>(SendgridTemplatesDocument, baseOptions);
        }
export type SendgridTemplatesQueryHookResult = ReturnType<typeof useSendgridTemplatesQuery>;
export type SendgridTemplatesLazyQueryHookResult = ReturnType<typeof useSendgridTemplatesLazyQuery>;
export type SendgridTemplatesQueryResult = ApolloReactCommon.QueryResult<SendgridTemplatesQuery, SendgridTemplatesQueryVariables>;
export const GetCohortDocument = gql`
    query GetCohort($cohortId: ID!, $includeProgress: Boolean!) {
  cohort(id: $cohortId) {
    ...GetCohortCohort
  }
}
    ${GetCohortCohortFragmentDoc}`;

/**
 * __useGetCohortQuery__
 *
 * To run a query within a React component, call `useGetCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      includeProgress: // value for 'includeProgress'
 *   },
 * });
 */
export function useGetCohortQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCohortQuery, GetCohortQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCohortQuery, GetCohortQueryVariables>(GetCohortDocument, baseOptions);
      }
export function useGetCohortLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCohortQuery, GetCohortQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCohortQuery, GetCohortQueryVariables>(GetCohortDocument, baseOptions);
        }
export type GetCohortQueryHookResult = ReturnType<typeof useGetCohortQuery>;
export type GetCohortLazyQueryHookResult = ReturnType<typeof useGetCohortLazyQuery>;
export type GetCohortQueryResult = ApolloReactCommon.QueryResult<GetCohortQuery, GetCohortQueryVariables>;
export const DeleteCohortDocument = gql`
    mutation DeleteCohort($cohortId: String!) {
  deleteCohort(cohortId: $cohortId)
}
    `;
export type DeleteCohortMutationFn = ApolloReactCommon.MutationFunction<DeleteCohortMutation, DeleteCohortMutationVariables>;

/**
 * __useDeleteCohortMutation__
 *
 * To run a mutation, you first call `useDeleteCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCohortMutation, { data, loading, error }] = useDeleteCohortMutation({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useDeleteCohortMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCohortMutation, DeleteCohortMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCohortMutation, DeleteCohortMutationVariables>(DeleteCohortDocument, baseOptions);
      }
export type DeleteCohortMutationHookResult = ReturnType<typeof useDeleteCohortMutation>;
export type DeleteCohortMutationResult = ApolloReactCommon.MutationResult<DeleteCohortMutation>;
export type DeleteCohortMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCohortMutation, DeleteCohortMutationVariables>;
export const CohortActionGroupDocument = gql`
    query CohortActionGroup($cohortId: ID!) {
  cohort(id: $cohortId) {
    id
    actionGroupId
    actionGroup {
      name
      actions {
        ...Action
      }
    }
  }
}
    ${ActionFragmentDoc}`;

/**
 * __useCohortActionGroupQuery__
 *
 * To run a query within a React component, call `useCohortActionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortActionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortActionGroupQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useCohortActionGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CohortActionGroupQuery, CohortActionGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<CohortActionGroupQuery, CohortActionGroupQueryVariables>(CohortActionGroupDocument, baseOptions);
      }
export function useCohortActionGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CohortActionGroupQuery, CohortActionGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CohortActionGroupQuery, CohortActionGroupQueryVariables>(CohortActionGroupDocument, baseOptions);
        }
export type CohortActionGroupQueryHookResult = ReturnType<typeof useCohortActionGroupQuery>;
export type CohortActionGroupLazyQueryHookResult = ReturnType<typeof useCohortActionGroupLazyQuery>;
export type CohortActionGroupQueryResult = ApolloReactCommon.QueryResult<CohortActionGroupQuery, CohortActionGroupQueryVariables>;
export const EditCohortActionGroupDocument = gql`
    mutation EditCohortActionGroup($actionGroupId: String!, $cohortId: String!) {
  editCohortActionGroup(actionGroupId: $actionGroupId, cohortId: $cohortId) {
    id
    actionGroupId
  }
}
    `;
export type EditCohortActionGroupMutationFn = ApolloReactCommon.MutationFunction<EditCohortActionGroupMutation, EditCohortActionGroupMutationVariables>;

/**
 * __useEditCohortActionGroupMutation__
 *
 * To run a mutation, you first call `useEditCohortActionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCohortActionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCohortActionGroupMutation, { data, loading, error }] = useEditCohortActionGroupMutation({
 *   variables: {
 *      actionGroupId: // value for 'actionGroupId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useEditCohortActionGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCohortActionGroupMutation, EditCohortActionGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCohortActionGroupMutation, EditCohortActionGroupMutationVariables>(EditCohortActionGroupDocument, baseOptions);
      }
export type EditCohortActionGroupMutationHookResult = ReturnType<typeof useEditCohortActionGroupMutation>;
export type EditCohortActionGroupMutationResult = ApolloReactCommon.MutationResult<EditCohortActionGroupMutation>;
export type EditCohortActionGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCohortActionGroupMutation, EditCohortActionGroupMutationVariables>;
export const EditCohortDocument = gql`
    mutation EditCohort($data: EditCohortInput!) {
  editCohort(data: $data) {
    id
    name
    startDate
    endDate
    programId
    defaultContract {
      id
      name
    }
  }
}
    `;
export type EditCohortMutationFn = ApolloReactCommon.MutationFunction<EditCohortMutation, EditCohortMutationVariables>;

/**
 * __useEditCohortMutation__
 *
 * To run a mutation, you first call `useEditCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCohortMutation, { data, loading, error }] = useEditCohortMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditCohortMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCohortMutation, EditCohortMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCohortMutation, EditCohortMutationVariables>(EditCohortDocument, baseOptions);
      }
export type EditCohortMutationHookResult = ReturnType<typeof useEditCohortMutation>;
export type EditCohortMutationResult = ApolloReactCommon.MutationResult<EditCohortMutation>;
export type EditCohortMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCohortMutation, EditCohortMutationVariables>;
export const PendingPaymentsCountDocument = gql`
    query PendingPaymentsCount {
  pendingPaymentsCount
}
    `;

/**
 * __usePendingPaymentsCountQuery__
 *
 * To run a query within a React component, call `usePendingPaymentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingPaymentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingPaymentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingPaymentsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingPaymentsCountQuery, PendingPaymentsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PendingPaymentsCountQuery, PendingPaymentsCountQueryVariables>(PendingPaymentsCountDocument, baseOptions);
      }
export function usePendingPaymentsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingPaymentsCountQuery, PendingPaymentsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PendingPaymentsCountQuery, PendingPaymentsCountQueryVariables>(PendingPaymentsCountDocument, baseOptions);
        }
export type PendingPaymentsCountQueryHookResult = ReturnType<typeof usePendingPaymentsCountQuery>;
export type PendingPaymentsCountLazyQueryHookResult = ReturnType<typeof usePendingPaymentsCountLazyQuery>;
export type PendingPaymentsCountQueryResult = ApolloReactCommon.QueryResult<PendingPaymentsCountQuery, PendingPaymentsCountQueryVariables>;
export const SearchStudentsDocument = gql`
    query SearchStudents($search: String!) {
  searchStudents(search: $search) {
    id
    firstName
    lastName
    email
    cohorts {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchStudentsQuery__
 *
 * To run a query within a React component, call `useSearchStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchStudentsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchStudentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchStudentsQuery, SearchStudentsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchStudentsQuery, SearchStudentsQueryVariables>(SearchStudentsDocument, baseOptions);
      }
export function useSearchStudentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchStudentsQuery, SearchStudentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchStudentsQuery, SearchStudentsQueryVariables>(SearchStudentsDocument, baseOptions);
        }
export type SearchStudentsQueryHookResult = ReturnType<typeof useSearchStudentsQuery>;
export type SearchStudentsLazyQueryHookResult = ReturnType<typeof useSearchStudentsLazyQuery>;
export type SearchStudentsQueryResult = ApolloReactCommon.QueryResult<SearchStudentsQuery, SearchStudentsQueryVariables>;
export const GetContractDocument = gql`
    query GetContract($contractId: ID!) {
  contract(id: $contractId) {
    ...GetContractContract
  }
}
    ${GetContractContractFragmentDoc}`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
      }
export function useGetContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
        }
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = ApolloReactCommon.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const DeleteContractDocument = gql`
    mutation DeleteContract($contractId: ID!) {
  deleteContract(contractId: $contractId)
}
    `;
export type DeleteContractMutationFn = ApolloReactCommon.MutationFunction<DeleteContractMutation, DeleteContractMutationVariables>;

/**
 * __useDeleteContractMutation__
 *
 * To run a mutation, you first call `useDeleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractMutation, { data, loading, error }] = useDeleteContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useDeleteContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractMutation, DeleteContractMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractMutation, DeleteContractMutationVariables>(DeleteContractDocument, baseOptions);
      }
export type DeleteContractMutationHookResult = ReturnType<typeof useDeleteContractMutation>;
export type DeleteContractMutationResult = ApolloReactCommon.MutationResult<DeleteContractMutation>;
export type DeleteContractMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractMutation, DeleteContractMutationVariables>;
export const CreateContractDocument = gql`
    mutation CreateContract($data: CreateContractInput!) {
  createContract(data: $data) {
    ...Contract
  }
}
    ${ContractFragmentDoc}`;
export type CreateContractMutationFn = ApolloReactCommon.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, baseOptions);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = ApolloReactCommon.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const EditContractDocument = gql`
    mutation EditContract($data: EditContractInput!) {
  editContract(data: $data) {
    ...Contract
  }
}
    ${ContractFragmentDoc}`;
export type EditContractMutationFn = ApolloReactCommon.MutationFunction<EditContractMutation, EditContractMutationVariables>;

/**
 * __useEditContractMutation__
 *
 * To run a mutation, you first call `useEditContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editContractMutation, { data, loading, error }] = useEditContractMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditContractMutation, EditContractMutationVariables>) {
        return ApolloReactHooks.useMutation<EditContractMutation, EditContractMutationVariables>(EditContractDocument, baseOptions);
      }
export type EditContractMutationHookResult = ReturnType<typeof useEditContractMutation>;
export type EditContractMutationResult = ApolloReactCommon.MutationResult<EditContractMutation>;
export type EditContractMutationOptions = ApolloReactCommon.BaseMutationOptions<EditContractMutation, EditContractMutationVariables>;
export const GetContractsDocument = gql`
    query GetContracts {
  contracts {
    ...GetContractsContract
  }
}
    ${GetContractsContractFragmentDoc}`;

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, baseOptions);
      }
export function useGetContractsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, baseOptions);
        }
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsQueryResult = ApolloReactCommon.QueryResult<GetContractsQuery, GetContractsQueryVariables>;
export const GetSchoolContractsFullDocument = gql`
    query GetSchoolContractsFull($schoolId: ID!) {
  contractsForSchool(schoolId: $schoolId) {
    ...GetContractsContract
  }
}
    ${GetContractsContractFragmentDoc}`;

/**
 * __useGetSchoolContractsFullQuery__
 *
 * To run a query within a React component, call `useGetSchoolContractsFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolContractsFullQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolContractsFullQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolContractsFullQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSchoolContractsFullQuery, GetSchoolContractsFullQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSchoolContractsFullQuery, GetSchoolContractsFullQueryVariables>(GetSchoolContractsFullDocument, baseOptions);
      }
export function useGetSchoolContractsFullLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSchoolContractsFullQuery, GetSchoolContractsFullQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSchoolContractsFullQuery, GetSchoolContractsFullQueryVariables>(GetSchoolContractsFullDocument, baseOptions);
        }
export type GetSchoolContractsFullQueryHookResult = ReturnType<typeof useGetSchoolContractsFullQuery>;
export type GetSchoolContractsFullLazyQueryHookResult = ReturnType<typeof useGetSchoolContractsFullLazyQuery>;
export type GetSchoolContractsFullQueryResult = ApolloReactCommon.QueryResult<GetSchoolContractsFullQuery, GetSchoolContractsFullQueryVariables>;
export const PaymentsDocument = gql`
    query Payments {
  payments {
    ...Payment
  }
}
    ${PaymentFragmentDoc}`;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, baseOptions);
      }
export function usePaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, baseOptions);
        }
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = ApolloReactCommon.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const PendingPaymentsDocument = gql`
    query PendingPayments {
  pendingPayments {
    ...Payment
  }
}
    ${PaymentFragmentDoc}`;

/**
 * __usePendingPaymentsQuery__
 *
 * To run a query within a React component, call `usePendingPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingPaymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingPaymentsQuery, PendingPaymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<PendingPaymentsQuery, PendingPaymentsQueryVariables>(PendingPaymentsDocument, baseOptions);
      }
export function usePendingPaymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingPaymentsQuery, PendingPaymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PendingPaymentsQuery, PendingPaymentsQueryVariables>(PendingPaymentsDocument, baseOptions);
        }
export type PendingPaymentsQueryHookResult = ReturnType<typeof usePendingPaymentsQuery>;
export type PendingPaymentsLazyQueryHookResult = ReturnType<typeof usePendingPaymentsLazyQuery>;
export type PendingPaymentsQueryResult = ApolloReactCommon.QueryResult<PendingPaymentsQuery, PendingPaymentsQueryVariables>;
export const ChargeEmployedStudentsDocument = gql`
    mutation ChargeEmployedStudents {
  chargeEmployedStudents
}
    `;
export type ChargeEmployedStudentsMutationFn = ApolloReactCommon.MutationFunction<ChargeEmployedStudentsMutation, ChargeEmployedStudentsMutationVariables>;

/**
 * __useChargeEmployedStudentsMutation__
 *
 * To run a mutation, you first call `useChargeEmployedStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeEmployedStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeEmployedStudentsMutation, { data, loading, error }] = useChargeEmployedStudentsMutation({
 *   variables: {
 *   },
 * });
 */
export function useChargeEmployedStudentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChargeEmployedStudentsMutation, ChargeEmployedStudentsMutationVariables>) {
        return ApolloReactHooks.useMutation<ChargeEmployedStudentsMutation, ChargeEmployedStudentsMutationVariables>(ChargeEmployedStudentsDocument, baseOptions);
      }
export type ChargeEmployedStudentsMutationHookResult = ReturnType<typeof useChargeEmployedStudentsMutation>;
export type ChargeEmployedStudentsMutationResult = ApolloReactCommon.MutationResult<ChargeEmployedStudentsMutation>;
export type ChargeEmployedStudentsMutationOptions = ApolloReactCommon.BaseMutationOptions<ChargeEmployedStudentsMutation, ChargeEmployedStudentsMutationVariables>;
export const ConfirmPendingPaymentDocument = gql`
    mutation ConfirmPendingPayment($id: String!, $confirm: Boolean!) {
  confirmPendingPayment(pendingPaymentId: $id, confirm: $confirm) {
    ...Payment
  }
}
    ${PaymentFragmentDoc}`;
export type ConfirmPendingPaymentMutationFn = ApolloReactCommon.MutationFunction<ConfirmPendingPaymentMutation, ConfirmPendingPaymentMutationVariables>;

/**
 * __useConfirmPendingPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmPendingPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPendingPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPendingPaymentMutation, { data, loading, error }] = useConfirmPendingPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      confirm: // value for 'confirm'
 *   },
 * });
 */
export function useConfirmPendingPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmPendingPaymentMutation, ConfirmPendingPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmPendingPaymentMutation, ConfirmPendingPaymentMutationVariables>(ConfirmPendingPaymentDocument, baseOptions);
      }
export type ConfirmPendingPaymentMutationHookResult = ReturnType<typeof useConfirmPendingPaymentMutation>;
export type ConfirmPendingPaymentMutationResult = ApolloReactCommon.MutationResult<ConfirmPendingPaymentMutation>;
export type ConfirmPendingPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmPendingPaymentMutation, ConfirmPendingPaymentMutationVariables>;
export const CreateCohortDocument = gql`
    mutation CreateCohort($data: CreateCohortInput!) {
  createCohort(data: $data) {
    ...CreateCohort
  }
}
    ${CreateCohortFragmentDoc}`;
export type CreateCohortMutationFn = ApolloReactCommon.MutationFunction<CreateCohortMutation, CreateCohortMutationVariables>;

/**
 * __useCreateCohortMutation__
 *
 * To run a mutation, you first call `useCreateCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCohortMutation, { data, loading, error }] = useCreateCohortMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCohortMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCohortMutation, CreateCohortMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCohortMutation, CreateCohortMutationVariables>(CreateCohortDocument, baseOptions);
      }
export type CreateCohortMutationHookResult = ReturnType<typeof useCreateCohortMutation>;
export type CreateCohortMutationResult = ApolloReactCommon.MutationResult<CreateCohortMutation>;
export type CreateCohortMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCohortMutation, CreateCohortMutationVariables>;
export const GetSchoolContractsDocument = gql`
    query GetSchoolContracts($schoolId: ID!) {
  contractsForSchool(schoolId: $schoolId) {
    id
    name
  }
}
    `;

/**
 * __useGetSchoolContractsQuery__
 *
 * To run a query within a React component, call `useGetSchoolContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolContractsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolContractsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSchoolContractsQuery, GetSchoolContractsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSchoolContractsQuery, GetSchoolContractsQueryVariables>(GetSchoolContractsDocument, baseOptions);
      }
export function useGetSchoolContractsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSchoolContractsQuery, GetSchoolContractsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSchoolContractsQuery, GetSchoolContractsQueryVariables>(GetSchoolContractsDocument, baseOptions);
        }
export type GetSchoolContractsQueryHookResult = ReturnType<typeof useGetSchoolContractsQuery>;
export type GetSchoolContractsLazyQueryHookResult = ReturnType<typeof useGetSchoolContractsLazyQuery>;
export type GetSchoolContractsQueryResult = ApolloReactCommon.QueryResult<GetSchoolContractsQuery, GetSchoolContractsQueryVariables>;
export const EditProgramDocument = gql`
    mutation EditProgram($data: EditProgramInput!) {
  editProgram(data: $data) {
    id
    name
    description
  }
}
    `;
export type EditProgramMutationFn = ApolloReactCommon.MutationFunction<EditProgramMutation, EditProgramMutationVariables>;

/**
 * __useEditProgramMutation__
 *
 * To run a mutation, you first call `useEditProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProgramMutation, { data, loading, error }] = useEditProgramMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditProgramMutation, EditProgramMutationVariables>) {
        return ApolloReactHooks.useMutation<EditProgramMutation, EditProgramMutationVariables>(EditProgramDocument, baseOptions);
      }
export type EditProgramMutationHookResult = ReturnType<typeof useEditProgramMutation>;
export type EditProgramMutationResult = ApolloReactCommon.MutationResult<EditProgramMutation>;
export type EditProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<EditProgramMutation, EditProgramMutationVariables>;
export const GetProgramDocument = gql`
    query GetProgram($programId: ID!) {
  program(id: $programId) {
    ...GetProgram
    school {
      id
      name
    }
  }
}
    ${GetProgramFragmentDoc}`;

/**
 * __useGetProgramQuery__
 *
 * To run a query within a React component, call `useGetProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetProgramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProgramQuery, GetProgramQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProgramQuery, GetProgramQueryVariables>(GetProgramDocument, baseOptions);
      }
export function useGetProgramLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProgramQuery, GetProgramQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProgramQuery, GetProgramQueryVariables>(GetProgramDocument, baseOptions);
        }
export type GetProgramQueryHookResult = ReturnType<typeof useGetProgramQuery>;
export type GetProgramLazyQueryHookResult = ReturnType<typeof useGetProgramLazyQuery>;
export type GetProgramQueryResult = ApolloReactCommon.QueryResult<GetProgramQuery, GetProgramQueryVariables>;
export const ProgramActionGroupDocument = gql`
    query ProgramActionGroup($programId: ID!) {
  program(id: $programId) {
    id
    defaultActionGroupId
    defaultActionGroup {
      name
      actions {
        ...Action
      }
    }
  }
}
    ${ActionFragmentDoc}`;

/**
 * __useProgramActionGroupQuery__
 *
 * To run a query within a React component, call `useProgramActionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramActionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramActionGroupQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramActionGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProgramActionGroupQuery, ProgramActionGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<ProgramActionGroupQuery, ProgramActionGroupQueryVariables>(ProgramActionGroupDocument, baseOptions);
      }
export function useProgramActionGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProgramActionGroupQuery, ProgramActionGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProgramActionGroupQuery, ProgramActionGroupQueryVariables>(ProgramActionGroupDocument, baseOptions);
        }
export type ProgramActionGroupQueryHookResult = ReturnType<typeof useProgramActionGroupQuery>;
export type ProgramActionGroupLazyQueryHookResult = ReturnType<typeof useProgramActionGroupLazyQuery>;
export type ProgramActionGroupQueryResult = ApolloReactCommon.QueryResult<ProgramActionGroupQuery, ProgramActionGroupQueryVariables>;
export const EditProgramActionGroupDocument = gql`
    mutation EditProgramActionGroup($defaultActionGroupId: String!, $programId: String!) {
  editProgramActionGroup(defaultActionGroupId: $defaultActionGroupId, programId: $programId) {
    id
    defaultActionGroupId
  }
}
    `;
export type EditProgramActionGroupMutationFn = ApolloReactCommon.MutationFunction<EditProgramActionGroupMutation, EditProgramActionGroupMutationVariables>;

/**
 * __useEditProgramActionGroupMutation__
 *
 * To run a mutation, you first call `useEditProgramActionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProgramActionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProgramActionGroupMutation, { data, loading, error }] = useEditProgramActionGroupMutation({
 *   variables: {
 *      defaultActionGroupId: // value for 'defaultActionGroupId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useEditProgramActionGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditProgramActionGroupMutation, EditProgramActionGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<EditProgramActionGroupMutation, EditProgramActionGroupMutationVariables>(EditProgramActionGroupDocument, baseOptions);
      }
export type EditProgramActionGroupMutationHookResult = ReturnType<typeof useEditProgramActionGroupMutation>;
export type EditProgramActionGroupMutationResult = ApolloReactCommon.MutationResult<EditProgramActionGroupMutation>;
export type EditProgramActionGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditProgramActionGroupMutation, EditProgramActionGroupMutationVariables>;
export const CreateProgramDocument = gql`
    mutation CreateProgram($data: CreateProgramInput!) {
  createProgram(data: $data) {
    ...CreateProgram
  }
}
    ${CreateProgramFragmentDoc}`;
export type CreateProgramMutationFn = ApolloReactCommon.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, baseOptions);
      }
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = ApolloReactCommon.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProgramMutation, CreateProgramMutationVariables>;
export const CreateSchoolDocument = gql`
    mutation CreateSchool($data: CreateSchoolInput!) {
  createSchool(data: $data) {
    ...School
  }
}
    ${SchoolFragmentDoc}`;
export type CreateSchoolMutationFn = ApolloReactCommon.MutationFunction<CreateSchoolMutation, CreateSchoolMutationVariables>;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchoolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSchoolMutation, CreateSchoolMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(CreateSchoolDocument, baseOptions);
      }
export type CreateSchoolMutationHookResult = ReturnType<typeof useCreateSchoolMutation>;
export type CreateSchoolMutationResult = ApolloReactCommon.MutationResult<CreateSchoolMutation>;
export type CreateSchoolMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSchoolMutation, CreateSchoolMutationVariables>;
export const EditSchoolDocument = gql`
    mutation EditSchool($data: EditSchoolInput!) {
  editSchool(data: $data) {
    ...School
  }
}
    ${SchoolFragmentDoc}`;
export type EditSchoolMutationFn = ApolloReactCommon.MutationFunction<EditSchoolMutation, EditSchoolMutationVariables>;

/**
 * __useEditSchoolMutation__
 *
 * To run a mutation, you first call `useEditSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSchoolMutation, { data, loading, error }] = useEditSchoolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditSchoolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSchoolMutation, EditSchoolMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSchoolMutation, EditSchoolMutationVariables>(EditSchoolDocument, baseOptions);
      }
export type EditSchoolMutationHookResult = ReturnType<typeof useEditSchoolMutation>;
export type EditSchoolMutationResult = ApolloReactCommon.MutationResult<EditSchoolMutation>;
export type EditSchoolMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSchoolMutation, EditSchoolMutationVariables>;
export const GetSchoolDocument = gql`
    query GetSchool($schoolId: ID!) {
  school(id: $schoolId) {
    ...GetSchoolSchool
  }
}
    ${GetSchoolSchoolFragmentDoc}`;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, baseOptions);
      }
export function useGetSchoolLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, baseOptions);
        }
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<typeof useGetSchoolLazyQuery>;
export type GetSchoolQueryResult = ApolloReactCommon.QueryResult<GetSchoolQuery, GetSchoolQueryVariables>;
export const SchoolAdminsDocument = gql`
    query SchoolAdmins($schoolId: ID!) {
  schoolUsers(schoolId: $schoolId) {
    user_id
    name
    nickname
    email
    picture
  }
}
    `;

/**
 * __useSchoolAdminsQuery__
 *
 * To run a query within a React component, call `useSchoolAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAdminsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useSchoolAdminsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SchoolAdminsQuery, SchoolAdminsQueryVariables>) {
        return ApolloReactHooks.useQuery<SchoolAdminsQuery, SchoolAdminsQueryVariables>(SchoolAdminsDocument, baseOptions);
      }
export function useSchoolAdminsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolAdminsQuery, SchoolAdminsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SchoolAdminsQuery, SchoolAdminsQueryVariables>(SchoolAdminsDocument, baseOptions);
        }
export type SchoolAdminsQueryHookResult = ReturnType<typeof useSchoolAdminsQuery>;
export type SchoolAdminsLazyQueryHookResult = ReturnType<typeof useSchoolAdminsLazyQuery>;
export type SchoolAdminsQueryResult = ApolloReactCommon.QueryResult<SchoolAdminsQuery, SchoolAdminsQueryVariables>;
export const SchoolActionGroupDocument = gql`
    query SchoolActionGroup($schoolId: ID!) {
  school(id: $schoolId) {
    id
    defaultActionGroupId
    defaultActionGroup {
      name
      actions {
        ...Action
      }
    }
  }
}
    ${ActionFragmentDoc}`;

/**
 * __useSchoolActionGroupQuery__
 *
 * To run a query within a React component, call `useSchoolActionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolActionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolActionGroupQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useSchoolActionGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SchoolActionGroupQuery, SchoolActionGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<SchoolActionGroupQuery, SchoolActionGroupQueryVariables>(SchoolActionGroupDocument, baseOptions);
      }
export function useSchoolActionGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchoolActionGroupQuery, SchoolActionGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SchoolActionGroupQuery, SchoolActionGroupQueryVariables>(SchoolActionGroupDocument, baseOptions);
        }
export type SchoolActionGroupQueryHookResult = ReturnType<typeof useSchoolActionGroupQuery>;
export type SchoolActionGroupLazyQueryHookResult = ReturnType<typeof useSchoolActionGroupLazyQuery>;
export type SchoolActionGroupQueryResult = ApolloReactCommon.QueryResult<SchoolActionGroupQuery, SchoolActionGroupQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    user_id
    name
    email
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const AddSchoolAdminUserDocument = gql`
    mutation AddSchoolAdminUser($userId: String!, $schoolId: ID!) {
  addSchoolAdminUser(userId: $userId, schoolId: $schoolId)
}
    `;
export type AddSchoolAdminUserMutationFn = ApolloReactCommon.MutationFunction<AddSchoolAdminUserMutation, AddSchoolAdminUserMutationVariables>;

/**
 * __useAddSchoolAdminUserMutation__
 *
 * To run a mutation, you first call `useAddSchoolAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSchoolAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSchoolAdminUserMutation, { data, loading, error }] = useAddSchoolAdminUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useAddSchoolAdminUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSchoolAdminUserMutation, AddSchoolAdminUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSchoolAdminUserMutation, AddSchoolAdminUserMutationVariables>(AddSchoolAdminUserDocument, baseOptions);
      }
export type AddSchoolAdminUserMutationHookResult = ReturnType<typeof useAddSchoolAdminUserMutation>;
export type AddSchoolAdminUserMutationResult = ApolloReactCommon.MutationResult<AddSchoolAdminUserMutation>;
export type AddSchoolAdminUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSchoolAdminUserMutation, AddSchoolAdminUserMutationVariables>;
export const EditSchoolActionGroupDocument = gql`
    mutation EditSchoolActionGroup($defaultActionGroupId: String!, $schoolId: String!) {
  editSchoolActionGroup(defaultActionGroupId: $defaultActionGroupId, schoolId: $schoolId) {
    id
    defaultActionGroupId
  }
}
    `;
export type EditSchoolActionGroupMutationFn = ApolloReactCommon.MutationFunction<EditSchoolActionGroupMutation, EditSchoolActionGroupMutationVariables>;

/**
 * __useEditSchoolActionGroupMutation__
 *
 * To run a mutation, you first call `useEditSchoolActionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSchoolActionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSchoolActionGroupMutation, { data, loading, error }] = useEditSchoolActionGroupMutation({
 *   variables: {
 *      defaultActionGroupId: // value for 'defaultActionGroupId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useEditSchoolActionGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSchoolActionGroupMutation, EditSchoolActionGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSchoolActionGroupMutation, EditSchoolActionGroupMutationVariables>(EditSchoolActionGroupDocument, baseOptions);
      }
export type EditSchoolActionGroupMutationHookResult = ReturnType<typeof useEditSchoolActionGroupMutation>;
export type EditSchoolActionGroupMutationResult = ApolloReactCommon.MutationResult<EditSchoolActionGroupMutation>;
export type EditSchoolActionGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSchoolActionGroupMutation, EditSchoolActionGroupMutationVariables>;
export const GetSchoolsDocument = gql`
    query GetSchools {
  schools {
    ...School
  }
}
    ${SchoolFragmentDoc}`;

/**
 * __useGetSchoolsQuery__
 *
 * To run a query within a React component, call `useGetSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, baseOptions);
      }
export function useGetSchoolsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, baseOptions);
        }
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<typeof useGetSchoolsLazyQuery>;
export type GetSchoolsQueryResult = ApolloReactCommon.QueryResult<GetSchoolsQuery, GetSchoolsQueryVariables>;
export const GetCountsDocument = gql`
    query GetCounts {
  schoolsCount
  studentsCount
  countriesCount
}
    `;

/**
 * __useGetCountsQuery__
 *
 * To run a query within a React component, call `useGetCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountsQuery, GetCountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCountsQuery, GetCountsQueryVariables>(GetCountsDocument, baseOptions);
      }
export function useGetCountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountsQuery, GetCountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCountsQuery, GetCountsQueryVariables>(GetCountsDocument, baseOptions);
        }
export type GetCountsQueryHookResult = ReturnType<typeof useGetCountsQuery>;
export type GetCountsLazyQueryHookResult = ReturnType<typeof useGetCountsLazyQuery>;
export type GetCountsQueryResult = ApolloReactCommon.QueryResult<GetCountsQuery, GetCountsQueryVariables>;
export const CreateStudentDocument = gql`
    mutation CreateStudent($data: CreateStudentInput!) {
  createStudent(data: $data) {
    ...Student
  }
}
    ${StudentFragmentDoc}`;
export type CreateStudentMutationFn = ApolloReactCommon.MutationFunction<CreateStudentMutation, CreateStudentMutationVariables>;

/**
 * __useCreateStudentMutation__
 *
 * To run a mutation, you first call `useCreateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentMutation, { data, loading, error }] = useCreateStudentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStudentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStudentMutation, CreateStudentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStudentMutation, CreateStudentMutationVariables>(CreateStudentDocument, baseOptions);
      }
export type CreateStudentMutationHookResult = ReturnType<typeof useCreateStudentMutation>;
export type CreateStudentMutationResult = ApolloReactCommon.MutationResult<CreateStudentMutation>;
export type CreateStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStudentMutation, CreateStudentMutationVariables>;
export const GetCohortsDocument = gql`
    query GetCohorts {
  cohorts {
    ...Cohort
  }
}
    ${CohortFragmentDoc}`;

/**
 * __useGetCohortsQuery__
 *
 * To run a query within a React component, call `useGetCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCohortsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCohortsQuery, GetCohortsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCohortsQuery, GetCohortsQueryVariables>(GetCohortsDocument, baseOptions);
      }
export function useGetCohortsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCohortsQuery, GetCohortsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCohortsQuery, GetCohortsQueryVariables>(GetCohortsDocument, baseOptions);
        }
export type GetCohortsQueryHookResult = ReturnType<typeof useGetCohortsQuery>;
export type GetCohortsLazyQueryHookResult = ReturnType<typeof useGetCohortsLazyQuery>;
export type GetCohortsQueryResult = ApolloReactCommon.QueryResult<GetCohortsQuery, GetCohortsQueryVariables>;
export const EditStudentDocument = gql`
    mutation EditStudent($data: EditStudentInput!) {
  editStudent(data: $data) {
    ...GetStudentStudent
  }
}
    ${GetStudentStudentFragmentDoc}`;
export type EditStudentMutationFn = ApolloReactCommon.MutationFunction<EditStudentMutation, EditStudentMutationVariables>;

/**
 * __useEditStudentMutation__
 *
 * To run a mutation, you first call `useEditStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStudentMutation, { data, loading, error }] = useEditStudentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditStudentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditStudentMutation, EditStudentMutationVariables>) {
        return ApolloReactHooks.useMutation<EditStudentMutation, EditStudentMutationVariables>(EditStudentDocument, baseOptions);
      }
export type EditStudentMutationHookResult = ReturnType<typeof useEditStudentMutation>;
export type EditStudentMutationResult = ApolloReactCommon.MutationResult<EditStudentMutation>;
export type EditStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<EditStudentMutation, EditStudentMutationVariables>;
export const CreateEmploymentDocument = gql`
    mutation CreateEmployment($data: CreateEmploymentInput!) {
  createEmployment(data: $data) {
    ...Employment
  }
}
    ${EmploymentFragmentDoc}`;
export type CreateEmploymentMutationFn = ApolloReactCommon.MutationFunction<CreateEmploymentMutation, CreateEmploymentMutationVariables>;

/**
 * __useCreateEmploymentMutation__
 *
 * To run a mutation, you first call `useCreateEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmploymentMutation, { data, loading, error }] = useCreateEmploymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEmploymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmploymentMutation, CreateEmploymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmploymentMutation, CreateEmploymentMutationVariables>(CreateEmploymentDocument, baseOptions);
      }
export type CreateEmploymentMutationHookResult = ReturnType<typeof useCreateEmploymentMutation>;
export type CreateEmploymentMutationResult = ApolloReactCommon.MutationResult<CreateEmploymentMutation>;
export type CreateEmploymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmploymentMutation, CreateEmploymentMutationVariables>;
export const EditEmploymentDocument = gql`
    mutation EditEmployment($employmentId: ID!, $data: CreateEmploymentInput!) {
  editEmployment(employmentId: $employmentId, data: $data) {
    ...Employment
  }
}
    ${EmploymentFragmentDoc}`;
export type EditEmploymentMutationFn = ApolloReactCommon.MutationFunction<EditEmploymentMutation, EditEmploymentMutationVariables>;

/**
 * __useEditEmploymentMutation__
 *
 * To run a mutation, you first call `useEditEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmploymentMutation, { data, loading, error }] = useEditEmploymentMutation({
 *   variables: {
 *      employmentId: // value for 'employmentId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditEmploymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditEmploymentMutation, EditEmploymentMutationVariables>) {
        return ApolloReactHooks.useMutation<EditEmploymentMutation, EditEmploymentMutationVariables>(EditEmploymentDocument, baseOptions);
      }
export type EditEmploymentMutationHookResult = ReturnType<typeof useEditEmploymentMutation>;
export type EditEmploymentMutationResult = ApolloReactCommon.MutationResult<EditEmploymentMutation>;
export type EditEmploymentMutationOptions = ApolloReactCommon.BaseMutationOptions<EditEmploymentMutation, EditEmploymentMutationVariables>;
export const DeleteEmploymentDocument = gql`
    mutation DeleteEmployment($employmentId: ID!) {
  deleteEmployment(employmentId: $employmentId)
}
    `;
export type DeleteEmploymentMutationFn = ApolloReactCommon.MutationFunction<DeleteEmploymentMutation, DeleteEmploymentMutationVariables>;

/**
 * __useDeleteEmploymentMutation__
 *
 * To run a mutation, you first call `useDeleteEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmploymentMutation, { data, loading, error }] = useDeleteEmploymentMutation({
 *   variables: {
 *      employmentId: // value for 'employmentId'
 *   },
 * });
 */
export function useDeleteEmploymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmploymentMutation, DeleteEmploymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmploymentMutation, DeleteEmploymentMutationVariables>(DeleteEmploymentDocument, baseOptions);
      }
export type DeleteEmploymentMutationHookResult = ReturnType<typeof useDeleteEmploymentMutation>;
export type DeleteEmploymentMutationResult = ApolloReactCommon.MutationResult<DeleteEmploymentMutation>;
export type DeleteEmploymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmploymentMutation, DeleteEmploymentMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($data: CreateNoteInput!) {
  createNote(data: $data) {
    ...Note
  }
}
    ${NoteFragmentDoc}`;
export type CreateNoteMutationFn = ApolloReactCommon.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, baseOptions);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = ApolloReactCommon.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const EditNoteDocument = gql`
    mutation EditNote($data: EditNoteInput!) {
  editNote(data: $data) {
    ...Note
  }
}
    ${NoteFragmentDoc}`;
export type EditNoteMutationFn = ApolloReactCommon.MutationFunction<EditNoteMutation, EditNoteMutationVariables>;

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, baseOptions);
      }
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = ApolloReactCommon.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditNoteMutation, EditNoteMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation DeleteNote($id: ID!) {
  deleteNote(id: $id)
}
    `;
export type DeleteNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, baseOptions);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = ApolloReactCommon.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const GetStudentDocument = gql`
    query GetStudent($studentId: ID!) {
  student(id: $studentId) {
    ...GetStudentStudent
  }
}
    ${GetStudentStudentFragmentDoc}`;

/**
 * __useGetStudentQuery__
 *
 * To run a query within a React component, call `useGetStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, baseOptions);
      }
export function useGetStudentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, baseOptions);
        }
export type GetStudentQueryHookResult = ReturnType<typeof useGetStudentQuery>;
export type GetStudentLazyQueryHookResult = ReturnType<typeof useGetStudentLazyQuery>;
export type GetStudentQueryResult = ApolloReactCommon.QueryResult<GetStudentQuery, GetStudentQueryVariables>;
export const DeleteStudentDocument = gql`
    mutation DeleteStudent($studentId: ID!) {
  deleteStudent(studentId: $studentId)
}
    `;
export type DeleteStudentMutationFn = ApolloReactCommon.MutationFunction<DeleteStudentMutation, DeleteStudentMutationVariables>;

/**
 * __useDeleteStudentMutation__
 *
 * To run a mutation, you first call `useDeleteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentMutation, { data, loading, error }] = useDeleteStudentMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useDeleteStudentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStudentMutation, DeleteStudentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStudentMutation, DeleteStudentMutationVariables>(DeleteStudentDocument, baseOptions);
      }
export type DeleteStudentMutationHookResult = ReturnType<typeof useDeleteStudentMutation>;
export type DeleteStudentMutationResult = ApolloReactCommon.MutationResult<DeleteStudentMutation>;
export type DeleteStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const StudentActionProgressDocument = gql`
    query StudentActionProgress($cohortId: ID!, $studentId: ID!) {
  cohort(id: $cohortId) {
    id
    actionGroup {
      name
      actions {
        ...Action
        progress(studentId: $studentId) {
          ...ActionProgress
        }
        sentEmails(studentId: $studentId) {
          ...SentEmail
        }
      }
    }
  }
}
    ${ActionFragmentDoc}
${ActionProgressFragmentDoc}
${SentEmailFragmentDoc}`;

/**
 * __useStudentActionProgressQuery__
 *
 * To run a query within a React component, call `useStudentActionProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActionProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActionProgressQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useStudentActionProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentActionProgressQuery, StudentActionProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentActionProgressQuery, StudentActionProgressQueryVariables>(StudentActionProgressDocument, baseOptions);
      }
export function useStudentActionProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentActionProgressQuery, StudentActionProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentActionProgressQuery, StudentActionProgressQueryVariables>(StudentActionProgressDocument, baseOptions);
        }
export type StudentActionProgressQueryHookResult = ReturnType<typeof useStudentActionProgressQuery>;
export type StudentActionProgressLazyQueryHookResult = ReturnType<typeof useStudentActionProgressLazyQuery>;
export type StudentActionProgressQueryResult = ApolloReactCommon.QueryResult<StudentActionProgressQuery, StudentActionProgressQueryVariables>;
export const EmailTemplateDocument = gql`
    query EmailTemplate($studentId: ID!, $sendgridTemplateId: String!) {
  student(id: $studentId) {
    id
    emailTemplate(sendgridTemplateId: $sendgridTemplateId) {
      id
      htmlContentWithDynamicData
      plain_content
      subject
    }
  }
}
    `;

/**
 * __useEmailTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplateQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      sendgridTemplateId: // value for 'sendgridTemplateId'
 *   },
 * });
 */
export function useEmailTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmailTemplateQuery, EmailTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(EmailTemplateDocument, baseOptions);
      }
export function useEmailTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailTemplateQuery, EmailTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(EmailTemplateDocument, baseOptions);
        }
export type EmailTemplateQueryHookResult = ReturnType<typeof useEmailTemplateQuery>;
export type EmailTemplateLazyQueryHookResult = ReturnType<typeof useEmailTemplateLazyQuery>;
export type EmailTemplateQueryResult = ApolloReactCommon.QueryResult<EmailTemplateQuery, EmailTemplateQueryVariables>;
export const SendEmailDocument = gql`
    mutation SendEmail($data: SendEmailInput!) {
  sendEmail(data: $data)
}
    `;
export type SendEmailMutationFn = ApolloReactCommon.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, baseOptions);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = ApolloReactCommon.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const UpdateActionProgressStatusDocument = gql`
    mutation UpdateActionProgressStatus($data: UpdateActionProgressInput!) {
  updateActionProgressStatus(data: $data) {
    id
    studentId
    actionId
    status
  }
}
    `;
export type UpdateActionProgressStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateActionProgressStatusMutation, UpdateActionProgressStatusMutationVariables>;

/**
 * __useUpdateActionProgressStatusMutation__
 *
 * To run a mutation, you first call `useUpdateActionProgressStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionProgressStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionProgressStatusMutation, { data, loading, error }] = useUpdateActionProgressStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateActionProgressStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateActionProgressStatusMutation, UpdateActionProgressStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateActionProgressStatusMutation, UpdateActionProgressStatusMutationVariables>(UpdateActionProgressStatusDocument, baseOptions);
      }
export type UpdateActionProgressStatusMutationHookResult = ReturnType<typeof useUpdateActionProgressStatusMutation>;
export type UpdateActionProgressStatusMutationResult = ApolloReactCommon.MutationResult<UpdateActionProgressStatusMutation>;
export type UpdateActionProgressStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateActionProgressStatusMutation, UpdateActionProgressStatusMutationVariables>;
export const ActionTypeDetailsDocument = gql`
    query ActionTypeDetails {
  actionTypeDetails {
    label
    stage
    type
  }
}
    `;

/**
 * __useActionTypeDetailsQuery__
 *
 * To run a query within a React component, call `useActionTypeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionTypeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionTypeDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActionTypeDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActionTypeDetailsQuery, ActionTypeDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ActionTypeDetailsQuery, ActionTypeDetailsQueryVariables>(ActionTypeDetailsDocument, baseOptions);
      }
export function useActionTypeDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActionTypeDetailsQuery, ActionTypeDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActionTypeDetailsQuery, ActionTypeDetailsQueryVariables>(ActionTypeDetailsDocument, baseOptions);
        }
export type ActionTypeDetailsQueryHookResult = ReturnType<typeof useActionTypeDetailsQuery>;
export type ActionTypeDetailsLazyQueryHookResult = ReturnType<typeof useActionTypeDetailsLazyQuery>;
export type ActionTypeDetailsQueryResult = ApolloReactCommon.QueryResult<ActionTypeDetailsQuery, ActionTypeDetailsQueryVariables>;
export const GetStudentsDocument = gql`
    query GetStudents($schoolId: ID, $stage: Stage) {
  students(schoolId: $schoolId, stage: $stage) {
    ...Student
  }
}
    ${StudentFragmentDoc}`;

/**
 * __useGetStudentsQuery__
 *
 * To run a query within a React component, call `useGetStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetStudentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, baseOptions);
      }
export function useGetStudentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, baseOptions);
        }
export type GetStudentsQueryHookResult = ReturnType<typeof useGetStudentsQuery>;
export type GetStudentsLazyQueryHookResult = ReturnType<typeof useGetStudentsLazyQuery>;
export type GetStudentsQueryResult = ApolloReactCommon.QueryResult<GetStudentsQuery, GetStudentsQueryVariables>;
export const CohortsDocument = gql`
    query Cohorts {
  cohorts {
    id
    name
    program {
      id
      name
      school {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCohortsQuery__
 *
 * To run a query within a React component, call `useCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCohortsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CohortsQuery, CohortsQueryVariables>) {
        return ApolloReactHooks.useQuery<CohortsQuery, CohortsQueryVariables>(CohortsDocument, baseOptions);
      }
export function useCohortsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CohortsQuery, CohortsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CohortsQuery, CohortsQueryVariables>(CohortsDocument, baseOptions);
        }
export type CohortsQueryHookResult = ReturnType<typeof useCohortsQuery>;
export type CohortsLazyQueryHookResult = ReturnType<typeof useCohortsLazyQuery>;
export type CohortsQueryResult = ApolloReactCommon.QueryResult<CohortsQuery, CohortsQueryVariables>;
export const SendEmailBulkDocument = gql`
    mutation SendEmailBulk($data: [SendBulkEmailInput!]!) {
  sendEmailBulk(data: $data)
}
    `;
export type SendEmailBulkMutationFn = ApolloReactCommon.MutationFunction<SendEmailBulkMutation, SendEmailBulkMutationVariables>;

/**
 * __useSendEmailBulkMutation__
 *
 * To run a mutation, you first call `useSendEmailBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailBulkMutation, { data, loading, error }] = useSendEmailBulkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendEmailBulkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailBulkMutation, SendEmailBulkMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEmailBulkMutation, SendEmailBulkMutationVariables>(SendEmailBulkDocument, baseOptions);
      }
export type SendEmailBulkMutationHookResult = ReturnType<typeof useSendEmailBulkMutation>;
export type SendEmailBulkMutationResult = ApolloReactCommon.MutationResult<SendEmailBulkMutation>;
export type SendEmailBulkMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailBulkMutation, SendEmailBulkMutationVariables>;
export const UpdateStageBulkDocument = gql`
    mutation UpdateStageBulk($studentIds: [ID!]!, $stage: Stage!) {
  updateStageBulk(studentIds: $studentIds, stage: $stage) {
    id
    stage
  }
}
    `;
export type UpdateStageBulkMutationFn = ApolloReactCommon.MutationFunction<UpdateStageBulkMutation, UpdateStageBulkMutationVariables>;

/**
 * __useUpdateStageBulkMutation__
 *
 * To run a mutation, you first call `useUpdateStageBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStageBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStageBulkMutation, { data, loading, error }] = useUpdateStageBulkMutation({
 *   variables: {
 *      studentIds: // value for 'studentIds'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useUpdateStageBulkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStageBulkMutation, UpdateStageBulkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStageBulkMutation, UpdateStageBulkMutationVariables>(UpdateStageBulkDocument, baseOptions);
      }
export type UpdateStageBulkMutationHookResult = ReturnType<typeof useUpdateStageBulkMutation>;
export type UpdateStageBulkMutationResult = ApolloReactCommon.MutationResult<UpdateStageBulkMutation>;
export type UpdateStageBulkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStageBulkMutation, UpdateStageBulkMutationVariables>;
export const StudentsByTagDocument = gql`
    query StudentsByTag($schoolId: ID, $tag: String) {
  students(schoolId: $schoolId, tag: $tag) {
    ...Student
  }
}
    ${StudentFragmentDoc}`;

/**
 * __useStudentsByTagQuery__
 *
 * To run a query within a React component, call `useStudentsByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsByTagQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useStudentsByTagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudentsByTagQuery, StudentsByTagQueryVariables>) {
        return ApolloReactHooks.useQuery<StudentsByTagQuery, StudentsByTagQueryVariables>(StudentsByTagDocument, baseOptions);
      }
export function useStudentsByTagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentsByTagQuery, StudentsByTagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudentsByTagQuery, StudentsByTagQueryVariables>(StudentsByTagDocument, baseOptions);
        }
export type StudentsByTagQueryHookResult = ReturnType<typeof useStudentsByTagQuery>;
export type StudentsByTagLazyQueryHookResult = ReturnType<typeof useStudentsByTagLazyQuery>;
export type StudentsByTagQueryResult = ApolloReactCommon.QueryResult<StudentsByTagQuery, StudentsByTagQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
      }
export function useCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = ApolloReactCommon.QueryResult<CountriesQuery, CountriesQueryVariables>;