import React from 'react'
import styled from 'styled-components'
import {
  BellOutlined,
  UserOutlined,
  SearchOutlined,
  ExpandOutlined,
  CompressOutlined,
} from '@ant-design/icons'
import { gql } from 'apollo-boost'
import { Avatar, Menu, Dropdown, Space, Badge, Modal, Form, List } from 'antd'
import { blue } from '@ant-design/colors'
import { Link, useHistory } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { Field, Formik } from 'formik'
import { usePendingPaymentsCountQuery, useSearchStudentsQuery } from '../generated/graphql'
import { useAuth0 } from '../auth/auth0'
import { trackEvent } from '../utils/analytics'
import { CustomInputComponent } from './FormikCustom'

export const PENDING_PAYMENTS_COUNT_QUERY = gql`
  query PendingPaymentsCount {
    pendingPaymentsCount
  }
`

gql`
  query SearchStudents($search: String!) {
    searchStudents(search: $search) {
      id
      firstName
      lastName
      email
      cohorts {
        id
        name
      }
    }
  }
`

const Hover = styled.div`
  color: white;
  cursor: pointer;
  padding: 0px 5px;
  transition: all 0.3s;
  &:hover {
    background-color: ${blue.primary};
  }
`

const Email = styled.span`
  padding-right: 7px;
`

const AccountDropdown: React.FC<{}> = () => {
  const { user, logout } = useAuth0()
  const history = useHistory()

  return (
    <Dropdown
      overlay={
        <Menu>
          {user?.adminUser && (
            <Menu.Item onClick={() => history.push('/action-groups')}>Actions</Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item onClick={() => logout()}>Log Out</Menu.Item>
        </Menu>
      }
    >
      <Hover>
        <Email>{user?.name}</Email>
        <Avatar
          size="large"
          icon={user?.picture ? <img src={user?.picture} alt="" /> : <UserOutlined />}
        />
      </Hover>
    </Dropdown>
  )
}

const BadgeWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
  padding: 0 10px;
  &:hover {
    background-color: ${blue.primary};
  }
`

interface RightNavContentProps {
  expanded: boolean
  toggleFullWidth: () => void
}

export const RightNavContent: React.FC<RightNavContentProps> = props => {
  const { user } = useAuth0()

  if (!user?.adminUser) return <AccountDropdown />

  return (
    <Space direction="horizontal">
      <Search />
      <FullWidthButton expanded={props.expanded} toggleFullWidth={props.toggleFullWidth} />
      <Notifications />
      <AccountDropdown />
    </Space>
  )
}

const Search: React.FC<{}> = props => {
  const [modalVisible, setModalVisible] = React.useState(false)

  useHotkeys('command+k', () => {
    setModalVisible(modalVisible => !modalVisible)
    trackEvent('Command K', { modalVisible })
  })

  return (
    <BadgeWrapper>
      <SearchOutlined
        onClick={() => setModalVisible(true)}
        style={{ verticalAlign: 'middle', padding: 4, fontSize: 18 }}
      />

      <Modal
        title="Search"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Formik<{ search: string }>
          initialValues={{ search: '' }}
          onSubmit={(values, { setSubmitting }) => {
            console.log('onSubmit', values)
            trackEvent('Search', values)
            setSubmitting(false)
            setModalVisible(false)
          }}
        >
          {({ handleSubmit, handleReset, values }) => {
            return (
              <Form onFinish={() => handleSubmit()} onReset={handleReset}>
                <Field
                  name="search"
                  component={CustomInputComponent}
                  autoFocus
                  autoComplete="off"
                />

                {values.search && (
                  <SearchStudentList
                    search={values.search}
                    onClick={() => setModalVisible(false)}
                  />
                )}
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </BadgeWrapper>
  )
}

const Notifications: React.FC<{}> = props => {
  const { data } = usePendingPaymentsCountQuery()

  return (
    <Link to="/payments?tab=NeedConfirm">
      <BadgeWrapper>
        <Badge count={data?.pendingPaymentsCount || 0} style={{ boxShadow: 'none' }}>
          <BellOutlined style={{ verticalAlign: 'middle', padding: 4, fontSize: 18 }} />
        </Badge>
      </BadgeWrapper>
    </Link>
  )
}

const FullWidthButton: React.FC<RightNavContentProps> = props => {
  return (
    <BadgeWrapper onClick={props.toggleFullWidth}>
      {props.expanded ? (
        <CompressOutlined style={{ verticalAlign: 'middle', padding: 4, fontSize: 18 }} />
      ) : (
        <ExpandOutlined style={{ verticalAlign: 'middle', padding: 4, fontSize: 18 }} />
      )}
    </BadgeWrapper>
  )
}

interface SearchStudentListProps {
  search: string
  onClick: () => void
}

export const SearchStudentList: React.FC<SearchStudentListProps> = props => {
  const { data, loading } = useSearchStudentsQuery({ variables: { search: props.search } })

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data?.searchStudents || []}
      renderItem={item => (
        <SearchItem to={`/student/${item.id}`} onClick={props.onClick} tabIndex={0}>
          <List.Item>
            <List.Item.Meta title={`${item.firstName} ${item.lastName}`} description={item.email} />
          </List.Item>
        </SearchItem>
      )}
    />
  )
}

const SearchItem = styled(Link)`
  &:focus {
    width: 100%;
    box-shadow: 0 0 2px 2px #40a9ff;
  }
`
