import { Card, Steps, Table, Tag } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { DownloadOutlined } from '@ant-design/icons'
import { blue } from '@ant-design/colors'

const TabCard = styled.span`
  padding-left: 50px;
  width: 70%;
  display: inline-block;
  position: absolute;
`
const Heading = styled.h1`
  text-align: center;
  padding-bottom: 10px;
`
const Container = styled.div`
  position: relative;
  left: 33%;
`
const employmentData = [
  {
    key: '1',
    employer: 'Spark',
    startDate: '3/8/2020',
    endDate: '12/12/2020',
    amount: '₪15,000 / month',
    paycheckFiles: <a href="#">Link</a>,
  },
  {
    key: '2',
    employer: 'Google',
    startDate: '6/5/2020',
    endDate: '12/12/2020',
    amount: '₪20,000 / month',
    paycheckFiles: <a href="#">Link</a>,
  },
]

const paymentData = [
  {
    key: '3',
    paymentNum: '5 / 24',
    amount: '₪1300',
    dueDate: '5/3/2020',
    paidDate: '5/3/2020',
    status: 'Current',
    paymentMethod: 'Stripe',
  },
  {
    key: '4',
    paymentNum: '6 / 24',
    amount: '₪1500',
    dueDate: '5/3/2020',
    paidDate: 'N/A',
    status: 'delayed',
    paymentMethod: 'Stripe',
  },
]

const documentsData = [
  {
    key: '5',
    document: 'Signed Contract',
    file: '',
  },
  {
    key: '6',
    document: 'ID (front)',
    file:
      'https://api.typeform.com/responses/files/8caf4a694ba81a6e008296c4a543563126addf916d7a81c1445a337eef07da29/Screen_Shot_2020_02_14_at_16.39.13.png',
  },
  {
    key: '7',
    document: 'ID (back)',
    file:
      'https://api.typeform.com/responses/files/dd5a3af76140c13009b37c014cb23be2e8caa753637f287c127e70c613e8cf73/Screen_Shot_2020_02_14_at_16.39.59.png',
  },
  {
    key: '8',
    document: 'Pay Stub',
    file:
      'https://api.typeform.com/responses/files/1cc187097cdc2a766dcd5bc089dfa4bac7c00a27c18b1ce06797724048972004/Screen_Shot_2020_02_14_at_16.40.27.png',
  },
  {
    key: '9',
    document: 'Check Image',
    file:
      'https://api.typeform.com/responses/files/f7113c73402caeab9d541eb50370c0f1dc7698327d92f7d79717ecdca6268522/Screen_Shot_2020_02_14_at_16.40.42.png',
  },
  {
    key: '10',
    document: 'Signature',
    file:
      'https://api.typeform.com/responses/files/f8b94c7f585e4b3a654e0980b2d79a8684b2ec640b00de6df6163bf2256f393b/Screen_Shot_2020_02_13_at_21.23.39.png',
  },
  {
    key: '11',
    document: 'Agreement Video',
    file:
      'https://api.typeform.com/responses/files/44af2cae8371a0e06dde5acbddf5bc66da3aa8bf5151a41f1f631b68711093b8/Movie_on_13_02_2020_at_21.24.mov',
  },
]

export const ProgressTabs: React.FC<{}> = props => {
  const [state, setState] = React.useState({
    key: 'tab1',
    noTitleKey: 'preCourse',
  })

  const { Step } = Steps

  const tabListNoTitle = [
    {
      key: 'preCourse',
      tab: 'Pre-Course',
    },
    {
      key: 'postCourse',
      tab: 'Post-Course',
    },
  ]

  const documentsColumns = [
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: file => {
        return (
          <span>
            <DownloadOutlined style={{ color: blue.primary }} />
            <a href={file} key={file}>
              Download File
            </a>
          </span>
        )
      },
    },
  ]

  const employmentColumns = [
    {
      title: 'Employer',
      dataIndex: 'employer',
      key: 'employer',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Amount/Currency',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Paycheck Files',
      dataIndex: 'paycheckFiles',
      key: 'paycheckFiles',
    },
  ]

  const paymentColumns = [
    {
      title: 'Payment #',
      dataIndex: 'paymentNum',
      key: 'paymentNum',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
    },
    {
      title: 'Paid Date',
      dataIndex: 'paidDate',
      key: 'paidDate',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        let color = 'green'
        let random = Math.random()
          .toString(36)
          .substring(7)
        let statusToUpper = status.toUpperCase()
        if (statusToUpper === 'DELAYED') {
          color = 'volcano'
        }
        return (
          <Tag color={color} key={random}>
            {statusToUpper}
          </Tag>
        )
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
  ]
  const contentListNoTitle = {
    preCourse: (
      <div>
        <Card title="Onboarding Timeline">
          <Steps size="small" current={1}>
            <Step title="Welcome Form" description="" />
            <Step title="Video Interview" description="" />
            <Step title="Interview Status" description="" />
            <Step title="E-signature link" description="Hello Sign" />
            <Step title="Onboarding questionnaire" description="" />
          </Steps>
        </Card>
        <Card>
          <Heading>Student Documents</Heading>
          <Container>
            <Table
              style={{ width: 300 }}
              dataSource={documentsData}
              columns={documentsColumns}
              pagination={false}
            />
          </Container>
        </Card>
      </div>
    ),
    postCourse: (
      <div>
        <Card>
          <Heading>Employment</Heading>
          <Table dataSource={employmentData} columns={employmentColumns} pagination={false} />
        </Card>
        <Card>
          <Heading>Payments</Heading>
          <Table dataSource={paymentData} columns={paymentColumns} pagination={false} />
        </Card>
      </div>
    ),
  }

  const onTabChange = (key, type) => {
    setState({
      ...state,
      [type]: key,
    })
  }
  return (
    <TabCard>
      <Card
        tabList={tabListNoTitle}
        activeTabKey={state.key}
        tabBarExtraContent={<a href="#">More</a>}
        onTabChange={key => {
          onTabChange(key, 'noTitleKey')
        }}
      >
        {contentListNoTitle[state.noTitleKey]}
      </Card>
    </TabCard>
  )
}
