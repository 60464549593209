import React from 'react'
import {
  BarChart as BarChartRecharts,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { blue } from '@ant-design/colors'

interface BarChartProps {
  dataKey: string
  data: ReadonlyArray<object>
  name?: string
}

export const BarChart: React.FC<BarChartProps> = props => {
  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChartRecharts
        data={props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={props.dataKey} name={props.name} fill={blue.primary} />
      </BarChartRecharts>
    </ResponsiveContainer>
  )
}
