import { gql } from 'apollo-boost'
import { ColumnProps } from 'antd/lib/table'
import { Button, Table, Form, Space, message, Popconfirm } from 'antd'
import { Field, Formik } from 'formik'
import React from 'react'
import moment, { Moment } from 'moment'
import {
  GetStudentStudentFragment,
  useCreateEmploymentMutation,
  useEditEmploymentMutation,
  useDeleteEmploymentMutation,
  CreateEmploymentInput,
  Country,
  Currency,
  EmploymentFragment,
  SalaryType,
} from '../../generated/graphql'
import {
  CustomInputComponent,
  CustomDateComponent,
  CustomDateRangeComponent,
  CustomSelectComponent,
} from '../../components/FormikCustom'
import { ButtonModalAdd } from '../../components/ButtonModalAdd'
import { ButtonModalLink } from '../../components/ButtonModalLink'
import { CountrySelectField } from '../../components/CountrySelectField'
import { CurrencySelectField } from '../../components/CurrencySelectField'
import { openSuccessNotification, openErrorNotification } from '../../components/Notification'
import { formatDate } from '../../utils/date'
import { GET_STUDENT } from './Student'
import { amountToServerAmount, formatAmount, serverAmountToAmount } from '../../utils/currency'
import { tailFormItemLayout, formItemLayout } from '../../components/Form'
import { trackEvent } from '../../utils/analytics'
import { EMPLOYMENT_FRAGMENT } from '../fragments'
import { formatGraphQLError } from '../../utils/error'
import { formatSalaryType } from '../../utils/employment'

gql`
  ${EMPLOYMENT_FRAGMENT}

  mutation CreateEmployment($data: CreateEmploymentInput!) {
    createEmployment(data: $data) {
      ...Employment
    }
  }
`

gql`
  ${EMPLOYMENT_FRAGMENT}

  mutation EditEmployment($employmentId: ID!, $data: CreateEmploymentInput!) {
    editEmployment(employmentId: $employmentId, data: $data) {
      ...Employment
    }
  }
`

gql`
  mutation DeleteEmployment($employmentId: ID!) {
    deleteEmployment(employmentId: $employmentId)
  }
`

const columns: ColumnProps<EmploymentFragment>[] = [
  {
    title: 'Company',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (value: Date) => <>{formatDate(value)}</>,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (value?: Date) => <>{value && formatDate(value)}</>,
  },
  {
    title: 'Monthly Salary',
    dataIndex: 'amountPerMonth',
    key: 'amountPerMonth',
    render: (value, row) => {
      return <>{formatAmount(value, row.currency)}</>
    },
  },
  {
    title: 'Hourly Salary',
    dataIndex: 'amountPerHour',
    key: 'amountPerHour',
    render: (value, row) => {
      return <>{formatAmount(value, row.currency)}</>
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (value, row) => {
      return (
        <Space size="middle">
          <ButtonModalLink
            title="Edit"
            content={closeModal => (
              <CreateEmploymentForm
                onCreate={closeModal}
                studentId={row.studentId}
                currency={row.currency}
                employment={row}
              />
            )}
          />
          <DeleteEmployment employmentId={row.id} studentId={row.studentId} />
        </Space>
      )
    },
  },
  // {
  //   title: 'Paycheck Files',
  //   dataIndex: 'paycheckFiles',
  //   key: 'paycheckFiles',
  // },
]

interface EmploymentProps {
  studentId: string
  employments: GetStudentStudentFragment['employments']
  currency?: Currency
}

export const Employment: React.FC<EmploymentProps> = props => {
  return (
    <>
      <ButtonModalAdd
        title="Add Employment"
        form={closeModal => (
          <CreateEmploymentForm
            studentId={props.studentId}
            currency={props.currency}
            onCreate={closeModal}
          />
        )}
      />
      <Table
        rowKey="id"
        dataSource={
          props.employments
            ?.map(employment => ({
              ...employment,
              startDate: new Date(employment.startDate),
              endDate: employment.endDate && new Date(employment.endDate),
            }))
            .sort((a, b) => +a.startDate - +b.startDate) || []
        }
        columns={columns}
        pagination={false}
      />
    </>
  )
}

interface EmploymentFormProps {
  studentId: string
  currency?: Currency
  employment?: EmploymentFragment
  onCreate?: () => void
}

const CreateEmploymentForm: React.FC<EmploymentFormProps> = props => {
  const { studentId, currency, employment } = props
  const [createEmployment] = useCreateEmploymentMutation({
    refetchQueries: [{ query: GET_STUDENT, variables: { studentId } }],
  })
  const [editEmployment] = useEditEmploymentMutation()

  if (!currency) return <p>You must attach a contract to this student before adding employment.</p>

  return (
    <Formik<CreateEmploymentInput & { dates?: Array<Moment | undefined> }>
      enableReinitialize
      initialValues={{
        studentId,
        companyName: employment?.companyName || '',
        amountPerMonth: employment?.amountPerMonth
          ? serverAmountToAmount(employment?.amountPerMonth)
          : 0,
        amountPerHour: employment?.amountPerHour
          ? serverAmountToAmount(employment?.amountPerHour)
          : 0,
        currency: employment?.currency || currency,
        salaryType: employment?.salaryType || SalaryType.Monthly,
        startDate: employment?.startDate || new Date(),
        endDate: employment?.endDate || undefined,
        dates: [
          employment?.startDate ? moment(employment?.startDate) : moment(),
          employment?.endDate ? moment(employment?.endDate) : undefined,
        ],
        country: employment?.country || Country.Israel,
        notes: employment?.notes || '',
        firstPaymentDate: employment?.firstPaymentDate
          ? moment(employment?.firstPaymentDate)
          : moment(),
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)

        if (!values.dates || !values.dates[0]) return

        const employmentId = employment?.id

        try {
          const data = {
            studentId: values.studentId,
            companyName: values.companyName,
            amountPerMonth:
              values.amountPerMonth || values.amountPerMonth === 0
                ? amountToServerAmount(values.amountPerMonth)
                : undefined,
            amountPerHour:
              values.amountPerHour || values.amountPerHour === 0
                ? amountToServerAmount(values.amountPerHour)
                : undefined,
            currency: values.currency,
            startDate: values.dates[0].toDate(),
            endDate: values.dates?.[1]?.toDate?.(),
            country: values.country,
            notes: values.notes,
            firstPaymentDate: values.firstPaymentDate,
            salaryType: values.salaryType,
          }

          if (employmentId) {
            await editEmployment({ variables: { employmentId, data } })
            trackEvent('editEmployment', { ...data, employmentId })
            openSuccessNotification({ description: 'Updated employment' })
          } else {
            await createEmployment({ variables: { data } })
            trackEvent('createEmployment', data)
            openSuccessNotification({ description: 'Added employment' })
          }
        } catch (error) {
          if (employmentId) openErrorNotification({ description: 'Error editing employment' })
          else openErrorNotification({ description: 'Error adding employment' })
        }
        setSubmitting(false)
        props.onCreate?.()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, values }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field name="companyName" label="Company" component={CustomInputComponent} />
          <Field
            name="salaryType"
            label="Salary Type"
            options={Object.values(SalaryType).map(salaryType => ({
              value: salaryType,
              label: formatSalaryType(salaryType),
            }))}
            component={CustomSelectComponent}
          />
          {values.salaryType === SalaryType.Monthly ? (
            <Field
              name="amountPerMonth"
              label="Monthly Salary"
              type="number"
              min={0}
              component={CustomInputComponent}
            />
          ) : (
            <Field
              name="amountPerHour"
              label="Hourly Salary"
              type="number"
              min={0}
              component={CustomInputComponent}
            />
          )}
          <CurrencySelectField disabled />
          <CountrySelectField />
          <Field
            name="dates"
            label="Dates"
            allowEmpty={[false, true]}
            component={CustomDateRangeComponent}
          />
          <Field
            name="firstPaymentDate"
            label="First Payment Date"
            component={CustomDateComponent}
          />
          <Field name="notes" label="Notes" component={CustomInputComponent} />
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              {employment ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}

const DeleteEmployment: React.FC<{ employmentId: string; studentId: string }> = props => {
  const { employmentId, studentId } = props
  const [deleteEmployment] = useDeleteEmploymentMutation({
    variables: { employmentId },
    refetchQueries: [{ query: GET_STUDENT, variables: { studentId } }],
  })

  return (
    <Popconfirm
      title="Are you sure you want to delete this employment?"
      onConfirm={async () => {
        try {
          await deleteEmployment()
          trackEvent('deleteEmployment', { employmentId })
          message.success('Deleted employment')
        } catch (error) {
          console.error(error)
          message.error(formatGraphQLError(error))
        }
      }}
      okText="Delete"
      cancelText="Cancel"
    >
      <a href="#">Delete</a>
    </Popconfirm>
  )
}
