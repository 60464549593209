import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'

export const openSuccessNotification = ({ message, description }: Partial<ArgsProps>) =>
  notification.success({
    message: message ?? 'Success',
    description,
  })

export const openErrorNotification = ({ message, description }: Partial<ArgsProps>) =>
  notification.error({
    message: message ?? 'Error',
    description,
  })
