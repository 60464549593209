import React from 'react'
import styled from 'styled-components'
import ProLayout from '@ant-design/pro-layout'
import {
  BrowserRouter as Router,
  Link,
  Switch,
  useLocation,
  Route,
  Redirect,
} from 'react-router-dom'
import { Cohort } from './cohort/Cohort'
import { Program } from './program/Program'
import { School } from './schools/School'
import { Schools } from './schools/Schools'
import { Student } from './students/StudentAdmin/Student'
import { Students } from './students/Students'
import { Auth0Provider } from './auth/auth0'
import { history } from './utils/history'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } from './utils/config'
import { AuthorizedApolloProvider } from './auth/AuthorizedApolloProvider'
import { PrivateRoute } from './auth/PrivateRoute'
import { useAuth0 } from './auth/auth0'
import { Contract } from './contract/Contract'
import { Contracts } from './contract/Contracts'
import { StudentView } from './students/StudentPortal'
import { Payments } from './payment/Payments'
import { ActionGroups } from './actions/ActionGroups'
import { Loading } from './components/Loading'
import { StripePayment, StripePaymentDirect } from './stripe/StripePayment'
import { StripeSuccess } from './stripe/StripeSuccess'
import { StripeCancel } from './stripe/StripeCancel'
import { RightNavContent } from './components/RightNavContent'
import { Stage } from './generated/graphql'
import { useTracking, identifyUser } from './utils/analytics'
import { StudentsCohortTag } from './students/StudentsCohortTag'

const ProLayoutWrapper = styled.div`
  height: 100vh;

  .ant-pro-top-nav-header-logo {
    min-width: 45px;
  }

  .ant-pro-top-nav-header-main-left {
    min-width: 130px;
  }
`

const onRedirectCallback = (appState?: { targetUrl: string }) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

export const App = () => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      client_id={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      redirect_uri={`${window.location.origin}/schools`}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthorizedApolloProvider>
        <div style={{ height: '100vh' }}>
          <Router>
            <AppContents />
          </Router>
        </div>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  )
}

const AppContents: React.FC<{}> = () => {
  useTracking()

  return (
    <Switch>
      <Route path="/stripe/:program/:studentEmail">
        <StripePayment />
      </Route>
      <Route path="/stripe/:program">
        <StripePaymentDirect />
      </Route>
      <Route path="/stripe-success">
        <StripeSuccess />
      </Route>
      <Route path="/stripe-cancel">
        <StripeCancel />
      </Route>
      <Route path="*">
        <MainLayout />
      </Route>
    </Switch>
  )
}

const MainLayout: React.FC<{}> = () => {
  const { user, loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const [fullWidth, setFullWidth] = React.useState(false)
  const location = useLocation()
  const { pathname } = location

  // Based off of:
  // https://auth0.com/docs/quickstart/spa/react/01-login#secure-the-profile-page
  React.useEffect(() => {
    if (loading || isAuthenticated) return

    loginWithRedirect({
      appState: { targetUrl: pathname },
    })
  }, [loading, isAuthenticated, loginWithRedirect, pathname])

  React.useEffect(() => {
    if (user) identifyUser(user)
  }, [user])

  if (loading) return <Loading />

  if (!user) return null

  return (
    <ProLayoutWrapper>
      <ProLayout
        title="Spark"
        logo={<img src="/spark-logo.png" alt="" />}
        menuHeaderRender={(logo, title) => <Link to="/">{logo}</Link>}
        layout="top"
        navTheme="dark"
        contentWidth={fullWidth ? 'Fluid' : 'Fixed'}
        headerHeight={64}
        inlineIndent={20}
        fixedHeader={false}
        fixSiderbar={false}
        iconfontUrl=""
        menuDataRender={() =>
          !user || user.studentUser
            ? []
            : [
                ...(user.adminUser ? [{ name: 'Schools', path: '/schools' }] : []),
                ...(!user.adminUser && user.schoolId
                  ? [{ name: 'School', path: `/school/${user.schoolId}` }]
                  : []),
                { name: 'Candidates', path: '/candidates' },
                { name: 'Onboarding', path: '/onboarding' },
                { name: 'Students', path: '/students' },
                { name: 'Graduates', path: '/graduates' },
                { name: 'Contracts', path: '/contracts' },
                { name: 'Payments', path: '/payments' },
              ]
        }
        menuItemRender={(menuItemProps, defaultDom) => {
          if (menuItemProps.isUrl || menuItemProps.children || !menuItemProps.path)
            return defaultDom

          return <Link to={menuItemProps.path}>{defaultDom}</Link>
        }}
        rightContentRender={() => (
          <RightNavContent expanded={fullWidth} toggleFullWidth={() => setFullWidth(!fullWidth)} />
        )}
      >
        <div>
          <Switch>
            <PrivateRoute path="/school/:schoolId/tag/:tag">
              <StudentsCohortTag />
            </PrivateRoute>
            <PrivateRoute path="/school/:schoolId">
              <School />
            </PrivateRoute>
            <PrivateRoute path="/schools">
              {user.schoolId && !user.adminUser ? (
                <Redirect to={`/school/${user.schoolId}`} />
              ) : (
                <Schools />
              )}
            </PrivateRoute>
            <PrivateRoute path="/student/:studentId">
              <Student />
            </PrivateRoute>
            <PrivateRoute path="/candidates">
              <Students stage={Stage.Candidate} />
            </PrivateRoute>
            <PrivateRoute path="/onboarding">
              <Students stage={Stage.Onboarding} />
            </PrivateRoute>
            <PrivateRoute path="/students">
              <Students stage={Stage.Student} />
            </PrivateRoute>
            <PrivateRoute path="/graduates">
              <Students stage={Stage.Graduate} />
            </PrivateRoute>
            <PrivateRoute path="/program/:programId">
              <Program />
            </PrivateRoute>
            <PrivateRoute path="/cohort/:cohortId">
              <Cohort />
            </PrivateRoute>
            <PrivateRoute path="/contracts">
              <Contracts />
            </PrivateRoute>
            <PrivateRoute path="/contract/:contractId">
              <Contract />
            </PrivateRoute>
            <PrivateRoute path="/payments">
              <Payments />
            </PrivateRoute>
            <PrivateRoute path="/action-groups">
              <ActionGroups />
            </PrivateRoute>
            <PrivateRoute path="/student-portal/:studentId">
              <StudentView />
            </PrivateRoute>
            <PrivateRoute path="*">
              {user.schoolId && !user.adminUser ? (
                <Redirect to={`/school/${user.schoolId}`} />
              ) : (
                <Schools />
              )}
            </PrivateRoute>
          </Switch>
        </div>
      </ProLayout>
    </ProLayoutWrapper>
  )
}
