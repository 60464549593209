import React from 'react'
import { Avatar, List, Card, Form, Button, Space } from 'antd'
import { Formik, Field } from 'formik'
import { TabProps } from './types'
import { useAuth0 } from '../auth/auth0'
import { Loading } from '../components/Loading'
import { ErrorGeneral } from '../components/ErrorPages'
import { gql } from 'apollo-boost'
import { ButtonModalLink } from '../components/ButtonModalLink'
import {
  useSchoolAdminsQuery,
  useGetUsersQuery,
  useSchoolActionGroupQuery,
  useAddSchoolAdminUserMutation,
  useEditSchoolActionGroupMutation,
} from '../generated/graphql'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { formItemLayout, tailFormItemLayout } from '../components/Form'
import { CustomSelectComponent } from '../components/FormikCustom'
import { ACTION_FRAGMENT, ViewActionGroup } from '../actions/ActionGroups'
import { SetActionGroupForm } from '../components/SetActionGroupForm'
import { trackEvent } from '../utils/analytics'

const GET_SCHOOL_ADMINS = gql`
  query SchoolAdmins($schoolId: ID!) {
    schoolUsers(schoolId: $schoolId) {
      user_id
      name
      nickname
      email
      picture
    }
  }
`

gql`
  ${ACTION_FRAGMENT}

  query SchoolActionGroup($schoolId: ID!) {
    school(id: $schoolId) {
      id
      defaultActionGroupId
      defaultActionGroup {
        name
        actions {
          ...Action
        }
      }
    }
  }
`

gql`
  query GetUsers {
    users {
      user_id
      name
      email
    }
  }
`

gql`
  mutation AddSchoolAdminUser($userId: String!, $schoolId: ID!) {
    addSchoolAdminUser(userId: $userId, schoolId: $schoolId)
  }
`

gql`
  mutation EditSchoolActionGroup($defaultActionGroupId: String!, $schoolId: String!) {
    editSchoolActionGroup(defaultActionGroupId: $defaultActionGroupId, schoolId: $schoolId) {
      id
      defaultActionGroupId
    }
  }
`

interface SchoolSettingsProps extends TabProps {}

export const SchoolSettings: React.FC<SchoolSettingsProps> = props => {
  const { school } = props
  const { user } = useAuth0()
  const { data, loading, error } = useSchoolActionGroupQuery({ variables: { schoolId: school.id } })
  const [editSchoolActionGroup] = useEditSchoolActionGroupMutation()

  if (loading) return <Loading />
  if (!data || error) return <ErrorGeneral error={error} />

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card
        headStyle={{ borderBottom: 'none' }}
        bordered={false}
        title={`Default Actions Group`}
        bodyStyle={{ padding: '0 32px 40px 32px' }}
      >
        <SetActionGroupForm
          actionGroupId={data.school.defaultActionGroupId || undefined}
          editActionGroup={async defaultActionGroupId => {
            const variables = { schoolId: data.school.id, defaultActionGroupId }
            editSchoolActionGroup({ variables })
            trackEvent('editSchoolActionGroup', variables)
          }}
        />

        <div style={{ paddingTop: 20 }}>
          {data.school.defaultActionGroupId && (
            <ViewActionGroup actionGroupId={data.school.defaultActionGroupId} />
          )}
        </div>
      </Card>
      {user?.adminUser && <SchoolAdmins schoolId={school.id} />}
    </Space>
  )
}

interface SchoolAdminsProps {
  schoolId: string
}

const SchoolAdmins: React.FC<SchoolAdminsProps> = ({ schoolId }) => {
  const { data, loading, error } = useSchoolAdminsQuery({
    variables: { schoolId },
  })

  if (loading) return <Loading />
  if (error) {
    console.error(error)
    return <p>Error loading school managers. {error.message}.</p>
  }

  return (
    <ErrorBoundary>
      <Card
        title="School Managers"
        extra={
          <ButtonModalLink
            buttonText="Add"
            title="Make User School Manager"
            content={onCreate => <AddSchoolAdminForm schoolId={schoolId} onCreate={onCreate} />}
          />
        }
      >
        {data?.schoolUsers ? (
          <List
            itemLayout="horizontal"
            dataSource={data.schoolUsers}
            renderItem={schoolAdmin => (
              <List.Item>
                <List.Item.Meta
                  avatar={schoolAdmin.picture && <Avatar src={schoolAdmin.picture} />}
                  title={schoolAdmin.nickname}
                  description={schoolAdmin.email}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>There are no school managers</p>
        )}
      </Card>
    </ErrorBoundary>
  )
}

interface AddSchoolAdminFormProps {
  schoolId: string
  onCreate?: () => void
}

const AddSchoolAdminForm: React.FC<AddSchoolAdminFormProps> = ({ schoolId, onCreate }) => {
  const [addSchoolAdminUser] = useAddSchoolAdminUserMutation({
    refetchQueries: [{ query: GET_SCHOOL_ADMINS, variables: { schoolId } }],
  })

  const { data, error, loading } = useGetUsersQuery()

  if (loading) {
    return (
      <div style={{ marginBottom: 60 }}>
        <Loading />
      </div>
    )
  }

  if (error) {
    console.error(error)
    return <p>Error loading schools. {error.message}.</p>
  }

  return (
    <Formik<{ userId: string }>
      initialValues={{
        userId: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('onSubmit', values)
        const variables = { userId: values.userId, schoolId }
        addSchoolAdminUser({ variables })
        trackEvent('addSchoolAdminUser', variables)
        setSubmitting(false)
        onCreate && onCreate()
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <Form {...formItemLayout} onFinish={() => handleSubmit()} onReset={handleReset}>
          <Field
            name="userId"
            label="User"
            options={data?.users.map(user => ({
              value: user.user_id,
              label: user.name,
            }))}
            component={CustomSelectComponent}
          />

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Add
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}
